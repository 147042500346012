import { CampaignOverallMetric } from "./CampaignOverallMetric";

export default function CampaignOverallMetricContainer(props) {
  const { totalCampaignsCount, totalSuccessMessages, totalSubscribersCount, optOutCount } = props;
  const data = [
    {
      icon: <div className="staticIconsSprite campaignOverviewIcon totalCampaignsIcon" />,
      message: "Total Campaigns",
      comingSoon: false,
      data: totalCampaignsCount,
    },
    {
      icon: <div className="staticIconsSprite campaignOverviewIcon messagesSentIcon" />,
      message: "Messages sent",
      comingSoon: false,
      data: totalSuccessMessages,
    },
    {
      icon: <div className="staticIconsSprite campaignOverviewIcon subscribersIcon" />,
      message: "Subscribers",
      comingSoon: true,
      data: totalSubscribersCount,
    },
    {
      icon: <div className="staticIconsSprite campaignOverviewIcon optOutIcon" />,
      message: "Opt-out",
      comingSoon: true,
      data: optOutCount,
    },
  ];
  return (
    <div className="w-100 d-flex justify-content-between">
      {data.map((item, index) => {
        item[index] = index;
        return <CampaignOverallMetric {...item} />;
      })}
    </div>
  );
}
