import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import Login from "../pages/Login";
import Dashboard from "../pages/dashboard/Dashboard";
import useAdminHttp from "../hooks/useAdminHttp";
import LoadingPage from "../pages/LoadingPage";

const AdminLayout = () => {

    const location = useLocation();
    const { isAdmin, setIsAdmin } = useAuth();
    const [isPinging, setIsPinging] = useState(true);
    const { fetchData } = useAdminHttp();
    useEffect(() => {
        console.log("Path >>>>>>>>> " + location.pathname);

        fetchData('ping', 'get', null)
            .then((response) => {
                console.log("response from ::::: OUTLET :::::::: ", JSON.stringify(response));
                if (response?.status === 200) setIsAdmin(true);
                else setIsAdmin(false);
            })
            .catch((error) => {
                setIsAdmin(false);
                console.log(error);
            })
            .finally(() => setIsPinging(false));
    }, []);

    return (
        <>
            {
                isPinging ? <LoadingPage /> : (
                    isAdmin ?
                        <Dashboard /> : <Login />
                )
            }
        </>
    )
}

export default AdminLayout;