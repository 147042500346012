import { localStorage, isOmniAccount, urlParams } from "./../constants/AppConstants";
export let DefaultOmessage = {
  service: "Default",
  isCustomModuleNeeded: true,
  isPhoneConfigurationEnabled: true,
  planDetails: {
    topPlan: "Gold",
    topPlanKey: "OMNI_GOLD",
    topPlanId: "omni_gold-USD-Monthly",
    totalUsers: 3,
  },
  sideBarExtend: {
    usersSideBar: true,
    customModuleTab: true,
  },
  workFlowIconInTemplates: true,

  checkIsAlreadyInstalled: function (fetchData) {
    return new Promise((resolve, reject) => {
      var integIdApiKey = "integId".trim();
      var hashApiKey = "hash".trim();
      DefaultOmessage.get(integIdApiKey).then(function (integId) {
        DefaultOmessage.get(hashApiKey).then(function (hash) {
          DefaultOmessage.get("osyncId").then(function (osyncIdFromStorage) {
            var extensionData = {
              hash: hash,
              integId: integId,
              osyncId: osyncIdFromStorage,
            };

            console.log("checkIsAlreadyInstalled default extensionData >>>>>>>>>>>>", extensionData);
            resolve(extensionData);
          });
        });
      });
    });
  },
  save: function (data) {
    return new Promise((resolve, reject) => {
      data?.map((key, val) => {
        if (isOmniAccount) {
          key = "Omni_" + key;
        }
        if (val) {
          localStorage.setItem(key, val);
        }
        return null;
      });
      resolve();
    });
  },
  savePhone: function (thisObj) {
    return new Promise((resolve, reject) => {});
  },
  get: function (key) {
    return new Promise((resolve, reject) => {
      if (isOmniAccount) {
        key = "Omni_" + key;
      }
      resolve(localStorage.getItem(key));
    });
  },
  delete: function (key) {
    return new Promise((resolve, reject) => {
      if (isOmniAccount) {
        key = "Omni_" + key;
      }
      if (localStorage.getItem(key)) {
        resolve(localStorage.removeItem(key));
      } else {
        reject();
      }
    });
  },

  getUserData: function () {
    return new Promise((resolve, reject) => {
      var crmUserName = "Default-Controller-Name";
      var crmId = "testboy";
      var email = "default@controller.oapps";
      var leftServiceId = urlParams.get("leftServiceId");
      var rightServiceId = urlParams.get("rightServiceId");

      var userData = {
        left_service_id: leftServiceId,
        right_service_id: rightServiceId,
        companyId: crmId,
        name: crmUserName,
        email: email,
        planName: "standard",
      };
      resolve(userData);
    });
  },
  getAssociatedObjectId: function () {
    return new Promise((resolve, reject) => {
      resolve("");
    });
  },
  getAssociatedObjectType: function () {
    return new Promise((resolve, reject) => {
      resolve(module);
    });
  },
  getAssociatedIntegId: function (thisObj, rightServiceName) {
    return new Promise((resolve, reject) => {
      var integIdApiKey = "integId".trim();

      DefaultOmessage.get(integIdApiKey)
        .then(function (integId) {
          if (integId) {
            resolve(integId);
          } else {
            reject();
          }
        })
        .catch(function (err) {
          console.log("getAssociatedIntegId Catch", err);
          reject();
        });
    });
  },
  getAssociatedHash: function (thisObj, rightServiceName) {
    return new Promise((resolve, reject) => {
      var hashApiKey = "hash".trim();

      DefaultOmessage.get(hashApiKey).then(function (hash) {
        if (hash) {
          resolve(hash);
        }
      });
    });
  },
  doAfterSuccessfulEnable: function (dataObj) {
    DefaultOmessage.save(dataObj);
  },
  getWebhookContent: function () {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },

  doCompleteInstallationProcess: function (integId, hash) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
};
