import useHttp from "./useHttp";

export default function useLicense() {
  const { fetchData } = useHttp();
  const fetchLicense = (appProperties) => {
    return new Promise((resolve, reject) => {
      if (appProperties !== undefined) {
        let isOmniAccount = appProperties.isOmniAccount;
        if (isOmniAccount) {
          fetchData(
            `omni/license?leftServiceId=${appProperties.leftServiceId}&osyncId=${appProperties.osyncId}`,
            "GET",
            null,
            appProperties
          ).then((response) => {
            if (response) {
              let responseData = JSON.parse(response);
              let LicenseData = responseData.data;
              console.log("responseData>>>>>>", responseData);
              console.log("savedUsers>>>>>>", LicenseData);
              console.log("fetchAllLicencedUSer>>>>>>", LicenseData.allLicensedUserDetails);
              resolve(LicenseData);
            }
          });
        } else {
          fetchData(
            `integration/license?leftServiceId=${appProperties.leftServiceId}&rightServiceId=${appProperties.rightServiceId}&remoteIdentifier=${appProperties.remoteIdentifier}`,
            "GET",
            null,
            appProperties
          ).then((response) => {
            if (response) {
              let responseData = JSON.parse(response);
              let LicenseData = responseData.data;
              console.log("responseData>>>>>>", responseData);
              console.log("savedUsers>>>>>>", LicenseData);
              console.log("fetchAllLicencedUSer>>>>>", LicenseData.allLicensedUserDetails);
              resolve(LicenseData);
            }
          });
        }
      }
    });
  };

  const fetchStripeURL = (appProperties) => {
    return new Promise((resolve, reject) => {
      if (appProperties !== undefined) {
        let urlParams = new URLSearchParams(window.location.search);
        const rightServiceId = urlParams.get("rightServiceId");
        fetchData(
          `manage/subscription?leftServiceId=${appProperties.leftServiceId}&rightServiceId=${rightServiceId}`,
          "GET",
          null,
          appProperties
        ).then((response) => {
          if (response) {
            let responseData = JSON.parse(response);
            let LicenseData = responseData.data;
            resolve(LicenseData);
          }
        });
      }
    });
  };

  return { fetchLicense, fetchStripeURL };
}
