import "../../assets/css/SideNavBar.css";

import { UserOutlined, FontColorsOutlined, ToolOutlined, SwapOutlined, MailOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";

import useAuth from "../../hooks/useAuth";

const { Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

export default function LeftPanel() {
  const { setCurrentSideTab } = useAuth();

  const items = [
    getItem("Customers", "customers", <UserOutlined />),
    getItem("Admins", "agents", <FontColorsOutlined />),
    getItem("Tools", "tools", <ToolOutlined />),
    getItem("Migration", "migration", <SwapOutlined />),
    getItem("RC Subscription", "rc_subscription", <MailOutlined />),
  ];

  const menuStyle = {
    marginLeft: "auto",
    marginRight: "auto",
    width: "99%",
    fontSize: "1rem",
    outerHeight: "100vh !important",
  };

  const menuSelectHandler = (item) => {
    console.log(item.key);
    setCurrentSideTab(item.key);
  };

  return (
    <>
      <Sider style={{ width: "70px" }} collapsed={false} collapsedWidth="0" theme="dark" width={180} className="mainSideNavBar">
        <div style={{ height: "71px", width: "127px" }}>
          <img
            style={{ width: "inherit" }}
            className="ms-4 my-3"
            src={require("../../assets/images/admin_tool_logo.png")}
            alt="admin tool logo"
          />
        </div>
        <Menu
          tabIndex={-1}
          onSelect={menuSelectHandler}
          style={menuStyle}
          theme="dark"
          defaultSelectedKeys={["customers"]}
          mode="inline"
          items={items}
        />
      </Sider>
    </>
  );
}
