import React from "react";
import { useState, useEffect } from "react";
import { Input } from "antd";
import { Button, Flex, Row, Col } from "antd";
import useAdminHttp from "../../hooks/useAdminHttp";
import { Card, Table } from "antd";
import { failureNotification } from "../../../pages/utils/CommonNotifications";

export default function AccountOrgIdMigration() {
  const { fetchData } = useAdminHttp();
  const [accountOrgId, setAccountOrgId] = useState("");
  const [migrationResponse, setMigrationResponse] = useState({});

  useEffect(() => {
    const storedOsyncId = sessionStorage.getItem("accountOrgId");

    if (storedOsyncId) setAccountOrgId(storedOsyncId);
  }, []);
  useEffect(() => {
    sessionStorage.setItem("osyncId", accountOrgId);
  }, [accountOrgId]);

  const getAccountOrgId = async () => {
    try {
      const res = await fetchData("migrate/account?accountOrgId=" + accountOrgId, "POST");
      if (res) {
        console.log(res);
        setMigrationResponse(res?.data);
      } else {
        failureNotification("Error", "Something went wrong !");
        return;
      }
    } catch (error) {
      console.error("An error occurred while fetching the account org ID:", error);
    }
  };

  return (
    <Card
      className="toolsCard"
      title="Migration"
      bordered={false}
      style={{
        width: "100%",
      }}
    >
      <Row className="mt-5">
        <Col span={6}>
          <div id="getAccountOrgIdInput">
            <div className="align-item-bottom d-flex justify-content-end mt-2">
              <label htmlFor="Account Org Id">Enter Your Account Org Id</label>
            </div>
          </div>
        </Col>
        <Col span={6}>
          <div id="getAccountOrgIdInput">
            <div className="d-flex justify-content-left ms-4">
              <label htmlFor="Account Org Id" className="mb-3">
                <Input
                  style={{ width: "25pc" }}
                  id="accountOrgId"
                  placeholder="Account Org Id"
                  onChange={(e) => setAccountOrgId(e.target.value)}
                  value={accountOrgId}
                />
              </label>
            </div>
          </div>
        </Col>
        <Col span={5}>
          <div className="d-flex flex-row-reverse">
            <div className="d-flex justify-content-left"></div>
            <div className="submit">
              <Flex gap="small" wrap="wrap">
                <Button className="ant-btn css-dev-only-do-not-override-amq5gd ant-btn-default" onClick={getAccountOrgId}>
                  Submit
                </Button>
              </Flex>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <MigrationResponse migrationResponse={migrationResponse} />
      </Row>
    </Card>
  );
}

const agentsDataColumns = [
  {
    title: "MigrationId",
    dataIndex: "migrationId",
    key: "migrationId",
  },
  {
    title: "OldOsyncIds",
    dataIndex: "oldOsyncIds",
    key: "oldOsyncIds",
  },
  {
    title: "NewOsyncId",
    dataIndex: "newOsyncId",
    key: "newOsyncId",
  },
  {
    title: "OldIntegIds",
    dataIndex: "oldIntegIds",
    key: "oldIntegIds",
  },
  {
    title: "NewIntegId",
    dataIndex: "newIntegId",
    key: "newIntegId",
  },
];

export const MigrationResponse = (props) => {
  const { migrationResponse } = props;
  const [migrationResponseTable, setMigrationResponseTable] = useState([]);

  useEffect(() => {
    const tableData = Object.entries(migrationResponse).map(([key, value]) => {
      console.log("Key:", key, "Value:", value);
      return {
        key,
        migrationId: value.migrationId,
        oldOsyncIds: value.oldOsyncIds,
        newOsyncId: value.newOsyncId,
        oldIntegIds: value.oldIntegIds,
        newIntegId: value.newIntegId,
      };
    });

    setMigrationResponseTable(tableData);
  }, [migrationResponse]);

  return (
    <div className="col-24 w-100">
      <Table pagination={false} columns={agentsDataColumns} dataSource={migrationResponseTable} />
    </div>
  );
};
