import { Divider } from "antd";
import Title from "antd/es/typography/Title";

export default function ToolHeader() {
  return (
    <Title>
      Tools
      <Divider />
    </Title>
  );
}
