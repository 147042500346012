import React from 'react';
import '../assets/css/LoadingPage.css';
import loading from '../../assets/gif/Loading.gif';
const LoadingPage = () => {
  return (
    <div className="loadingContainer">
        <img height={100} width={125} src={loading} alt="loading.gif"></img>
    </div>
  );
};

export default LoadingPage;