import { useCallback } from "react";
import useHttp from "./useHttp";

export default function useTemplates() {
  const { fetchData } = useHttp();

  var urlParams = new URLSearchParams(window.location.search);
  var rightServiceId = urlParams.get("rightServiceId");

  const fetchSavedTemplates = (appProperties) => {
    return new Promise((resolve, reject) => {
      let integId = appProperties?.randomIntegId;

      if (appProperties) {
        console.log("handleAddAutomation fetchSavedTemplates integId>>>>>>>>>>", integId);
        fetchData(`templates`, "GET", null, appProperties).then((response) => {
          let responseData = "";
          if (response) {
            responseData = JSON.parse(response);
          }

          let savedTemplates = responseData?.data;

          resolve(savedTemplates);
        });
      }
    });
  };
  const fetchSavedTemplatesByModule = (appProperties, moduleName) => {
    return new Promise((resolve, reject) => {
      let integId = appProperties.randomIntegId;

      if (appProperties !== undefined) {
        fetchData(`templates?module=${moduleName}`, "GET", null, appProperties).then((response) => {
          let responseData = "";
          if (response) {
            responseData = JSON.parse(response);
          }
          let savedTemplates = responseData?.data;

          resolve(savedTemplates);
        });
      }
    });
  };
  const fetchModules = useCallback(
    (appProperties, includeFilters, action) => {
      return new Promise((resolve, reject) => {
        if (appProperties.leftServiceId !== undefined) {
          let targetUrl = `application/modules?leftServiceId=${appProperties.leftServiceId}&action=${action}&rightServiceId=${rightServiceId}`;
          targetUrl += includeFilters ? `&includeFilters=true` : ``;
          fetchData(targetUrl, "GET", null, appProperties).then((response) => {
            let responseData = "";
            if (response) {
              responseData = JSON.parse(response);
            }
            let modules = responseData?.data;

            resolve(modules);
          });
        }
      });
    },
    [fetchData]
  );
  const fetchFiltersByModule = useCallback(
    (appProperties, module) => {
      let integId = appProperties?.installedApps?.[0]?.integProps?.integId;
      return new Promise((resolve, reject) => {
        if (appProperties !== undefined) {
          fetchData(`${integId}/campaign/filters?moduleName=${module}`, "GET", null, appProperties).then((response) => {
            let responseData = "";
            if (response) {
              responseData = JSON.parse(response);
            }
            let filters = responseData?.data;

            resolve(filters);
          });
        }
      });
    },
    [fetchData]
  );

  const fetchFields = useCallback(
    (appProperties, moduleName) => {
      return new Promise((resolve, reject) => {
        if (appProperties && appProperties?.leftServiceId) {
          fetchData(
            `module/fields?moduleName=${moduleName}&leftServiceId=${appProperties.leftServiceId}&lookupFields=true&rightServiceId=${rightServiceId}`,
            "GET",
            null,
            appProperties
          ).then((response) => {
            let responseData = "";
            if (response) {
              responseData = JSON.parse(response);
            }
            let fields = responseData?.data;

            resolve(fields);
          });
        }
      });
    },
    [fetchData]
  );

  const fetchAllFields = useCallback(
    (appProperties, moduleName, lookupFields) => {
      let integId = appProperties.randomIntegId;
      return new Promise((resolve, reject) => {
        if (appProperties !== undefined) {
          var urlParams = new URLSearchParams(window.location.search);
          var rightServiceId = urlParams.get("rightServiceId");
          fetchData(
            `module/fields?leftServiceId=${appProperties.leftServiceId}&moduleName=${moduleName}&lookupFields=true&rightServiceId=${rightServiceId}`,
            "GET",
            null,
            appProperties
          ).then((response) => {
            let responseData = "";
            if (response) {
              responseData = JSON.parse(response);
            }
            let fields = responseData?.data;

            resolve(fields);
          });
        }
      });
    },
    [fetchData]
  );

  const fetchPhoneFieldsWithValues = useCallback(
    (appProperties, moduleName, recordId) => {
      let integId = appProperties.randomIntegId;
      return new Promise((resolve, reject) => {
        if (appProperties !== undefined) {
          var urlParams = new URLSearchParams(window.location.search);
          var rightServiceId = urlParams.get("rightServiceId");
          var leftId = urlParams.get("leftServiceId");

          var leftServiceId = leftId || appProperties.leftServiceId;
          fetchData(
            `app/phone-fields/values?leftServiceId=${leftServiceId}&moduleName=${moduleName}&recordId=${recordId}&rightServiceId=${rightServiceId}`,
            "GET",
            null,
            appProperties
          ).then((response) => {
            let responseData = "";
            if (response) {
              responseData = JSON.parse(response);
            }
            let fields = responseData?.data;

            resolve(fields);
          });
        }
      });
    },
    [fetchData]
  );

  const addTemplate = (appProperties, body) => {
    return new Promise((resolve, reject) => {
      let integId = appProperties.randomIntegId;

      if (appProperties !== undefined) {
        fetchData(`template?leftServiceId=${appProperties.leftServiceId}`, "POST", body, appProperties).then((response) => {
          let responseData = "";
          if (response) {
            responseData = JSON.parse(response);
          }
          let addedTemp = responseData?.data;

          resolve(addedTemp);
        });
      }
    });
  };

  const updateTemplate = (appProperties, body, templateId) => {
    return new Promise((resolve, reject) => {
      let integId = appProperties.randomIntegId;

      if (appProperties !== undefined) {
        fetchData(`template/${templateId}`, "PUT", body, appProperties).then((response) => {
          let responseData = "";
          if (response) {
            responseData = JSON.parse(response);
          }
          let addedTemp = responseData?.data;

          resolve(addedTemp);
        });
      }
    });
  };
  const deleteTemplate = (appProperties, templateId) => {
    return new Promise((resolve, reject) => {
      let integId = appProperties.randomIntegId;

      if (appProperties !== undefined) {
        fetchData(`template/${templateId}?leftServiceId=${appProperties.leftServiceId}`, "DELETE", null, appProperties).then((response) => {
          let responseData = "";
          if (response) {
            responseData = JSON.parse(response);
          }
          let deleteTemp = responseData?.data;

          resolve(deleteTemp);
        });
      }
    });
  };

  const fetchFilterInfoByFilterId = (
    appProperties,
    integId,
    moduleName,
    filterId,
    selectedIds,
    excludedIds,
    leftServiceId,
    filterType,
    filter
  ) => {
    return new Promise((resolve, reject) => {
      let targetUrl = `filter/records/count?moduleName=${moduleName}&filterId=${filterId}`;

      if (filterType) {
        targetUrl += `&filterType=${filterType}`;
      }
      if (leftServiceId) {
        targetUrl += `&leftServiceId=${leftServiceId}`;
      }
      if (integId) {
        targetUrl += `&integId=${integId}`;
      }
      if (selectedIds) {
        targetUrl += `&selectedIds=${selectedIds}`;
      }
      if (excludedIds) {
        targetUrl += `&excludedIds=${excludedIds}`;
      }

      if (filter) {
        targetUrl += `&filter=${encodeURIComponent(filter)}`;
      }

      console.log("log >>> f1 >>> ", filter);

      fetchData(targetUrl, "GET", null, appProperties)
        .then((res) => {
          if (res) {
            const responseJson = JSON.parse(res);
            if (responseJson?.data) {
              resolve(responseJson.data);
            }
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return {
    fetchSavedTemplates,
    fetchModules,
    fetchFields,
    addTemplate,
    updateTemplate,
    deleteTemplate,
    fetchSavedTemplatesByModule,
    fetchAllFields,
    fetchFiltersByModule,
    fetchFilterInfoByFilterId,
    fetchPhoneFieldsWithValues,
  };
}
