import { Button, Input, List } from "antd";
import { useState } from "react";
import { validateEmail } from "../utils/commonUtils";

function EmailComponent({ parentKey, field, settingsValue, setErrorObj }) {
  var emailList = settingsValue?.[parentKey]?.[field?.key] || [];
  const [emailInput, setEmailInput] = useState("");
  const handleAddEmailList = () => {
    if (validateEmail(emailInput)) {
      field.onchange(parentKey, field?.key, [...(emailList || []), emailInput]);
      setEmailInput("");
      setErrorObj(null);
    } else {
      setErrorObj({
        "Email notification": "Please enter a valid email address.",
      });
    }
  };
  const handleEmailRemove = (email) => {
    field?.onchange(
      parentKey,
      field?.key,
      emailList.filter((obj) => obj !== email)
    );
  };
  return (
    <div className="block">
      <div className="d-flex align-items-center">
        <Input
          className="hs-bg-off-white mx-2 w-50 h-45 focus-border"
          placeholder={field?.placeholder}
          value={emailInput}
          onChange={(e) => {
            setEmailInput(e.target.value);
          }}
          type="email"
        />
        <Button className="hs-btn-small" onClick={handleAddEmailList}>
          Add
        </Button>
      </div>
      <List
        grid={{
          gutter: 16,
          column: 4,
        }}
        className="d-flex email-list-setting"
      >
        {emailList?.map((email, index) => (
          <List.Item key={email + index} className="email-list-item-setting mx-2 my-1 d-flex hs-w-fit">
            <span className="px-1">{email}</span>
            <div
              className="actionIconsSprite closeIconBlack"
              onClick={() => {
                handleEmailRemove(email);
              }}
            />
          </List.Item>
        ))}
      </List>
    </div>
  );
}

export default EmailComponent;
