import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Modal, Input, Checkbox } from "antd";
import "../../assets/css/otpModal.css";
import useHttp from "./../../hooks/useHttp";
import { urlParams } from "./../../constants/AppConstants";
import mailPng from "./../../assets/icons/Mail.png";
import { getController } from "./../../context/AppContext";
import { successNotification } from "../../pages/utils/CommonNotifications";
import useUsers from "../../hooks/useUsers";

const { Search } = Input;

export default function OTPModal(props) {
  const { open, setLoading, appPropsObject } = props;
  const [isModalOpen, setIsModalOpen] = useState(open);
  const [currentStep, setCurrentStep] = useState("1");
  const leftServiceId = urlParams.get("leftServiceId");
  const rightServiceId = urlParams.get("rightServiceId");
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [otpBtnLoading, setOtpBtnLoading] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState(true);
  const [emailMessageContent, setEmailMessageContent] = useState(true);
  const [controller] = useState(getController(window.location.path === "/zoho-crm/webhook" ? "zohocrm" : urlParams.get("service") ?? "default"));
  const [emailLoading, setEmailLoading] = useState(true);
  const [emailDisable, setEmailDisable] = useState(false);
  const [email, setEmail] = useState(urlParams.get("email"));

  const onChange = (e) => {
    setChecked(e.target.checked);
  };

  const { fetchData } = useHttp();

  const login = () => {
    let companyId = appPropsObject?.companyId;
    let userId = appPropsObject?.userId;
    let customObject = { companyId, userId };
    setOtpBtnLoading(true);
    fetchData(
      `login?email=${encodeURIComponent(
        email
      )}&companyId=${companyId}&leftServiceId=${leftServiceId}&rightServiceId=${rightServiceId}&userDetail=${userId}&rememberMe=${checked}`,
      "POST",
      null,
      customObject
    ).then(function (response) {
      if (response) {
        const responseData = JSON.parse(response);
        if (responseData?.data?.emailResponse?.isValid) {
          successNotification("OTP Sent");
          setOtpBtnLoading(false);
          setCurrentStep("2");
        } else {
          setEmailMessageContent(responseData?.data?.emailResponse?.message);
          setDisabled(false);
          setOtpBtnLoading(false);
        }
      }
    });
  };

  const { getUserAccountDetails } = useUsers();
  useEffect(() => {
    if (!controller) return;

    let accountAvailable = false;

    if (!email || email === "null" || email === "undefined") {
      let companyId = appPropsObject?.companyId;
      let userId = appPropsObject?.userId;
      getUserAccountDetails(companyId, userId, leftServiceId, controller, appPropsObject)
        .then((response) => {
          if (response) {
            const responseData = response?.data?.data;
            if (responseData) {
              let dataObj = JSON.parse(responseData);
              if (dataObj) {
                const email = dataObj?.email;
                if (email) {
                  setEmail(email);
                  setEmailDisable(true);
                  setEmailLoading(false);
                }
              }
            }
            accountAvailable = response?.data?.accountAvailable;
            if (window.location.pathname === "/zoho-crm/landing") {
              if (!accountAvailable) {
                setIsModalOpen(false);
              }
            }
          }
        })
        .finally(() => {
          setEmailLoading(false);
          setLoading(false);
        });
    } else {
      setEmailLoading(false);
    }
  }, [controller]);
  return (
    <>
      <Modal
        id="otp"
        className="otpModal"
        maskClosable={false}
        open={isModalOpen && !emailLoading}
        footer={null}
        centered={true}
        closeIcon={false}
      >
        <div id="modalContainer" style={{ marginTop: "2px", marginBottom: ".5rem" }}>
          <div id="otpModalIconDiv">
            <img src={mailPng} draggable="false" alt="mail.png" />
          </div>

          {currentStep === "1" && (
            <GetEmail
              otpBtnLoading={otpBtnLoading}
              login={login}
              setDisabled={setDisabled}
              disabled={disabled}
              emailMessageContent={emailMessageContent}
              onChange={onChange}
              controller={controller}
              emailLoading={emailLoading}
              setEmailLoading={setEmailLoading}
              emailDisable={emailDisable}
              email={email}
              setEmail={setEmail}
              appPropsObject={appPropsObject}
            />
          )}
          {currentStep === "2" && (
            <GetOTP
              email={email}
              checked={checked}
              setOtpErrorMessage={setOtpErrorMessage}
              otpErrorMessage={otpErrorMessage}
              login={login}
              setOtpModalState={props.setOtpModalState}
              controller={controller}
              leftServiceId={leftServiceId}
              rightServiceId={rightServiceId}
              appPropsObject={appPropsObject}
            />
          )}
        </div>
      </Modal>
    </>
  );
}

const GetEmail = (props) => {
  const { login, setDisabled, disabled, emailMessageContent, onChange, otpBtnLoading, emailLoading, emailDisable, email, setEmail } = props;
  return (
    <div id="otpModal">
      <p className="commonStyleHeading"> Email Verification </p>
      <p className="commonStyleMessage" style={{ marginBottom: "2rem" }}>
        {" "}
        Please enter your registered email address. An OTP will be sent to verify your account.{" "}
      </p>
      <div id="otpEmailInput" className="shadow-sm">
        <Search
          loading={otpBtnLoading}
          prefix={emailLoading ? <div className="actionIconsSprite search-loading"></div> : <></>}
          allowClear={false}
          enterButton={
            <Button type="primary" size="large" disabled={otpBtnLoading}>
              Get OTP
            </Button>
          }
          size="large"
          onSearch={login}
          disabled={emailDisable}
          onChange={(e) => {
            setEmail(e.target.value);
            setDisabled(true);
          }}
          bordered={false}
          autoSize={true}
          value={email}
        />
        <div className="d-flex" style={{ paddingLeft: "12px", paddingTop: "6px" }}>
          <p style={{ color: "red" }} hidden={disabled}>
            {emailMessageContent}
          </p>
        </div>
      </div>
      <div className="d-flex align-items-end flex-column bd-highlight mb-3" style={{ paddingRight: "10px", fontSize: "large" }}>
        <p
          style={{
            marginBottom: "20px",
          }}
        >
          <Checkbox onChange={onChange} className="d-none">
            Remember Me
          </Checkbox>
        </p>
      </div>
    </div>
  );
};

const GetOTP = (props) => {
  let controller = props.controller;
  var length = 4;

  const { email, setOtpErrorMessage, otpErrorMessage, checked, login, setOtpModalState, leftServiceId, rightServiceId, appPropsObject } = props;
  const [otp, setOtp] = useState(Array(length).fill(""));
  const [otpMessageContent, setOtpMessageContent] = useState(true);
  const [countdown, setCountdown] = useState(15);
  const [isCountingDown, setIsCountingDown] = useState(true);
  const [hashToken, setHashToken] = useState();
  const [isRequestSentForValidation, setIsRequestSentForValidation] = useState(false);
  const [otpResponseData, setOtpResponseData] = useState();
  const companyId = urlParams.get("companyId");
  const userId = urlParams.get("userId");

  const { fetchData } = useHttp();

  const inputRefs = useRef([]);
  useEffect(() => {
    if (hashToken) {
      controller?.saveApplicationToken(hashToken).then(function () {
        console.log("zoho-crm-test otpResponseData>>>>>>>>>>>", otpResponseData);
        console.log("zoho-crm-test otpResponseData?.data>>>>>>>>>>>", otpResponseData?.data);
        console.log("zoho-crm-test otpResponseData?.data?.otpResponse>>>>>>>>>>>", otpResponseData?.data?.otpResponse);
        console.log("zoho-crm-test otpResponseData?.data?.otpResponse?.isValid>>>>>>>>>>>", otpResponseData?.data?.otpResponse?.isValid);
        if (otpResponseData?.data?.otpResponse?.isValid) {
          setOtpModalState(false);
          window.location.reload();
        } else {
          setIsRequestSentForValidation(false);
          setOtpMessageContent(otpResponseData?.data?.otpResponse?.message);
          setOtpErrorMessage(false);
        }
      });
    }
  }, [hashToken, controller]);

  const handleInputChange = (value, index) => {
    const newOtp = otp ? [...otp] : [];
    newOtp[index] = value;
    setIsRequestSentForValidation(false);
    setOtp(newOtp);
    if (value !== "" && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleBackspace = (index) => {
    if (index > 0 && otp[index] === "") {
      inputRefs.current[index - 1].focus();
      inputRefs.current[index - 1].select();
    }
    setIsRequestSentForValidation(false);
    const newOtp = otp ? [...otp] : [];
    newOtp[index] = "";
    setOtp(newOtp);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").trim().replace(/\s/g, "").slice(0, length);

    const newOtp = Array(length).fill("");
    for (let i = 0; i < pastedData.length; i++) {
      newOtp[i] = pastedData[i];
    }
    inputRefs.current[3].focus();

    setOtp(newOtp);
  };

  const validateOtp = useCallback(
    (otpValues) => {
      if (!isRequestSentForValidation) {
        setIsRequestSentForValidation(true);
        console.log("huyji appPropsObject >>>>>>>>>>>", appPropsObject);
        let companyId = appPropsObject?.companyId;
        let userId = appPropsObject?.userId;
        fetchData(
          `validate?otp=${otpValues}&companyId=${companyId}&leftServiceId=${leftServiceId}&rightServiceId=${rightServiceId}&userDetail=${userId}&rememberMe=${checked}&email=${email}`,
          "POST",
          null,
          appPropsObject
        )
          .then(function (response) {
            if (response) {
              const responseData = JSON.parse(response);

              let hashTokenFromServer = responseData?.data?.token;
              if (hashTokenFromServer) {
                setOtpResponseData(responseData);
                setHashToken(hashTokenFromServer);
              } else {
                if (responseData?.data?.otpResponse?.isValid) {
                  setOtpModalState(false);
                  window.location.reload();
                } else {
                  setOtpMessageContent(responseData?.data?.otpResponse?.message);
                  setOtpErrorMessage(false);
                }
              }
            }
          })
          .catch((error) => {
            console.log(error?.message);
          });
      }
    },
    [fetchData, checked, companyId, leftServiceId, rightServiceId, setOtpErrorMessage, setOtpModalState, email, userId, hashToken]
  );

  useEffect(() => {
    const otpString = otp.join("");
    console.log("huyji otpString>>>>>>>>>>>>>>", otpString);
    console.log("huyji otpString.length>>>>>>>>>>>>>>", otpString?.length);
    if (otpString.length === 4) {
      validateOtp(otpString);
    }
  }, [otp, validateOtp]);

  useEffect(() => {
    if (countdown > 0 && isCountingDown) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      setIsCountingDown(false);
    }
  }, [countdown, isCountingDown]);

  useEffect(() => {
    // Set focus on the first input when the component mounts
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleResend = () => {
    if (!isCountingDown) {
      login(email);
      setCountdown(15);
      setIsCountingDown(true);
    }
  };
  return (
    <div id="otpModal">
      <p className="commonStyleHeading"> Email Verification </p>
      <p>
        {" "}
        <span className="commonStyleMessage">Enter the OTP sent to</span> <br />{" "}
        <span style={{ fontSize: "small", fontWeight: "bolder", opacity: "revert" }}>{email}</span>{" "}
      </p>
      <div>
        {otp.map((value, index) => {
          return (
            <input
              key={`blink${index}`}
              id="otpInput"
              type="text"
              maxLength="1"
              autoFocus
              className={(index === 0 && !otp.some(Boolean)) || inputRefs.current[index] === document.activeElement ? "blink" : ""}
              style={{
                borderBottom: value ? "2px solid #605BFF" : "2px solid rgb(212, 212, 212)",
              }}
              value={value}
              onChange={(e) => {
                handleInputChange(e.target.value, index);
                setOtpErrorMessage(true);
                if (e.target.value && index < otp.length - 1) {
                  inputRefs.current[index + 1].focus(); // Move focus to next input
                }
              }}
              onPaste={handlePaste}
              onKeyUp={(e) => {
                if (e.key === "Backspace") {
                  handleBackspace(index);
                  if (index > 0) {
                    inputRefs.current[index - 1].focus(); // Move focus to previous input on backspace
                  }
                }
              }}
              ref={(el) => (inputRefs.current[index] = el)}
            />
          );
        })}

        <div
          className="d-flex"
          style={{
            paddingLeft: "5rem",
            fontSize: "small",
            marginBottom: "-2.5rem",
            height: "32px",
          }}
        >
          <p style={{ color: "red" }} hidden={otpErrorMessage}>
            {otpMessageContent}
          </p>
        </div>
      </div>
      <p
        style={{
          fontSize: "small",
          opacity: "50%",
          marginBottom: "0rem",
          marginTop: "2rem",
        }}
      >
        {" "}
        Didn’t you receive the OTP?{" "}
      </p>
      <Button
        type="link"
        style={{
          marginTop: "0rem",
          color: isCountingDown ? "#9CA3AF" : "#605BFF",
        }}
        onClick={handleResend}
        disabled={isCountingDown}
      >
        Resend OTP
        {isCountingDown && <span style={{ color: "#605BFF" }}> ({countdown}s)</span>}
      </Button>
    </div>
  );
};
