import React from "react";
import { useState, useEffect } from "react";
import { Select } from "antd";
import { Button, Flex } from "antd";
import { SmileFilled, SmileOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import useAdminHttp from "../hooks/useAdminHttp";
import { Card } from "antd";
import useAuth from "../hooks/useAuth";
const { Paragraph } = Typography;

export default function Tools({ userData }) {
  const { fetchData } = useAdminHttp();
  const { customerResData } = useAuth();
  const [osyncId, setOsyncId] = useState("");
  const [integId, setIntegId] = useState("");
  const [rightServiceId, setRightServiceId] = useState("");
  const [osyncUserId, setOsyncUserId] = useState("");
  const [urlEncodedString, setUrlEncodedString] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const getEncodedUrl = () => {
    console.log("OsyncId", osyncId);
    console.log("IntegId", integId);
    console.log("Right Service Id", rightServiceId);
    const data = {
      osyncId: osyncId,
      integId: integId,
      rightServiceId: rightServiceId,
      osyncUserId: osyncUserId,
    };

    fetchData(
      "encodeUrl?osyncId=" + osyncId + "&integId=" + integId + "&rightServiceId=" + rightServiceId + "&osyncUserId=" + osyncUserId,
      "get",
      data
    ).then((res) => {
      console.log(res);
      setUrlEncodedString(res?.data);
    });
  };

  useEffect(() => {
    const areInputsValid = osyncId.trim() !== "" && integId.trim() !== "" && (rightServiceId.trim() !== "" || osyncUserId !== "");
    setIsButtonDisabled(!areInputsValid);
  }, [osyncId, integId, rightServiceId, osyncUserId]);

  useEffect(() => {
    setUrlEncodedString("");
    setIntegId("");
    setOsyncId("");
    setRightServiceId("");
    setOsyncUserId("");
  }, [customerResData, userData]);

  return (
    <Card
      className="toolsCard"
      title="URL Encoded Webhook"
      bordered={false}
      style={{
        width: "100%",
      }}
    >
      <div id="getUrlEncodedString" className="grid-container">
        <div className="grid-item">
          <label htmlFor="osyncId">
            OsyncId
            <Select id="osyncId" placeholder="Select an OsyncId" value={osyncId || undefined} onChange={(value) => setOsyncId(value)}>
              {customerResData?.account?.osyncId && (
                <Select.Option value={customerResData?.account?.osyncId}>{customerResData?.account?.osyncId}</Select.Option>
              )}
            </Select>
          </label>
        </div>
        <div className="grid-item">
          <label htmlFor="integId">
            IntegId
            <Select id="integId" placeholder="Select an IntegId" value={integId || undefined} onChange={(value) => setIntegId(value)}>
              {customerResData?.integration?.length > 0 &&
                customerResData?.integration?.map((integ, index) => (
                  <Select.Option key={index} value={integ.integId}>
                    {integ.integId}
                  </Select.Option>
                ))}
            </Select>
          </label>
        </div>
        <div className="grid-item">
          <label htmlFor="rightServiceId">
            RightServiceId
            <Select
              id="rightserviceid"
              placeholder="Select an RightServiceId"
              value={rightServiceId || undefined}
              onChange={(value) => setRightServiceId(value)}
            >
              {customerResData?.integration?.length > 0 &&
                customerResData?.integration?.map((rightSerId, index) => (
                  <Select.Option key={index} value={rightSerId.rightServiceId}>
                    {rightSerId.rightServiceId}
                  </Select.Option>
                ))}
            </Select>
          </label>
        </div>
        <div className="grid-item">
          <label htmlFor="osyncUserId">
            OsyncUserId
            <Select
              id="osyncuserid"
              value={osyncUserId || undefined}
              placeholder="Select an OsyncUserId"
              onChange={(value) => setOsyncUserId(value)}
            >
              {userData?.length > 0 &&
                userData?.map((userId, index) => (
                  <Select.Option key={index} value={userId.osyncUserId}>
                    {userId.osyncUserId}
                  </Select.Option>
                ))}
            </Select>
          </label>
        </div>
      </div>
      <div className="d-flex flex-row-reverse">
        <div className="divContainer">
          <div className="submit">
            <Flex gap="small" wrap="wrap">
              <Button
                className="ant-btn css-dev-only-do-not-override-amq5gd ant-btn-default"
                onClick={getEncodedUrl}
                disabled={isButtonDisabled}
              >
                Get Encrypted String
              </Button>
            </Flex>
          </div>

          <div className="UrlEccodedString">
            {urlEncodedString && (
              <Paragraph
                copyable={{
                  icon: [<SmileOutlined key="copy-icon" />, <SmileFilled key="copied-icon" />],
                  tooltips: ["click here to copy", "copied !!"],
                }}
                className="UrlEccodedString"
              >
                {urlEncodedString}
              </Paragraph>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}
