import React, { useContext, useEffect, useState, useMemo } from "react";

import { Select, Spin, Row, Col, Divider, Input, Empty, Tooltip } from "antd";
import { AppContext } from "../../context/AppContext";
import useHttp from "../../hooks/useHttp";
import useTemplates from "../../hooks/useTemplates";
import { RefreshButton } from "../utils/CommonVessels";
import { LoadingOutlined } from "@ant-design/icons";
import ZohoCRM from "../../controller/ZohoCRMController";

export const SavedTemplatesSelect = React.memo((props) => {
  const {
    setTemplateId,
    templateId,
    setMessageAreaLoading,
    handleMessage,
    tabIndex,
    moduleName,
    openAddTemplateDrawer,
    showPreview,
    refetchOnModuleChange,
    fetchOnlyByModule,
    handleMedia,
  } = props;

  const [appProperties] = useContext(AppContext);
  const [savedTemplatesList, setSavedTemplatesList] = useState([]);
  const [templateLoading, setTemplateLoading] = useState(true);
  const [templateApi, setTemplateApi] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState(templateId);
  const [currentModule, setCurrentModule] = useState(moduleName);

  const { fetchSavedTemplates, fetchSavedTemplatesByModule } = useTemplates();
  const { fetchData } = useHttp();

  function handleTemplateId(templateIdSelected) {
    if (templateIdSelected === "add::::Templates") {
      appProperties?.controller?.getUrlParamsForRedirection(true, appProperties).then(function (redirectionUrl) {
        if (moduleName) {
          let templateUrlLink = "/templates?" + redirectionUrl + "&resource=" + moduleName + "&openTemplate=true";
          window.open(templateUrlLink, "_blank");
        } else {
          appProperties?.controller?.getAssociatedObjectType().then(function (moduleName) {
            let templateUrlLink = "/templates?" + redirectionUrl + "&resource=" + moduleName + "&openTemplate=true";
            window.open(templateUrlLink, "_blank");
          });
        }
      });
    } else {
      props.setTemplateId(templateIdSelected);
    }
  }
  function handleTemplateLoading(templateToBeLoaded) {
    setTemplateLoading(templateToBeLoaded);
  }

  const handleOnChange = (v) => {
    handleTemplateLoading(true);
    setTemplateId(v);
    setSelectedTemplateId(v);
    handleTemplateId(v);
  };

  const previewTemplate = (templateId) => {
    if (templateId !== "add::::Templates") {
      setMessageAreaLoading(true);
      appProperties.controller.getAssociatedObjectType().then(function (associatedModule) {
        appProperties.controller.getAssociatedObjectId().then(function (associatedId) {
          let integId = appProperties.randomIntegId;

          if (!showPreview) {
            associatedId = "";
            associatedModule = "";
          }

          fetchData(
            `omessage/${integId}/template/${templateId}/preview?&associatedObjectId=${associatedId}&associatedObjectType=${associatedModule}`,
            "GET",
            null,
            appProperties
          )
            .then((response) => {
              const responseData = JSON.parse(response);
              setMessageAreaLoading(false);
              setTemplateLoading(false);
              if (responseData?.data?.data || responseData?.data?.mediaFileList) {
                setTemplateId(templateId);
                handleMessage(responseData?.data?.data || "");
                setSelectedTemplateId("");
              }
            })
            .catch((error) => {});
        });
      });
    } else {
      setTemplateLoading(false);
    }
  };
  useEffect(() => {
    if (refetchOnModuleChange) handleFetchTemplates(true, true);
  }, [moduleName]);

  useEffect(() => {
    console.log("setSelectedTemplateId >>>>>>>>>>>>> templateId kumarrrrr>>>>>>>>>>", templateId);
    if (templateId) {
      setSelectedTemplateId(templateId);
    } else {
      setSelectedTemplateId("");
    }
  }, [templateId, showPreview]);

  useEffect(() => {
    if (selectedTemplateId) {
      console.log("setSelectedTemplateId >>>>>>>>>>>>> selectedTemplateId dinesss>>>>>>>>>>", selectedTemplateId);
      previewTemplate(selectedTemplateId);
      let selectedTemplateEntity = savedTemplatesList.find((item) => item.templateId.toLowerCase().includes(selectedTemplateId));
      handleMedia(selectedTemplateEntity?.mediaFileList || []);
    }
  }, [selectedTemplateId, savedTemplatesList]);

  useEffect(() => {
    appProperties?.controller?.getAssociatedObjectType().then(function (currentModuleFromApplication) {
      if (currentModuleFromApplication) {
        setCurrentModule(currentModuleFromApplication);
      }
    });
  }, [appProperties?.controller, ZohoCRM?.serviceInitialized]);

  const handleFetchTemplates = async (refresh, templatesSelectOpen) => {
    if ((!templateApi && templatesSelectOpen) || (refresh && templatesSelectOpen)) {
      setTemplateLoading(true);
      let moduleToFetch = moduleName || currentModule;
      if (moduleToFetch) {
        fetchSavedTemplatesByModule(appProperties, moduleToFetch).then((responseData) => {
          var templatesData = [];

          if (responseData?.data) {
            templatesData = responseData.data;

            templatesData = templatesData.map((item) => {
              return {
                ...item,
                value: item.templateId,
                label: (
                  <div className="savedTemplateHover">
                    <Row>
                      <Col className="text-truncate" span={22}>
                        {item.friendlyName}
                      </Col>
                      <Col span={2} style={{ display: "none" }} className="savedTemplateEye">
                        <Tooltip placement={"rightBottom"} title={item?.content}>
                          <div className="d-flex justify-content-end">
                            <div className="actionIconsSprite  previewTemplateEyeIcon " />
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                  </div>
                ),
              };
            });
          }
          setTemplateLoading(false);
          setSavedTemplatesList(templatesData);
          setTemplateApi(true);
        });
      } else if (!fetchOnlyByModule) {
        fetchSavedTemplates(appProperties)
          .then((responseData) => {
            var templatesData = [];

            if (responseData?.data) {
              templatesData = responseData.data;

              templatesData = templatesData.map((item) => {
                return {
                  ...item,
                  value: item.templateId,
                  label: item.friendlyName,
                };
              });
            }
            setTemplateLoading(false);
            setSavedTemplatesList(templatesData);
            setTemplateApi(true);
          })
          .finally(() => setTemplateLoading(false));
      }
    }
  };

  let templatesOption = useMemo(() => {
    return savedTemplatesList.filter((item) => item.friendlyName.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [savedTemplatesList, searchTerm]);

  const handleFetchTemplatesButton = (e) => {
    handleFetchTemplates("refresh", e);
  };

  return (
    <Select
      tabIndex={tabIndex}
      // open={true}
      onMouseDown={(e) => handleFetchTemplates(null, e)}
      bordered={false}
      style={{
        width: 120,
        height: 29,
        backgroundColor: "#F4F4FF",
        borderRadius: 8,
        cursor: "pointer",
      }}
      dropdownStyle={{ width: 300 }}
      placeholder={
        <span style={{ color: "#333" }} className="ms-1 hs-fs-13">
          Template
        </span>
      }
      value={templateId}
      options={templatesOption}
      optionFilterProp="children"
      filterOption={(input, option) => (option?.label ?? "").toString().includes(input)}
      suffixIcon={<div className="actionIconsSprite templatesDropdownIcon" />}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "")
          .toString()
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toString().toLowerCase())
      }
      dropdownRender={(menu) => (
        <>
          <div className="p-2 pb-0">
            <Row className="pe-2">
              <Col span={18} className="pt-1" style={{ fontWeight: 600 }}>
                Templates
              </Col>
              <Col span={3}>
                <RefreshButton onClickFunction={handleFetchTemplatesButton} loading={templateLoading} />
              </Col>
              <Col span={2}>
                <div onClick={() => handleTemplateId("add::::Templates")} className="btn mt-0 pt-0">
                  <div className="actionIconsSprite addTemplatePlusIcon" />
                </div>
              </Col>
            </Row>
          </div>
          <Divider />
          <div className="px-1">
            <div className="d-flex mt-1 mb-1 ">
              <Input
                size="small"
                className="rounded"
                placeholder={"Search template"}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                bordered={false}
              />
            </div>
          </div>
          <Divider />
          <div className="d-flex flex-column">
            <Spin indicator={<LoadingOutlined spin />} spinning={templateLoading}>
              <div className="overflow-scroll" style={{ maxHeight: "19dvh", minHeight: "15dvh" }}>
                {menu}
              </div>
            </Spin>
          </div>
        </>
      )}
      onSelect={(v) => handleOnChange(v)}
      notFoundContent={
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 60,
          }}
          description={<span>No templates!</span>}
        ></Empty>
      }
    />
  );
});

export default SavedTemplatesSelect;
