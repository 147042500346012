import { Row, Col } from "antd";
import "../../assets/css/setting.css";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const updatedSearch = "?" + params.toString();

  const navigateTo = (path) => {
    navigate(path + updatedSearch);
  };

  const settingsOptions = [
    {
      key: "general",
      label: "General",
      description:
        "Manage your email notifications and logging preferences in the general settings. Tailor these options to suit your communication and tracking needs.",
      iconClass: "generalSettingsIcon",
      onClick: () => navigateTo("/settings/general"),
    },
    {
      key: "template",
      label: "Template",
      description: "Manage and customize message templates for consistent, personalized communication.",
      iconClass: "templateSettingsIcon",
      onClick: () => navigateTo("/settings/templates"),
    },
    {
      key: "users",
      label: "Users",
      description: "Manage user access and permissions to streamline team collaboration and ensure secure communication.",
      iconClass: "usersSettingsIcon",
      onClick: () => navigateTo("/settings/users"),
    },
    {
      key: "channels",
      label: "Channels",
      description:
        "Build strong customer connections through various channels. Engage with personalized support to foster lasting relationships.",
      iconClass: "channelsSettingsIcon",
      onClick: () => navigateTo("/settings/channels"),
      settingsSecondRow: true,
    },
  ];

  return (
    <>
      <Row className="ps-4 ms-2">
        <Row className="ps-4 pt-5 ms-4 mt-3">
          <Col span={24} className="interFontWeightMedium" style={{ fontSize: 15, color: "#111827", marginBottom: 10 }}>
            Configuration Settings
          </Col>
          <Col span={24} style={{ fontSize: 12, color: "#374151" }}>
            Easily configure your settings to optimize performance. Customize the app to fit your specific needs and preferences.
          </Col>
        </Row>
        <Row className="p-5">
          {settingsOptions.map((option, index) => (
            <Col
              key={option.key}
              style={{ cursor: "pointer" }}
              span={8}
              className={classNames("p-3 settingsHover", {
                "mt-4": index >= 3, // Adds top margin to the second row and beyond
              })}
            >
              <Row onClick={option.onClick} className={classNames({ "pt-1": option.settingsSecondRow })}>
                <Col span={3}>
                  <div className={`actionIconsSprite ${option.iconClass}`} />
                </Col>
                <Col span={21} style={{ paddingLeft: 2 }}>
                  <Row>
                    <h6 style={{ color: "#111827", fontSize: 14 }}>{option.label}</h6>
                  </Row>
                  <Row className="interFontWeightMedium" style={{ fontSize: 12, color: "#9CA3AF" }}>
                    {option.description}
                  </Row>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Row>
    </>
  );
};

export default Settings;
