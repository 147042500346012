import { createContext, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
 const[customerResData, setCustomerResData] = useState(null);
 const [zohoCRMData, setZohoCRMData] = useState(null);
  const [currentSideTab, setCurrentSideTab] = useState("customers");
  return (
    <AuthContext.Provider
      value={{
        isAdmin,
        setIsAdmin,
        currentSideTab,
        setCurrentSideTab,
        customerResData,
        setCustomerResData,
        zohoCRMData,
        setZohoCRMData,
        }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
