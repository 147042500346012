import { Button, message, Popconfirm, Space } from "antd";
import useAdminHttp from "../hooks/useAdminHttp";
export default function AccountActions({ fetchCustomerData, accountData }) {
  const { fetchData } = useAdminHttp();
  const removerCustomerHandler = () => {
    fetchData("customer?osyncId=" + accountData["Osync ID"], "delete", null)
      .then((res) => {
        if (res?.status === 200 && res?.data) {
          message.success("Customer removed successfully");
          fetchCustomerData({ osyncId: accountData["Osync ID"] });
        } else {
          throw new Error("Error removing user");
        }
      })
      .catch((err) => {
        message.error(err.message);
        console.log(err);
      });
  };
  return (
    <Space>
      <Popconfirm onConfirm={removerCustomerHandler} title={"Are you sure want to remove this customer and his organisation details ?"}>
        <Button danger>Remove customer</Button>
      </Popconfirm>
    </Space>
  );
}
