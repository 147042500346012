export default function CampaignOverviewHeader(props) {
  return (
    <div className="ms-3 my-3">
      <div className="hs-fs-17">Campaign Overview</div>
      <div className="hs-fs-15">
        Effortlessly track and analyze campaign performance on the Campaign Overview page, streamlining your strategic decision-making process.
      </div>
    </div>
  );
}
