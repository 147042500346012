import { Button } from "antd";
import { Link, useLocation } from "react-router-dom";

export default function CampaignHeader(props) {
  const { appProperties } = props;
  const isAppInstalledAlready = appProperties.installedApps?.length > 0;
  const location = useLocation();
  return (
    <div className="d-flex justify-content-between ms-3">
      <div>
        {/* Empty div for alignment purpose */}
        <div></div>
      </div>
    </div>
  );
}
