import useHttp from "./useHttp";

export default function useWorkFlow() {
  const { fetchData } = useHttp();

  const fetchWebhookNotifications = (appProperties, templateId) => {
    return new Promise((resolve, reject) => {
      let integId = appProperties?.randomIntegId;
      var url = `workflow/${integId}`;
      if (templateId) {
        url = `workflow/${integId}?templateId=${templateId}`;
      }

      fetchData(url, "GET", null, appProperties).then((response) => {
        let responseData = "";
        if (response) {
          responseData = JSON.parse(response);
          console.log(responseData, "webhookSavedNumbers response1");
        }
        resolve(responseData);
      });
    });
  };

  const fetchWebhookEvent = (appProperties) => {
    return new Promise((resolve, reject) => {
      let integId = appProperties?.randomIntegId;
      const serviceName = appProperties.controller.service;
      fetchData(`omessage/${integId}/webhookNotification/events?service=${serviceName}`, "GET", null, appProperties).then((response) => {
        let webhookEvent = "";
        if (response) {
          webhookEvent = JSON.parse(response);
          console.log(webhookEvent, "useworkflow response");
        }
        resolve(webhookEvent);
      });
    });
  };
  const fetchWebhookSavedNumbers = (appProperties) => {
    return new Promise((resolve, reject) => {
      fetchData(`helloSend/savedNumbers`, "GET", null, appProperties).then((response) => {
        let webhookSavedNumbers = "";
        if (response) {
          webhookSavedNumbers = JSON.parse(response);
          console.log(webhookSavedNumbers, "webhookSavedNumbers response");
        }
        resolve(webhookSavedNumbers);
      });
    });
  };
  const addWebhookFunc = (appProperties, body, integId) => {
    return new Promise((resolve, reject) => {
      fetchData(`omessage/${integId}/webhookNotification`, "POST", body, appProperties).then((response) => {
        let addWebhook = "";
        if (response) {
          addWebhook = JSON.parse(response);
          console.log(addWebhook, "webhookSavedNumbers response");
        }
        resolve(addWebhook);
      });
    });
  };

  const updateWebhook = (appProperties, body, eventId, integId) => {
    return new Promise((resolve, reject) => {
      fetchData(`omessage/${integId}/webhookNotification/${eventId}`, "PUT", body, appProperties).then((response) => {
        let updateWebhook = "";
        if (response) {
          updateWebhook = JSON.parse(response);
          console.log(updateWebhook, "webhookSavedNumbers response");
        }
        resolve(updateWebhook);
      });
    });
  };

  const deleteWebhook = (appProperties, eventId, integId) => {
    return new Promise((resolve, reject) => {
      fetchData(`omessage/${integId}/webhookNotification/${eventId}`, "DELETE", null, appProperties).then((response) => {
        let webhookSavedNumbers = "";
        if (response) {
          webhookSavedNumbers = JSON.parse(response);
          console.log(webhookSavedNumbers, "webhookSavedNumbers response");
        }
        resolve(webhookSavedNumbers);
      });
    });
  };

  return {
    fetchWebhookNotifications,
    fetchWebhookEvent,
    fetchWebhookSavedNumbers,
    addWebhookFunc,
    updateWebhook,
    deleteWebhook,
  };
}
