import { Layout, message } from "antd";
import { useState } from "react";

import AgentsContent from "./AgentsContent";
import AgentsHeader from "./AgentsHeader";
import AgentsActions from "../../actions/AgentsActions";
import useAdminHttp from "../../hooks/useAdminHttp";

export default function AgentsContainer(props) {
  const { fetchData } = useAdminHttp();
  const [isLoading, setIsLoading] = useState(false);
  const getAndSetCurrentAgentsData = () => {
    setIsLoading(true);
    fetchData("adminUsers", "get", null)
      .then((response) => {
        console.log("response from /adminUsers", response);
        if (response?.status === 200 && response?.data) {
          console.log("response from adminUsers :::: ", JSON.stringify(response.data));
          props.setAgentsData(props.parseJsonIntoAntDTable(response.data, false));
        } else {
          throw new Error("Something wrong happened");
        }
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <Layout
      style={{
        minHeight: "97vh",
      }}
      className="row mt-4"
    >
      <div className="row mt-2 w-100 justify-content-center">
        <div className="col-11">
          <AgentsHeader />
          <AgentsActions getAndSetCurrentAgentsData={getAndSetCurrentAgentsData} {...props} />
          <AgentsContent setIsLoading={setIsLoading} isLoading={isLoading} getAndSetCurrentAgentsData={getAndSetCurrentAgentsData} {...props} />
        </div>
      </div>
    </Layout>
  );
}
