import { Table } from "antd";
import { useEffect, useState } from "react";

import useAntdHooks from "../../hooks/useAntDHooks";

export default function TableData({ customersData, columns, rowWise }) {
  const { parseJsonIntoAntDTable } = useAntdHooks();
  const [data, setData] = useState();

  useEffect(() => {
    setData(parseJsonIntoAntDTable(customersData, rowWise));
  }, [customersData]);

  return (
    <div className="row justify-content-center">
      <Table className="col mb-3" scroll={{ x: "100%" }} columns={columns} pagination={false} dataSource={data} />
    </div>
  );
}
