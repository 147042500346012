import React from "react";
import { Divider } from "antd";

import "../../node_modules/antd/dist/antd-with-locales";
// const { LocaleProvider,locales } = window.antd;

function Home() {
  return (
    // <LocaleProvider locale={locales.fr_FR}>
    <>
      <h4>HOME</h4>
      <Divider />
    </>
    // </LocaleProvider>
  );
}

export default Home;
