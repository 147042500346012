import { Row, Col, Divider, Typography, Button, Modal, List } from "antd";
import React, { useContext, useEffect, useState } from "react";
import AuthorizePage from "../OnboardingComponents/AuthorizePage";
import { AppContext } from "../../context/AppContext";
import ConfigPhone from "./ConfigPhone";
import ServicesSidebar from "./ServicesSidebar";
import { useLocation } from "react-router-dom";
import { ProviderCommonObj, ProviderExtendedCommonObj } from "../../constants/ProviderConstants";
import { CommonLoadingV2 } from "../utils/CommonVessels";

function TextSMSAuthPage() {
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [rightServiceObj, setRightServiceObj] = useState({});
  const [showGotoLeftService, setShowGotoLeftService] = useState(false);
  const [showFinishButton, setShowFinishButton] = useState(false);
  const [buyNumberLoading, setBuyNumberLoading] = useState(false);
  const location = useLocation();
  let pathName = location.pathname.split("/").pop();
  let urlParams = new URLSearchParams(window.location.search);
  let onBoardingPage = urlParams.get("onBoardingPage");
  useEffect(() => {
    if (appProperties.rightServiceName !== pathName) {
      let assignerObj1 = Object.assign(ProviderCommonObj, ProviderExtendedCommonObj[pathName]);
      appProperties.providerCommonObj = assignerObj1;
      setAppProperties({
        ...appProperties,
        rightServiceName: pathName,
      });
    }
  }, [appProperties?.rightServiceName, pathName, appProperties, setAppProperties]);

  useEffect(() => {
    if (appProperties.apps) {
      let queryObj = appProperties?.installedApps?.find((app) => app?.right?.service_name === pathName);
      if (queryObj) {
        queryObj = {
          integId: queryObj?.integProps?.integId,
          serviceId: queryObj?.right?.service_id,
          isTrialAccount: queryObj?.right?.isTrialAccount,
          rightServiceName: queryObj?.right?.service_name,
          displayName: queryObj?.right?.service_display_name,
          authorized: queryObj?.right?.auth?.authorized,
          service_display_name: queryObj?.right?.service_display_name,
        };
      }
      let queryObj2 =
        appProperties?.apps?.SMS?.find((app) => app.name === pathName) ||
        appProperties?.apps?.MESSENGER?.find((app) => app.name === pathName) ||
        appProperties?.apps?.WHATSAPP?.find((app) => app.name === pathName);
      if (queryObj2) {
        queryObj2 = {
          integId: queryObj2?.integId,
          serviceId: queryObj2?.serviceId,
          isTrialAccount: queryObj2?.isTrialAccount,
          rightServiceName: queryObj2?.name,
          displayName: queryObj2?.displayName,
          authorized: queryObj2?.authorized,
          service_display_name: queryObj2?.displayName,
        };
      }
      setRightServiceObj(queryObj || queryObj2);
    }
  }, [appProperties?.apps, appProperties?.installedApps, pathName]);

  var redirectPage = appProperties.domain + "/persons/list";
  if (appProperties?.controller?.redirectPage) {
    redirectPage = appProperties?.controller?.redirectPage;
  }

  return (
    <>
      {buyNumberLoading && <CommonLoadingV2 />}
      {appProperties.serviceInstalled ? (
        <Row className="ms-4 mt-2" style={{ marginRight: "10px" }}>
          <Col span={18}>
            {!onBoardingPage ? (
              <Row className="pt-3">
                <Col className="actionIconsSprite authInfo-icon"></Col>
                <Col className="ms-2">Seamlessly connect with your clients on their preferred messaging platforms.</Col>
              </Row>
            ) : null}
          </Col>
          <Col span={6} className="d-flex align-items-end justify-content-end">
            {showFinishButton && rightServiceObj?.authorized && onBoardingPage && (
              <button
                onClick={() => {
                  setShowGotoLeftService(true);
                }}
                class="goToServiceBtn"
              >
                <span>Go To {appProperties?.leftServiceDisplayName}</span>
                <svg width="15px" height="10px" viewBox="0 0 13 10">
                  <path d="M1,5 L11,5"></path>
                  <polyline points="8 1 12 5 8 9"></polyline>
                </svg>
              </button>
            )}
          </Col>
        </Row>
      ) : null}
      {/* <BuyNumber open={showBuyNumber} closeDrawer={closeDrawer} /> */}
      <div className="ms-4 mt-3">
        <Row>
          {appProperties.serviceInstalled && !onBoardingPage ? (
            <Col xxl={4} lg={6} md={6} xl={4} sm={8} xs={24}>
              <ServicesSidebar />
            </Col>
          ) : null}
          <Col xxl={20} lg={18} md={18} xl={20} sm={16} xs={24}>
            <Row className="ms-3">
              <Col
                sm={23}
                md={23}
                xl={11}
                xxl={11}
                xs={24}
                lg={11}
                className="bg-white pb-4"
                style={{ height: "100%", minHeight: "88svh", borderRadius: 12 }}
              >
                <Step1 serviceName={rightServiceObj?.displayName} rightServiceObj={rightServiceObj} setRightServiceObj={setRightServiceObj} />
              </Col>
              <Col
                sm={23}
                md={23}
                xl={11}
                xxl={11}
                xs={24}
                lg={11}
                className="bg-white ms-3 p-2 pt-0"
                style={{
                  height: "100%",
                  minHeight: "88svh",
                  borderRadius: 12,
                }}
              >
                {!rightServiceObj?.authorized ? (
                  <div>
                    <Row className="mt-3 pt-1 ms-4 d-flex align-items-center ">
                      <span>
                        <Typography.Title
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          How to Authorize {rightServiceObj?.displayName} ?
                        </Typography.Title>
                        <List
                          dataSource={ProviderExtendedCommonObj?.[rightServiceObj?.rightServiceName]?.AuthorizeReference ?? []}
                          bordered={false}
                          className="me-2 bullet-list"
                          renderItem={(item) => (
                            <List.Item className="bullet-list-item" style={{ borderBlockEnd: "0px" }}>
                              <div className="d-flex">
                                <span className="me-3"> &gt; </span>
                                <Typography.Text> {item}</Typography.Text>
                              </div>
                            </List.Item>
                          )}
                        />
                        <Typography
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          {ProviderExtendedCommonObj?.[rightServiceObj?.rightServiceName]?.AuthorizeReferenceText ?? []}
                        </Typography>
                      </span>
                    </Row>
                    <Row className="ps-3 pe-3">
                      <div>
                        <iframe
                          className="mt-5"
                          width="400"
                          height="250"
                          src={ProviderExtendedCommonObj?.[rightServiceObj?.rightServiceName]?.AuthorizeReferenceURL ?? []}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title="YouTube video"
                        ></iframe>
                      </div>
                    </Row>
                  </div>
                ) : (
                  <ConfigPhone
                    rightServiceObj={rightServiceObj}
                    setRightServiceObj={setRightServiceObj}
                    setShowFinishButton={setShowFinishButton}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Modal
        header={null}
        footer={null}
        title={null}
        open={showGotoLeftService}
        centered
        closable
        className="finish-modal"
        onCancel={() => {
          setShowGotoLeftService(false);
        }}
        width={900}
      >
        <Row>
          <Col span={12} className="finish-modal-left w-100 h-100">
            <div className="m-3">
              <div className="finish-modal-content m-3">
                <h2 className="hs-fw-600">SMS Channel Activated</h2>
                <p>Stay Connected, Stay Engaged: SMS Your Way to Clients.</p>
              </div>
              <Button
                className="gotoLeftService-btn ms-3 mb-5 hs-fw-600"
                onClick={() => {
                  window.open(redirectPage);
                }}
              >
                Go to {appProperties?.leftServiceDisplayName}
              </Button>
            </div>
          </Col>
          <Col span={12} className="finish-modal-right d-flex align-items-center w-100 h-100">
            <div className="d-flex align-items-center justify-content-center">{appProperties?.controller?.sendSmsVideoComponent}</div>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

const Step1 = ({ serviceName, rightServiceObj, setRightServiceObj }) => {
  return (
    <div>
      <Row className="mt-2 ms-4 d-flex align-items-center ">
        <Col className="ms-4 me-2 staticIconsSprite stepOneAuthorizeIcon"></Col>
        <span>
          <Typography.Title
            style={{
              color: "#374151",
              textTransform: "capitalize",
              fontSize: 16,
              fontWeight: 500,
              paddingTop: 10,
            }}
          >
            Authorize {serviceName}
          </Typography.Title>
        </span>
      </Row>
      <Divider className="w-50 ms-auto me-auto mt-2 pt-1 mb-1" style={{ minWidth: "50%", borderBlockStart: "2px solid #F3F3F3" }} />
      <AuthorizePage setRightServiceObj={setRightServiceObj} rightServiceObj={rightServiceObj} />
    </div>
  );
};

export default TextSMSAuthPage;
