import React from "react";

import PricingPage from "../../pages/PricingPage";

import { Routes, Route } from "react-router-dom";

function Pricingbar(props) {
  return (
    <Routes>
      <Route path="/pricing" element={<PricingPage />} />
    </Routes>
  );
}

export default Pricingbar;
