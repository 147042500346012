import { Divider } from "antd";
import Title from "antd/es/typography/Title";

export default function AgentsHeader() {
  return (
    <Title>
      Admins
      <Divider />
    </Title>
  );
}
