import React, { useState, useMemo } from "react";
import ReactFlagsSelect from "react-flags-select";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
const customLabels = {
  AF: { primary: "+93 Afghanistan" },
  AL: { primary: "+355 Albania" },
  DZ: { primary: "+213 Algeria" },
  AS: { primary: "+1684 American Samoa" },
  AD: { primary: "+376 Andorra" },
  AO: { primary: "+244 Angola" },
  AI: { primary: "+1264 Anguilla" },
  AQ: { primary: "+672 Antarctica" },
  AG: { primary: "+1268 Antigua and Barbuda" },
  AR: { primary: "+54 Argentina" },
  AM: { primary: "+374 Armenia" },
  AW: { primary: "+297 Aruba" },
  AU: { primary: "+61 Australia" },
  AT: { primary: "+43 Austria" },
  AZ: { primary: "+994 Azerbaijan" },
  BS: { primary: "+1242 Bahamas" },
  BH: { primary: "+973 Bahrain" },
  BD: { primary: "+880 Bangladesh" },
  BB: { primary: "+1246 Barbados" },
  BY: { primary: "+375 Belarus" },
  BE: { primary: "+32 Belgium" },
  BZ: { primary: "+501 Belize" },
  BJ: { primary: "+229 Benin" },
  BM: { primary: "+1441 Bermuda" },
  BT: { primary: "+975 Bhutan" },
  BO: { primary: "+591 Bolivia" },
  BQ: { primary: "+599 Bonaire, Sint Eustatius and Saba" },
  BA: { primary: "+387 Bosnia and Herzegovina" },
  BW: { primary: "+267 Botswana" },
  BV: { primary: "+47 Bouvet Island" },
  BR: { primary: "+55 Brazil" },
  IO: { primary: "+246 British Indian Ocean Territory" },
  BN: { primary: "+673 Brunei Darussalam" },
  BG: { primary: "+359 Bulgaria" },
  BF: { primary: "+226 Burkina Faso" },
  BI: { primary: "+257 Burundi" },
  CV: { primary: "+238 Cabo Verde" },
  KH: { primary: "+855 Cambodia" },
  CM: { primary: "+237 Cameroon" },
  CA: { primary: "+1 Canada" },
  KY: { primary: "+1345 Cayman Islands" },
  CF: { primary: "+236 Central African Republic" },
  TD: { primary: "+235 Chad" },
  CL: { primary: "+56 Chile" },
  CN: { primary: "+86 China" },
  CX: { primary: "+61 Christmas Island" },
  CC: { primary: "+61 Cocos (Keeling) Islands" },
  CO: { primary: "+57 Colombia" },
  KM: { primary: "+269 Comoros" },
  CD: { primary: "+243 Congo, Democratic Republic of the" },
  CG: { primary: "+242 Congo, Republic of the" },
  CK: { primary: "+682 Cook Islands" },
  CR: { primary: "+506 Costa Rica" },
  HR: { primary: "+385 Croatia" },
  CU: { primary: "+53 Cuba" },
  CW: { primary: "+599 Curaçao" },
  CY: { primary: "+357 Cyprus" },
  CZ: { primary: "+420 Czech Republic" },
  DK: { primary: "+45 Denmark" },
  DJ: { primary: "+253 Djibouti" },
  DM: { primary: "+1767 Dominica" },
  DO: { primary: "+1809 Dominican Republic" },
  EC: { primary: "+593 Ecuador" },
  EG: { primary: "+20 Egypt" },
  SV: { primary: "+503 El Salvador" },
  GQ: { primary: "+240 Equatorial Guinea" },
  ER: { primary: "+291 Eritrea" },
  EE: { primary: "+372 Estonia" },
  SZ: { primary: "+268 Eswatini" },
  ET: { primary: "+251 Ethiopia" },
  FK: { primary: "+500 Falkland Islands" },
  FJ: { primary: "+679 Fiji" },
  FI: { primary: "+358 Finland" },
  FR: { primary: "+33 France" },
  GA: { primary: "+241 Gabon" },
  GM: { primary: "+220 Gambia" },
  GE: { primary: "+995 Georgia" },
  DE: { primary: "+49 Germany" },
  GH: { primary: "+233 Ghana" },
  GI: { primary: "+350 Gibraltar" },
  GR: { primary: "+30 Greece" },
  GL: { primary: "+299 Greenland" },
  GD: { primary: "+1473 Grenada" },
  GP: { primary: "+590 Guadeloupe" },
  GU: { primary: "+1671 Guam" },
  GT: { primary: "+502 Guatemala" },
  GG: { primary: "+44 Guernsey" },
  GN: { primary: "+224 Guinea" },
  GW: { primary: "+245 Guinea-Bissau" },
  GY: { primary: "+592 Guyana" },
  HT: { primary: "+509 Haiti" },
  HM: { primary: "+672 Heard Island and McDonald Islands" },
  VA: { primary: "+379 Holy See" },
  HN: { primary: "+504 Honduras" },
  HK: { primary: "+852 Hong Kong" },
  HU: { primary: "+36 Hungary" },
  IS: { primary: "+354 Iceland" },
  IN: { primary: "+91 India" },
  ID: { primary: "+62 Indonesia" },
  IR: { primary: "+98 Iran" },
  IQ: { primary: "+964 Iraq" },
  IE: { primary: "+353 Ireland" },
  IM: { primary: "+44 Isle of Man" },
  IL: { primary: "+972 Israel" },
  IT: { primary: "+39 Italy" },
  CI: { primary: "+225 Ivory Coast" },
  JM: { primary: "+1-876 Jamaica" },
  JP: { primary: "+81 Japan" },
  JE: { primary: "+44 Jersey" },
  JO: { primary: "+962 Jordan" },
  KZ: { primary: "+7 Kazakhstan" },
  KE: { primary: "+254 Kenya" },
  KI: { primary: "+686 Kiribati" },
  KP: { primary: "+850 Korea, North" },
  KR: { primary: "+82 Korea, South" },
  KW: { primary: "+965 Kuwait" },
  KG: { primary: "+996 Kyrgyzstan" },
  LA: { primary: "+856 Laos" },
  LV: { primary: "+371 Latvia" },
  LB: { primary: "+961 Lebanon" },
  LS: { primary: "+266 Lesotho" },
  LR: { primary: "+231 Liberia" },
  LY: { primary: "+218 Libya" },
  LI: { primary: "+423 Liechtenstein" },
  LT: { primary: "+370 Lithuania" },
  LU: { primary: "+352 Luxembourg" },
  MO: { primary: "+853 Macau" },
  MG: { primary: "+261 Madagascar" },
  MW: { primary: "+265 Malawi" },
  MY: { primary: "+60 Malaysia" },
  MV: { primary: "+960 Maldives" },
  ML: { primary: "+223 Mali" },
  MT: { primary: "+356 Malta" },
  MH: { primary: "+692 Marshall Islands" },
  MQ: { primary: "+596 Martinique" },
  MR: { primary: "+222 Mauritania" },
  MU: { primary: "+230 Mauritius" },
  YT: { primary: "+262 Mayotte" },
  MX: { primary: "+52 Mexico" },
  FM: { primary: "+691 Micronesia" },
  MD: { primary: "+373 Moldova" },
  MC: { primary: "+377 Monaco" },
  MN: { primary: "+976 Mongolia" },
  ME: { primary: "+382 Montenegro" },
  MS: { primary: "+1664 Montserrat" },
  MA: { primary: "+212 Morocco" },
  MZ: { primary: "+258 Mozambique" },
  MM: { primary: "+95 Myanmar" },
  NA: { primary: "+264 Namibia" },
  NR: { primary: "+674 Nauru" },
  NP: { primary: "+977 Nepal" },
  NL: { primary: "+31 Netherlands" },
  NZ: { primary: "+64 New Zealand" },
  NI: { primary: "+505 Nicaragua" },
  NE: { primary: "+227 Niger" },
  NG: { primary: "+234 Nigeria" },
  NU: { primary: "+683 Niue" },
  NF: { primary: "+672 Norfolk Island" },
  MP: { primary: "+1670 Northern Mariana Islands" },
  NO: { primary: "+47 Norway" },
  OM: { primary: "+968 Oman" },
  PK: { primary: "+92 Pakistan" },
  PW: { primary: "+680 Palau" },
  PS: { primary: "+970 Palestine" },
  PA: { primary: "+507 Panama" },
  PG: { primary: "+675 Papua New Guinea" },
  PY: { primary: "+595 Paraguay" },
  PE: { primary: "+51 Peru" },
  PH: { primary: "+63 Philippines" },
  PL: { primary: "+48 Poland" },
  PT: { primary: "+351 Portugal" },
  PR: { primary: "+1787 Puerto Rico" },
  QA: { primary: "+974 Qatar" },
  RE: { primary: "+262 Réunion" },
  RO: { primary: "+40 Romania" },
  RU: { primary: "+7 Russia" },
  RW: { primary: "+250 Rwanda" },
  WS: { primary: "+685 Samoa" },
  SM: { primary: "+378 San Marino" },
  ST: { primary: "+239 São Tomé and Príncipe" },
  SA: { primary: "+966 Saudi Arabia" },
  SN: { primary: "+221 Senegal" },
  RS: { primary: "+381 Serbia" },
  SC: { primary: "+248 Seychelles" },
  SL: { primary: "+232 Sierra Leone" },
  SG: { primary: "+65 Singapore" },
  SK: { primary: "+421 Slovakia" },
  SI: { primary: "+386 Slovenia" },
  SB: { primary: "+677 Solomon Islands" },
  SO: { primary: "+252 Somalia" },
  ZA: { primary: "+27 South Africa" },
  GS: { primary: "+500 South Georgia and the South Sandwich Islands" },
  SS: { primary: "+211 South Sudan" },
  ES: { primary: "+34 Spain" },
  LK: { primary: "+94 Sri Lanka" },
  SD: { primary: "+249 Sudan" },
  SR: { primary: "+597 Suriname" },
  SJ: { primary: "+47 Svalbard and Jan Mayen" },
  SZ: { primary: "+268 Swaziland" },
  SE: { primary: "+46 Sweden" },
  CH: { primary: "+41 Switzerland" },
  SY: { primary: "+963 Syria" },
  TW: { primary: "+886 Taiwan" },
  TJ: { primary: "+992 Tajikistan" },
  TZ: { primary: "+255 Tanzania" },
  TH: { primary: "+66 Thailand" },
  TL: { primary: "+670 Timor-Leste" },
  TG: { primary: "+228 Togo" },
  TK: { primary: "+690 Tokelau" },
  TO: { primary: "+676 Tonga" },
  TT: { primary: "+1868 Trinidad and Tobago" },
  TN: { primary: "+216 Tunisia" },
  TR: { primary: "+90 Turkey" },
  TM: { primary: "+993 Turkmenistan" },
  TC: { primary: "+1649 Turks and Caicos Islands" },
  TV: { primary: "+688 Tuvalu" },
  UG: { primary: "+256 Uganda" },
  UA: { primary: "+380 Ukraine" },
  AE: { primary: "+971 United Arab Emirates" },
  GB: { primary: "+44 United Kingdom" },
  US: { primary: "+1 United States" },
  UY: { primary: "+598 Uruguay" },
  UZ: { primary: "+998 Uzbekistan" },
  VU: { primary: "+678 Vanuatu" },
  VE: { primary: "+58 Venezuela" },
  VN: { primary: "+84 Vietnam" },
  WF: { primary: "+681 Wallis and Futuna" },
  EH: { primary: "+212 Western Sahara" },
  YE: { primary: "+967 Yemen" },
  ZM: { primary: "+260 Zambia" },
  ZW: { primary: "+263 Zimbabwe" },
};

const pppp = getCountries();
console.log("getCountries() dinku >>>>>>>>>>>>>>", pppp);
const countriesData = getCountries().map((country) => ({
  code: country,
  name: country,
  phoneCode: `+${getCountryCallingCode(country)}`,
  flag: country,
}));

console.log("country  >>>>> all >>>", countriesData);

function CountriesWithCountryCode(props) {
  const { parentKey, setSettingsValue, setApiBody, value } = props;
  const [selectedCountry, setSelectedCountry] = useState(value ?? "");

  const handleSelect = (code) => {
    setSettingsValue((prev) => ({ ...(prev || []), [parentKey]: code }));
    setSettingsValue((prev) => ({ ...(prev || []), countryCode: `+${getCountryCallingCode(code)}` }));
    setApiBody((prev) => [...prev, parentKey]);
    setApiBody((prev) => [...prev, "countryCode"]);
    setSelectedCountry(code);
  };
  return (
    <>
      <ReactFlagsSelect
        selected={selectedCountry}
        onSelect={handleSelect}
        customLabels={customLabels}
        countries={countriesData.map((country) => country.code)}
        placeholder="Select a country"
        fullWidth={true}
        searchable={true}
      />
    </>
  );
}

export default CountriesWithCountryCode;
