import useHttp from "./useHttp";

export default function useConfigurePhoneField() {
  const { fetchData } = useHttp();

  const fetchPhoneFieldConfiguration = (appProperties, method, body) => {
    return new Promise((resolve, reject) => {
      if (appProperties?.module && appProperties?.osyncId) {
        fetchData(
          `service/${appProperties.leftServiceId}/phone-field/configuration?resource=${appProperties.module}`,
          method,
          body,
          appProperties
        )
          .then((phoneFieldMappingResponse) => {
            try {
              if (phoneFieldMappingResponse) {
                phoneFieldMappingResponse = JSON.parse(phoneFieldMappingResponse);

                appProperties.phoneFieldMappingResponse = phoneFieldMappingResponse.data;
                resolve(phoneFieldMappingResponse);
              } else {
                resolve("");
              }
            } catch (error) {
              console.error("Error parsing phoneFieldMappingResponse:", error);
              reject(error);
            }
          })
          .catch((error) => {
            console.error("Error fetching phone field configuration:", error);
            reject(error);
          });
      } else {
        resolve("");
      }
    });
  };
  return {
    fetchPhoneFieldConfiguration,
  };
}
