import React, { useEffect, useState, memo } from "react";
import useHttp from "../../../hooks/useHttp";
import { CheckOutlined, ExclamationOutlined } from "@ant-design/icons";
import { Collapse } from "antd";

import { Button, Checkbox, Col, Form, Input, Radio, Row, Select, Space } from "antd";
import "../../../assets/css/tendlc.css";

import { countries } from "../../../constants/AppConstants";
import { failureNotification } from "../../utils/CommonNotifications";

export default function CreateSecondaryProfile({
  endUser,
  appProperties,
  profileId,
  setCurrent,
  respData,
  formFields,
  setFormFields,
  setSecondaryProfileStatus,
}) {
  const formData = {};
  const { fetchData } = useHttp();
  const [isFormValid, setIsFormValid] = useState(formFields);
  const [otherBusinessIndustry, setOtherBusinessIndustry] = useState("business_registration_identifier");
  let rightServiceObj = appProperties.installedApps?.find((obj) => obj?.right?.service_name === "hello_send");
  let integId = rightServiceObj?.integProps?.integId;
  const [continueBtnDisabled, setContinueBtnDisabled] = useState(true);
  const [showOtherBusinessIndustry, setShowOtherBusinessIndustry] = useState(false);
  const [registerResp, setRegisterResp] = useState([]);
  const [errorPanel, setErrorPanel] = useState({});
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [continueButtonLoading, setContinueButtonLoading] = useState(false);
  const [activeForm, setActiveForm] = useState(0);
  const [countries, setCountries] = useState([]);

  const [form0] = Form.useForm();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();
  const [formFinal] = Form.useForm();
  const form = [form0, form1, form2, form3, form4];

  const saveFormByIndex = [saveForm0, saveForm1, saveForm2, saveForm3, saveForm4];

  function saveForm0() {
    form0
      .validateFields()
      .then((e) => {
        setSaveButtonLoading(true);
        console.log("form0>>>", e);
        if (isFormValid["Name & E-mail"] !== undefined) {
          var urlType = "PUT";
        } else {
          var urlType = "POST";
        }
        fetchData("hellosend/account/" + integId + "/profile/" + profileId + "?type=PROFILE", urlType, e, appProperties)
          .then(function (response) {
            response = JSON.parse(response);
            if (response.data !== undefined) {
              setIsFormValid((prevIsFormValid) => ({
                ...prevIsFormValid,
                "Name & E-mail": e,
              }));

              formData["Name & E-mail"] = e;
              setActiveForm(activeForm + 1);
              setSaveButtonLoading(false);
            } else {
              setSaveButtonLoading(false);
            }
          })
          .catch((error) => {
            failureNotification("Error", "Something went Wrong  !");
            setSaveButtonLoading(false);
          });
      })
      .catch((error) => {
        console.log("error error error error errorerrorerror error error error error error error error", error);
      });
  }
  function saveForm1() {
    form1
      .validateFields()
      .then((e) => {
        setSaveButtonLoading(true);
        if (typeof e.business_regions_of_operation === "object") {
          const selectedValuesAsString = e.business_regions_of_operation.join(",");
          e["business_regions_of_operation"] = selectedValuesAsString;
        }
        e["business_identity"] = "direct_customer";
        console.log("form>>>", e);
        if (isFormValid["Business Information"] !== undefined) {
          var urlType = "PUT";
        } else {
          var urlType = "POST";
        }
        fetchData("hellosend/account/" + integId + "/profile/" + profileId + "/business?type=PROFILE", urlType, e, appProperties)
          .then(function (response) {
            response = JSON.parse(response);
            setIsFormValid((prevIsFormValid) => ({
              ...prevIsFormValid,
              "Business Information": e,
            }));

            if (response?.data?.error?.value !== undefined) {
              const regex = /Attribute\s+([^ ]+)\s+value\s+is\s+invalid/g;
              const matches = response?.data?.error?.value?.match(regex);
              if (matches) {
                const values = matches.map((match) => match.split(" ")[1]);
                form1.setFields([
                  {
                    name: values,
                    errors: [camelCaseToTitleCase(values[0]) + " is invalid"],
                  },
                ]);
              }
            } else {
              formData["Business Information"] = e;
              setActiveForm(activeForm + 1);
            }

            setSaveButtonLoading(false);
          })
          .catch((error) => {
            failureNotification("Error", "Something went Wrong  !");
            setSaveButtonLoading(false);
          });
      })
      .catch((error) => {
        console.log("error error error error error error rerror error error ", error);
      });
  }
  function saveForm2() {
    form2
      .validateFields()
      .then((e) => {
        setSaveButtonLoading(true);
        console.log("form2>>>", e);
        if (isFormValid["Authorized Representative #1"] !== undefined) {
          var urlType = "PUT";
        } else {
          var urlType = "POST";
        }
        fetchData(
          "hellosend/account/" + integId + "/profile/" + profileId + "/person?primaryPerson=true&type=PROFILE",
          urlType,
          { authorizedPerson1: e },
          appProperties
        )
          .then(function (response) {
            response = JSON.parse(response);
            console.log(response);
            setIsFormValid((prevIsFormValid) => ({
              ...prevIsFormValid,
              "Authorized Representative #1": e,
            }));
            formData["Authorized Representative #1"] = e;
            setActiveForm(activeForm + 1);
            setSaveButtonLoading(false);
          })
          .catch((error) => {
            failureNotification("Error", "Something went Wrong  !");
            setSaveButtonLoading(false);
          });
      })
      .catch((error) => {
        console.log("error error error error error error rerror error error ", error);
      });
  }
  function saveForm3() {
    form3
      .validateFields()
      .then((e) => {
        setSaveButtonLoading(true);
        console.log("form2>>>", e);
        if (isFormValid["Authorized Representative #2"] !== undefined) {
          var urlType = "PUT";
        } else {
          var urlType = "POST";
        }
        fetchData(
          "hellosend/account/" + integId + "/profile/" + profileId + "/person?primaryPerson=false&type=PROFILE",
          urlType,
          { authorizedPerson2: e },
          appProperties
        )
          .then(function (response) {
            console.log(response);
            setIsFormValid((prevIsFormValid) => ({
              ...prevIsFormValid,
              "Authorized Representative #2": e,
            }));
            formData["Authorized Representative #2"] = e;
            setActiveForm(activeForm + 1);
            setSaveButtonLoading(false);
          })
          .catch((error) => {
            failureNotification("Error", "Something went Wrong  !");
            setSaveButtonLoading(false);
          });
      })
      .catch((error) => {
        console.log("error error error error error error rerror error error ", error);
      });
  }
  function saveForm4() {
    form4
      .validateFields()
      .then((e) => {
        setSaveButtonLoading(true);
        console.log("form2>>>", e);
        if (isFormValid["Physical Business Address"] !== undefined) {
          var urlType = "PUT";
        } else {
          var urlType = "POST";
        }
        fetchData("hellosend/account/" + integId + "/profile/" + profileId + "/address", urlType, e, appProperties)
          .then(function (response) {
            console.log(response);
            setIsFormValid((prevIsFormValid) => ({
              ...prevIsFormValid,
              "Physical Business Address": e,
            }));
            formData["Physical Business Address"] = e;
            setActiveForm(activeForm + 1);
            setSaveButtonLoading(false);
          })
          .catch((error) => {
            failureNotification("Error", "Something went Wrong  !");
            setSaveButtonLoading(false);
          });
      })
      .catch((error) => {
        console.log("error error error error error error rerror error error ", error);
      });
  }

  useEffect(() => {
    let formFields5Value = 0;
    if (formFields) {
      if (formFields["Physical Business Address"] !== undefined && formFields["Physical Business Address"] !== null) {
        formFields5Value = 5;
      } else if (formFields["Authorized Representative #2"] !== undefined && formFields["Authorized Representative #2"] !== null) {
        formFields5Value = 4;
      } else if (formFields["Authorized Representative #1"] !== undefined && formFields["Authorized Representative #1"] !== null) {
        formFields5Value = 3;
      } else if (formFields["Business Information"] !== undefined && formFields["Business Information"] !== null) {
        formFields5Value = 2;
      } else if (formFields["Name & E-mail"] !== undefined && formFields["Name & E-mail"] !== null) {
        formFields5Value = 1;
      } else {
        formFields5Value = 0;
      }
    }
    setActiveForm(formFields5Value);
  }, [formFields]);

  useEffect(() => {
    fetchData(
      "hellosend/account/" + integId + "/available/numbers?" + "leftServiceId=" + appProperties.leftServiceId,
      "GET",
      null,
      appProperties
    ).then(function (response) {
      if (response) {
        response = JSON.parse(response);
        if (response?.data?.countries) {
          let countriesList = response.data.countries;
          setCountries(countriesList);
        }
      }
    });
  }, []);

  useEffect(() => {
    const updatedFormValid = { ...isFormValid };
    delete updatedFormValid["Authorized Representative #2"];
    const definedValueCount = Object.values(updatedFormValid).filter((value) => value !== undefined).length;
    if (definedValueCount > 3) {
      setContinueBtnDisabled(false);
    } else {
      setContinueBtnDisabled(true);
    }
  }, [isFormValid]);

  const handleSubmit = () => {
    formFinal
      .validateFields()
      .then(function (values) {
        setContinueButtonLoading(true);
        console.log(values);
        fetchData("hellosend/account/" + integId + "/profile/" + profileId + "/register", "POST", null, appProperties)
          .then(function (response) {
            response = JSON.parse(response);
            setSecondaryProfileStatus(response?.data?.profile_evaluations?.status);
            if (response.data.profile_evaluations.status === "NONCOMPLIANT") {
              setRegisterResp(response.data.profile_evaluations.results);
              setContinueButtonLoading(false);
            } else {
              setFormFields(isFormValid);
              setCurrent(1);
              setContinueButtonLoading(false);
            }
          })
          .catch((error) => {
            failureNotification("Error", "Something went Wrong  !");
            setContinueButtonLoading(false);
          });
      })
      .catch((error) => {
        console.log("error error error error error error rerror error error ", error);
      });
  };

  return (
    <div className="container">
      <div className="m-3">
        <h4>Register Your Brand</h4>
        <div className="description10">
          A brand represents the company or entity that the final customer perceives as the sender of a message. To establish your brand, please
          furnish comprehensive, precise, and current company information. This data informs mobile network operators about the originator of
          messages.
        </div>
      </div>
      <Collapse bordered={false} ghost activeKey={activeForm} className="tenDlcFormCollapse">
        {endUser?.length > 0 &&
          endUser.map((obj, index) => (
            <Collapse.Panel
              extra={
                isFormValid[obj.name] !== undefined && errorPanel[obj.name] !== true ? (
                  <CheckOutlined style={{ color: "forestgreen", fontSize: "1.3rem" }} />
                ) : errorPanel[obj.name] === true ? (
                  <ExclamationOutlined style={{ color: "red", fontSize: "1.3rem" }} />
                ) : null
              }
              key={index}
              header={obj.name === "Authorized Representative #2" ? obj.name + "(Optional)" : obj.name}
              onClick={() => {
                if (
                  isFormValid[obj.name] !== undefined ||
                  Object.values(isFormValid).filter((value) => value !== undefined).length + 1 > index ||
                  (index === endUser.length - 1 &&
                    endUser[index - 1]?.name === "Authorized Representative #2" &&
                    isFormValid[endUser[index - 2]?.name] !== undefined) ||
                  index === 0
                ) {
                  setActiveForm((prevActiveForm) => (prevActiveForm === index ? null : index));
                }
              }}
              collapsible={
                isFormValid[obj.name] !== undefined ||
                Object.values(isFormValid).filter((value) => value !== undefined).length + 1 > index ||
                (index === endUser.length - 1 &&
                  endUser[index - 1]?.name === "Authorized Representative #2" &&
                  isFormValid[endUser[index - 2]?.name] !== undefined) ||
                index === 0
                  ? true
                  : "disabled"
              }
            >
              <Form
                key={index}
                id={form[index]}
                form={form[index]}
                layout="vertical"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                initialValues={formFields[obj.name]}
              >
                <div
                // className="shadow m-3"
                // style={{ backgroundColor: "aliceblue", borderRadius: 3 }}
                >
                  <Row>
                    <MapDataToFields
                      obj={obj.fields}
                      otherBusinessIndustry={otherBusinessIndustry}
                      setShowOtherBusinessIndustry={setShowOtherBusinessIndustry}
                      setOtherBusinessIndustry={setOtherBusinessIndustry}
                      respData={respData}
                      showOtherBusinessIndustry={showOtherBusinessIndustry}
                      registerResp={registerResp}
                      errorPanel={errorPanel}
                      setErrorPanel={setErrorPanel}
                      form={form}
                      countries={countries}
                    />
                  </Row>
                </div>
                <div className="d-flex justify-content-end align-items-end">
                  <Form.Item>
                    <Button id={obj.name} loading={saveButtonLoading} className="saveBtn" type="success" onClick={saveFormByIndex[index]}>
                      Next
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Collapse.Panel>
          ))}
      </Collapse>
      <div className="m-3 p-3 rounded">
        <Form form={formFinal} layout="vertical">
          <Form.Item name="Acknowledgement" label="Acknowledgement" valuePropName="checked" rules={[{ required: true }]}>
            <Checkbox>
              I declare the accuracy of the information provided and acknowledge that OAppS will use this information for identity verification
              purposes. I understand that OAppS may retain the Customer Profile information even after I close my account in the event of a
              regulatory authority or equivalent requesting a traceback.
            </Checkbox>
          </Form.Item>
        </Form>
      </div>
      <Row className="mt-5">
        <Col span={24} className="d-flex justify-content-end align-items-center">
          <Button
            disabled={continueBtnDisabled}
            loading={continueButtonLoading}
            type="primary"
            className="m-3"
            style={{ backgroundColor: "#009EFD" }}
            onClick={handleSubmit}
            id="secondaryProfile"
          >
            Continue
          </Button>
        </Col>
      </Row>
    </div>
  );
}

function camelCaseToTitleCase(input) {
  const words = input.split("_");
  const firstWord = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  const finalString = [firstWord, ...words.slice(1)].join(" ");
  return finalString;
}
const MapDataToFields = memo(
  ({
    obj,
    otherBusinessIndustry,
    setOtherBusinessIndustry,
    setShowOtherBusinessIndustry,
    respData,
    showOtherBusinessIndustry,
    registerResp,
    errorPanel,
    form,
    setErrorPanel,
  }) => {
    const index = obj.indexOf("business_regions_of_operation");
    const jobPositionOption = [
      { value: "Director", name: "Director" },
      { value: "GM", name: "GM" },
      { value: "VP", name: "VP" },
      { value: "CEO", name: "CEO" },
      { value: "CFO", name: "CFO" },
      { value: "General Counsel", name: "General Counsel" },
      { value: "Other", name: "Other" },
    ];
    if (index !== -1) {
      obj.splice(index, 1);
      obj.push("business_regions_of_operation");
    }

    useEffect(() => {
      if (registerResp?.length > 0) {
        const newErrorPanel = { ...errorPanel };
        for (var i = 0; i < 4; i++) {
          const failedFields = registerResp[i].fields.filter((field) => !field.passed);
          if (registerResp[i].passed === false) {
            const objectKey = registerResp[i].friendly_name;
            newErrorPanel[objectKey] = true;
          }
          const forms = form[i + 1];

          failedFields.map((failedField) => {
            forms.setFields([
              {
                name: failedField.object_field,
                errors: [failedField.failure_reason],
              },
            ]);
          });
        }
        setErrorPanel(newErrorPanel);
      }
    }, [registerResp]);

    return (
      <>
        {obj.map((obj, index) => (
          <React.Fragment key={index}>
            {obj === "business_industry" ||
            obj === "business_type" ||
            obj === "job_position" ||
            obj === "business_registration_identifier" ||
            obj === "isoCountry" ? (
              <>
                <Col span={12}>
                  <div className="container">
                    <Form.Item
                      // initialValue={camelCaseToTitleCase(obj)}
                      label={camelCaseToTitleCase(obj)}
                      name={obj === "business_registration_identifier" ? otherBusinessIndustry : obj}
                      rules={[
                        {
                          required: true,
                          message: camelCaseToTitleCase(obj) + " is required",
                        },
                      ]}
                    >
                      <Select
                        placeholder={"Select " + camelCaseToTitleCase(obj)}
                        showSearch={obj === "isoCountry" ? true : false}
                        filterOption={(input, option) =>
                          obj === "isoCountry" ? (option?.props?.children.toLowerCase().includes(input.toLowerCase()) ? option : null) : null
                        }
                        onChange={(e) => {
                          if (e === "Other") {
                            setShowOtherBusinessIndustry(true);
                            setOtherBusinessIndustry("Other");
                          } else {
                            setShowOtherBusinessIndustry(false);
                          }
                        }}
                      >
                        {obj === "job_position" ? (
                          <>
                            {jobPositionOption.map((obj, index) => (
                              <Select.Option key={index} value={obj.value}>
                                {obj.name}
                              </Select.Option>
                            ))}{" "}
                          </>
                        ) : obj === "isoCountry" ? (
                          <>
                            {" "}
                            {countries.sort().map((obj, index) => (
                              <Select.Option key={index} value={obj.value}>
                                {obj.label}
                              </Select.Option>
                            ))}
                          </>
                        ) : (
                          <>
                            {respData[obj].map((obj, index) => (
                              <Select.Option key={index} value={obj.value}>
                                {obj.name}
                              </Select.Option>
                            ))}
                          </>
                        )}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                {showOtherBusinessIndustry === true && obj === "business_registration_identifier" ? (
                  <Col span={12}>
                    <div className="container">
                      <Form.Item
                        label={"Name of other " + camelCaseToTitleCase(obj)}
                        name={obj}
                        rules={[
                          {
                            required: true,
                            message: camelCaseToTitleCase(obj) + " is required",
                          },
                        ]}
                      >
                        <Input placeholder={"Enter your " + camelCaseToTitleCase(obj)} />
                      </Form.Item>
                    </div>
                  </Col>
                ) : null}
              </>
            ) : obj === "secondaryVetting" ? (
              <Col span={23} className="bg-secondary m-auto mb-4">
                <Form.Item
                  name={obj}
                  rules={[
                    {
                      required: true,
                      message: camelCaseToTitleCase(obj) + " is required",
                    },
                  ]}
                >
                  <Radio.Group
                  // initialValue={data.fields[obj]}
                  >
                    <Space direction="vertical m-3">
                      <Radio value="1">Submit my bramd for secondary vetting.</Radio>
                      <Radio value="2">submit my brand without secondary vetting.</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            ) : obj === "business_regions_of_operation" ? (
              <Form.Item
                name={obj}
                label={camelCaseToTitleCase(obj)}
                rules={[
                  {
                    required: true,
                    message: camelCaseToTitleCase(obj) + " is required",
                  },
                ]}
                className="ms-3"
              >
                <Checkbox.Group>
                  <Row className="mt-3 ms-3">
                    {respData[obj].map((obj, index) => (
                      <Col span={10} key={index}>
                        {" "}
                        <Checkbox
                          value={obj.value}
                          style={{
                            lineHeight: "2em",
                          }}
                        >
                          {obj.name}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            ) : (
              <Col span={12} key={respData} hidden={obj === "business_identity" ? true : null}>
                <div className="container">
                  <Form.Item
                    label={obj === "emailId" ? "Email Id" : camelCaseToTitleCase(obj)}
                    name={obj}
                    key={obj}
                    rules={
                      obj === "email" || obj === "emailId"
                        ? [
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                            {
                              required: true,
                              message: "Email Id is required",
                            },
                          ]
                        : obj === "business_identity"
                        ? [{ required: false }]
                        : [
                            {
                              required: true,
                              message: camelCaseToTitleCase(obj) + " is required",
                            },
                          ]
                    }
                  >
                    <Input placeholder={"Enter your " + camelCaseToTitleCase(obj)} />
                  </Form.Item>
                </div>
              </Col>
            )}
          </React.Fragment>
        ))}
      </>
    );
  }
);
