import { BrowserRouter, Routes, Route } from "react-router-dom";

import { AuthProvider } from "../../admin/context/AuthProvider";
import AdminLayout from "../../admin/components/AdminLayout";
import Page404 from "../../admin/pages/Page404";

function AdminWrapper() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/admin" element={<AdminLayout />} />
          <Route path="/admin/*" element={<Page404 />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}
export default AdminWrapper;
