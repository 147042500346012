import { useState } from "react";
import ReusableFileUploadModal from "./ReusableFileUploadModal";

const MediaUploadIconWrapper = (props) => {
  const { fileList, setFileList, integId, skipFilter } = props;
  const [isUploadModalVisible, setIsUploadModalVisible] = useState();
  return (
    <>
      <ReusableFileUploadModal
        visible={isUploadModalVisible}
        setVisible={setIsUploadModalVisible}
        setFileList={setFileList}
        fileList={fileList}
        source={"sendsms"}
        integId={integId}
        skipFilter={skipFilter}
      />
      <div
        className="actionIconsSprite mediaUploadIconModern ms-1"
        onClick={() => setIsUploadModalVisible(true)}
        style={{ cursor: "pointer" }}
      ></div>
    </>
  );
};
export default MediaUploadIconWrapper;
