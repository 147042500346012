import React from "react";
import { useState, useEffect } from "react";
import { Input } from "antd";
import { Button, Flex } from "antd";
import { SmileFilled, SmileOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import useAdminHttp from "../../hooks/useAdminHttp";
import { Card } from "antd";
const { Paragraph } = Typography;

export default function UrlEncodeComponent() {
  const { fetchData } = useAdminHttp();
  const [osyncId, setOsyncId] = useState("");
  const [integId, setIntegId] = useState("");
  const [rightServiceId, setRightServiceId] = useState("");
  const [osyncUserId, setOsyncUserId] = useState("");
  const [urlEncodedString, setUrlEncodedString] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const storedOsyncId = sessionStorage.getItem("osyncId");
    const storedIntegId = sessionStorage.getItem("integId");
    const storedRightServiceId = sessionStorage.getItem("rightServiceId");
    const storedOsyncUserId = sessionStorage.getItem("osyncUserId");

    if (storedOsyncId) setOsyncId(storedOsyncId);
    if (storedIntegId) setIntegId(storedIntegId);
    if (storedOsyncUserId) setOsyncUserId(storedOsyncUserId);
    if (storedRightServiceId) setRightServiceId(storedRightServiceId);
  }, []);
  useEffect(() => {
    sessionStorage.setItem("osyncId", osyncId);
    sessionStorage.setItem("integId", integId);
    sessionStorage.setItem("rightServiceId", rightServiceId);
    sessionStorage.setItem("osyncUserId", osyncUserId);
  }, [osyncId, integId, rightServiceId, osyncUserId]);

  const getEncodedUrl = () => {
    console.log("OsyncId", osyncId);
    console.log("IntegId", integId);
    console.log("Right Service Id", rightServiceId);
    const data = {
      osyncId: osyncId,
      integId: integId,
      rightServiceId: rightServiceId,
      osyncUserId: osyncUserId,
    };

    fetchData(
      "encodeUrl?osyncId=" +
        osyncId.trim() +
        "&integId=" +
        integId.trim() +
        "&rightServiceId=" +
        rightServiceId.trim() +
        "&osyncUserId=" +
        osyncUserId.trim(),
      "get",
      data
    ).then((res) => {
      console.log(res);
      setUrlEncodedString(res.data);
    });
  };

  useEffect(() => {
    const areInputsValid = osyncId !== "" && integId !== "" && (rightServiceId !== "" || osyncUserId !== "");
    setIsButtonDisabled(!areInputsValid);
  }, [osyncId, integId, rightServiceId, osyncUserId]);

  return (
    <Card
      className="toolsCard"
      title="URL Encoded Webhook"
      bordered={false}
      style={{
        width: "100%",
      }}
    >
      <div id="getUrlEncodedString" className="grid-container">
        <div className="grid-item">
          <label htmlFor="osyncId">
            OsyncId
            <Input id="osyncId" placeholder="osyncId" onChange={(e) => setOsyncId(e.target.value)} value={osyncId} />
          </label>
        </div>
        <div className="grid-item">
          <label htmlFor="integId">
            IntegId
            <Input id="integId" placeholder="IntegId" onChange={(e) => setIntegId(e.target.value)} value={integId} />
          </label>
        </div>
        <div className="grid-item">
          <label htmlFor="rightServiceId">
            Right Service Id
            <Input
              id="rightServiceId"
              placeholder="Right Service Id"
              onChange={(e) => setRightServiceId(e.target.value)}
              value={rightServiceId}
            />
          </label>
        </div>
        <div className="grid-item">
          <label htmlFor="osyncUserId">
            Osync User Id
            <Input id="osyncUserId" placeholder="OsyncUserId" onChange={(e) => setOsyncUserId(e.target.value)} value={osyncUserId} />
          </label>
        </div>
      </div>
      <div className="d-flex flex-row-reverse">
        <div className="divContainer">
          <div className="submit">
            <Flex gap="small" wrap="wrap">
              <Button
                className="ant-btn css-dev-only-do-not-override-amq5gd ant-btn-default"
                onClick={getEncodedUrl}
                disabled={isButtonDisabled}
              >
                Get Encrypted String
              </Button>
            </Flex>
          </div>

          <div className="UrlEccodedString">
            {urlEncodedString && (
              <Paragraph
                copyable={{
                  icon: [<SmileOutlined key="copy-icon" />, <SmileFilled key="copied-icon" />],
                  tooltips: ["click here to copy", "copied !!"],
                }}
                className="UrlEccodedString"
              >
                {urlEncodedString}
              </Paragraph>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}
