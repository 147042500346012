export default function useAntDHooks() {
  // If isSeperateRow is true then the each entry is created seperately with its key as its row
  // If not then each item in the object will have seperate row
  const parseJsonIntoAntDTable = (data, isRowWise) => {
    console.log("data >>>>>> ", data);
    if (data === null) return [];

    var resultArray = [];
    var i = 1;
    if (isRowWise) {
      for (let key in data) {
        resultArray.push({
          key: i,
          name: key,
          value: data[key],
        });
        i++;
      }
    } else {
      data?.forEach((item) => {
        item["key"] = i;
        resultArray.push(item);
        i++;
      });
    }

    console.log("after parsing ::::::: ", JSON.stringify(resultArray));
    return resultArray;
  };

  return { parseJsonIntoAntDTable };
}
