import { Empty } from "antd";
export default function NoData() {
  return (
    <div className="row align-items-center" style={{ height: "75dvh" }}>
      <div className="row justify-content-center">
        <Empty />
      </div>
    </div>
  );
}
