import { useContext } from "react";
import "../../../../assets/css/campaignOverview.css";
import { AppContext } from "../../../../context/AppContext";
import CampaignOverviewContent from "./components/CampaignOverviewContent";
import CampaignOverviewHeader from "./components/CampaignOverviewHeader";

export default function CampaignOverview(props) {
  const [appProperties] = useContext(AppContext);
  return (
    <div>
      {!appProperties?.installedApps && <CampaignOverviewHeader />}
      <CampaignOverviewContent appProperties={appProperties} />
    </div>
  );
}
