import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "./../../context/AppContext";
import { Button, Row, Col } from "antd";
import "./../../assets/css/guideSendSmsPage.css";
import { CaretRightFilled, CheckCircleOutlined } from "@ant-design/icons";
import { urlParams } from "../../constants/AppConstants";
import { useNavigate } from "react-router-dom";

function importAllVideo(r) {
  let guideVideo = {};
  r.keys().map((item) => {
    guideVideo[item.replace("./", "")] = r(item);
    return null;
  });

  return guideVideo;
}
const guideVideoFun = importAllVideo(require.context("./../../assets/video/", false, /\.mp4$/));

function GuidetoSendSms() {
  const [appProperties] = useContext(AppContext);
  const [guideSendSmsVideo, setGuideSendSmsVideo] = useState("twilio.mp4");
  const [domainUrl, setDomainUrl] = useState("");
  const navigate = useNavigate();
  var [leftServiceDisplayName, setLeftServiceDisplayName] = useState("");

  let authObj = appProperties?.authorizeObj;
  console.log("authobj in account>>>>", authObj);
  let rightAuth = authObj?.right?.auth?.authorized;
  if (!rightAuth) {
    navigate("/authorize?" + urlParams);
  }

  useEffect(() => {
    if (appProperties.providerCommonObj !== undefined) {
      console.log(
        "appProperties.providerCommonObj@@@@@@@@guideusers",

        appProperties
      );

      var pipedrivePagedomainUrl = appProperties.domain + "/persons/list";
      setDomainUrl(pipedrivePagedomainUrl);

      setLeftServiceDisplayName(appProperties.displayName);

      var guideSmsSendVideo = appProperties.providerCommonObj.app + "SmsGuidePipedrive.mp4";

      setGuideSendSmsVideo(guideVideoFun[guideSmsSendVideo]);
    }
  }, [appProperties]);

  let nextIcon = <CaretRightFilled className="rightArrow" />;
  const handleClickDomainUrl = () => {
    window.open(domainUrl);
  };
  return (
    <>
      <div className="successMessage">
        <Row>
          <Col span={3}>
            <CheckCircleOutlined style={{ fontSize: "170%", color: "#43A047" }} />
          </Col>
          <Col>
            <h5>Success</h5>
            SMS channel enabled successfully
          </Col>
        </Row>
      </div>
      <div style={{ textAlign: "center" }}>
        <h2 className="successInstall">Send SMS Via {leftServiceDisplayName}</h2>
        {/* <h3 id="guideSendSmsHead">How to Send SMS Via {leftServiceDisplayName}</h3>   */}

        <video id="guideSmsVideoId" controls type="video/m4v" src={guideSendSmsVideo} />
        <p id="guidePath">
          Home {nextIcon} Contacts {nextIcon} People {nextIcon} Select Person
        </p>
        <div className="goToBtnDiv">
          <Button id="goToPipedriveButton" size="large" shape="default" type="primary" onClick={handleClickDomainUrl}>
            <h5
              style={{
                paddingTop: 5,
                fontFamily: "Inter , sans-serif",
                fontsize: 20,
              }}
            >
              Go to {leftServiceDisplayName}
            </h5>
          </Button>
        </div>
      </div>
    </>
  );
}
export default GuidetoSendSms;
