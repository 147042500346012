import { useState } from "react";
import useHttp from "./useHttp";
import { failureNotification, successNotification } from "../pages/utils/CommonNotifications";

export default function useMessage(appProperties) {
  const [loading, setLoading] = useState(false);
  const { fetchData } = useHttp();
  const sendMessage = async ({ payload, integId, onSuccess, onError, targetUrl }) => {
    setLoading(true);
    const { to, messageBody } = payload;
    if (!to) {
      failureNotification("To Number is required !");
      return;
    } else if (!messageBody) {
      failureNotification("Message Body is required !");
      return;
    }
    if (!targetUrl) {
      targetUrl = `omessage/${integId}/send`;
    }
    try {
      const response = await fetchData(targetUrl, "POST", payload, appProperties);
      if (response) {
        const parsedResponse = JSON.parse(response);
        if (parsedResponse?.data?.status) {
          successNotification("Message sent successfully");
          onSuccess && onSuccess(parsedResponse.data);
        } else {
          failureNotification("", parsedResponse.data?.data || "Failed to send message");
          onError && onError(parsedResponse.data);
        }
      }
    } catch (error) {
      failureNotification("An error occurred while sending the message");
      onError && onError(error);
    } finally {
      setLoading(false);
    }
  };

  return { sendMessage, loading };
}
