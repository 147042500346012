import { Button, Col, Drawer, Empty, Row, Select, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ASSIST_MAIL } from "../constants/AppConstants";
import { AppContext } from "../context/AppContext";
import useSavedModule from "../hooks/useModule";
import "./../assets/css/customModule.css";
import { failureNotificationWithLink, successNotifyWithDescription } from "./utils/CommonNotifications";

import { formatDate } from "./utils/CommonVessels";

function Module() {
  const [appProperties] = useContext(AppContext);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCustomModule, setSelectedCustomModule] = useState("");
  const { fetchSavedModule } = useSavedModule();
  const [savedCustomModule, setSavedCustomModule] = useState([]);
  const [selectedModuleName, setSelectedModuleName] = useState("");
  const [selectedModuleApiName, setSelectedModuleApiName] = useState("");
  const [callSavedModuleTable, setCallSavedModule] = useState(true);

  let urlParams = new URLSearchParams(window.location.search);
  let isDrawerOpenFromCampaign = urlParams.get("isDrawerOpen");

  useEffect(() => {
    if (isDrawerOpenFromCampaign) {
      setIsDrawerOpen(true);
    }
  }, []);

  const addConfigurationCustomModule = () => {
    setIsDrawerOpen(true);
    setSelectedCustomModule("");
  };
  const columns = [
    {
      title: "Module Name",
      dataIndex: "moduleName",
      key: "moduleName",
    },
    {
      title: "Lookup Field",
      dataIndex: "moduleFieldDisplayLabel",
      key: "moduleFieldDisplayLabel",
    },
    {
      title: "Phone Field",
      key: "phoneFieldDisplayLabel",
      dataIndex: "phoneFieldDisplayLabel",
    },
    {
      title: "Created Time",
      key: "createdTime",
      dataIndex: "createdTime",
    },
  ];

  useEffect(() => {
    if (appProperties?.integId && callSavedModuleTable === true) {
      fetchSavedModule(appProperties).then((response) => {
        if (response && response?.data) {
          const updatedModuleEntity = response?.data?.map((moduleEntity) => {
            return {
              ...moduleEntity,
              createdTime: <div>{formatDate(moduleEntity?.createdTime)}</div>,
            };
          });

          setSavedCustomModule(updatedModuleEntity);
          setCallSavedModule(false);
          if (selectedCustomModule === "") {
            setSelectedCustomModule(response?.data[0]);
          }
        }
        setCallSavedModule(false);
      });
    }
  }, [appProperties.integId, callSavedModuleTable]);
  return (
    <>
      <div className="d-flex align-items-end justify-content-between  mb-2 m-3">
        <div className="ms-3">
          <Row className="mt-2">
            <Col className="actionIconsSprite authInfo-icon"></Col>
            <Col className="ms-2">Configure and manage custom modules for your SMS campaigns.</Col>
          </Row>
        </div>
        <Button className="addTempBtn" type="" onClick={addConfigurationCustomModule} icon={<div className="actionIconsSprite plusIconWhite" />}>
          Add Custom Module
        </Button>
      </div>
      {isDrawerOpen ? (
        <AddCustomModule
          setIsDrawerOpen={setIsDrawerOpen}
          isDrawerOpen={isDrawerOpen}
          appProperties={appProperties}
          selectedModuleName={selectedModuleName}
          setSelectedModuleName={setSelectedModuleName}
          setSelectedModuleApiName={setSelectedModuleApiName}
          selectedModuleApiName={selectedModuleApiName}
          setCallSavedModule={setCallSavedModule}
        />
      ) : null}
      <Table className="savedTempTable" columns={columns} dataSource={savedCustomModule} pagination={false} />
    </>
  );
}

export default Module;

const AddCustomModule = React.memo((props) => {
  const {
    isDrawerOpen,
    setIsDrawerOpen,
    appProperties,
    selectedModuleName,
    setSelectedModuleName,
    setSelectedModuleApiName,
    selectedModuleApiName,
    setCallSavedModule,
  } = props;

  const [drawerOpen, setDrawerOpen] = useState(isDrawerOpen);
  const { fetchModules, fetchModuleApiName, addCustomModuleBtn } = useSavedModule();
  const [savedModules, setSavedModules] = useState([]);
  const [savedModuleApiName, setSavedModuleApiName] = useState([]);
  const [selectedDisplayedValue, setSelectedDisplayedValue] = useState("");
  const [selectedPhoneField, setSelectedPhoneField] = useState("");
  const [selectedDisplayApiName, setSelectedDisplayApiName] = useState("");
  const [selectedPhoneFieldApiName, setSelectedPhoneFieldApiName] = useState("");

  const [loadingModuleResponse, setLoadingModuleResponse] = useState(false);
  const [loadingChooseField, setLoadingChooseField] = useState(false);
  const [invalidModule, setInvalidModule] = useState(false);
  const [invalidDisplayedValue, setInvalidDisplayedValue] = useState(false);
  const [invalidPhoneField, setInvalidPhoneField] = useState(false);
  const handleClose = () => {
    setDrawerOpen(false);
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    let integId = appProperties?.randomIntegId;
    if (integId) {
      fetchModules(appProperties).then((response) => {
        setLoadingModuleResponse(false);
        setSavedModules(response?.data ?? []);
      });
    }
  }, [appProperties?.randomIntegId]);

  const handleChangeCustomModule = (value) => {
    setInvalidModule(false);
    setLoadingChooseField(true);
    const moduleNameArray = savedModules[value];
    const moduleName = moduleNameArray.moduleName;
    const moduleApiName = moduleNameArray.moduleApiName;
    setSelectedModuleName(moduleName);
    setSelectedModuleApiName(moduleApiName);
    setSelectedDisplayedValue("");
    setSelectedPhoneField("");
    fetchModuleApiName(appProperties, moduleApiName).then((response) => {
      if (response && response?.fields) {
        setLoadingChooseField(false);
        setSavedModuleApiName(response?.fields ?? []);
        setSelectedDisplayedValue(response?.fields[0]?.fieldName);
        setSelectedPhoneField(response?.fields[0]?.fieldName);
      }
      // else if (response.status === false) {
      //   setLoadingChooseField(false);
      //   failureNotificationWithLink("Module not support");
      // }
    });
  };
  const handleChangeDisplayed = (value) => {
    setInvalidDisplayedValue(false);
    const labelToBeDisplay = savedModuleApiName[value];
    console.log(labelToBeDisplay, "labelToBeDisplay");
    const labelToBeDisplayModuleName = labelToBeDisplay.fieldName;
    const labelToBeDisplayModuleApiName = labelToBeDisplay.fieldApiName;
    setSelectedDisplayedValue(labelToBeDisplayModuleName);
    setSelectedDisplayApiName(labelToBeDisplayModuleApiName);
  };
  const handleChangePhoneField = (value) => {
    setInvalidPhoneField(false);
    const phoneField = savedModuleApiName[value];
    console.log(phoneField, "phoneField");
    const phoneFieldModuleName = phoneField.fieldName;
    const phoneFieldModuleApiName = phoneField.fieldApiName;
    setSelectedPhoneField(phoneFieldModuleName);
    setSelectedPhoneFieldApiName(phoneFieldModuleApiName);
  };

  const addCustomModule = () => {
    var moduleApiName = selectedModuleApiName;
    const tempData = {
      moduleName: selectedModuleName,
      moduleApiName: selectedModuleApiName,
      moduleFieldDisplayLabel: selectedDisplayedValue,
      moduleFieldDisplayLabelApiName: selectedDisplayApiName,
      phoneFieldDisplayLabel: selectedPhoneField,
      phoneFieldApiName: selectedPhoneFieldApiName,
      integId: appProperties.integId,
    };
    console.log("selectedModuleName>>>>", selectedModuleName);
    if (selectedModuleName?.trim() === "") {
      setInvalidModule(true);
      return;
    }
    if (selectedDisplayedValue?.trim() === "") {
      setInvalidDisplayedValue(true);
      return;
    }
    if (selectedPhoneField?.trim() === "") {
      setInvalidPhoneField(true);
      return;
    }
    if (moduleApiName !== "") {
      console.log("payload fo@@@r templates3");
      addCustomModuleBtn(appProperties, tempData).then((response) => {
        console.log("response for add templates", response);
        if (response?.status) {
          console.log("response for add templates");
          setCallSavedModule(true);
          setIsDrawerOpen(false);

          successNotifyWithDescription("Add Module", "Module added. You can now send campaigns.");
        } else if (response?.license?.code === "FEATURE_LIMIT_EXCEEDED") {
          var temLimit = response?.license?.description;
          failureNotificationWithLink(temLimit, "mail to", `mailto:${ASSIST_MAIL}`);
        }
      });
    }
  };

  return (
    <>
      <Drawer
        className="templateDrawer"
        title="Add Custom Module"
        placement="right"
        open={drawerOpen}
        onClose={handleClose}
        width={"30rem"}
        footer={
          <Col span={24} className=" templatesAddButton">
            <Button size="large" className="mt-4 me-2 templateAdd" onClick={addCustomModule}>
              Add
            </Button>
          </Col>
        }
      >
        <div className="templateModal">
          <Row>
            <Col xs={24} md={22} xl={24} id="EventWebhook">
              Module
              <Select
                className="selectModule w-100 mt-1"
                size="large"
                value={selectedModuleName || "Select Module"}
                onChange={handleChangeCustomModule}
                loading={loadingModuleResponse}
              >
                {savedModules.map((value, index) => {
                  return <Select.Option key={index}>{value.moduleName}</Select.Option>;
                })}
              </Select>
            </Col>
            <div className="d-flex">
              <span id="fdnameError" style={{ display: invalidModule ? "inherit" : "none" }}>
                Choose Module
              </span>
            </div>

            <Col xs={24} md={22} xl={24} className="pt-4" id="EventWebhook">
              Choose the label to be displayed
              <Select
                className="selectModule w-100 mt-1"
                size="large"
                value={[selectedDisplayedValue]}
                onChange={handleChangeDisplayed}
                loading={loadingChooseField}
                notFoundContent={
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 60,
                    }}
                    description={<span>No fields!</span>}
                  ></Empty>
                }
              >
                {savedModuleApiName.map((value, index) => {
                  return <Select.Option value={index}>{value.fieldName}</Select.Option>;
                })}
              </Select>
            </Col>
            <div className="d-flex">
              <span id="fdnameError" style={{ display: invalidDisplayedValue ? "inherit" : "none" }}>
                Choose the display label
              </span>
            </div>
            <Col xs={24} md={22} xl={24} className="pt-4" id="FromNumberWebhook">
              Choose the phone field
              <Select
                className="selectModule w-100 mt-1"
                size="large"
                value={[selectedPhoneField]}
                onChange={handleChangePhoneField}
                loading={loadingChooseField}
                notFoundContent={
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 60,
                    }}
                    description={<span>No fields!</span>}
                  ></Empty>
                }
              >
                {savedModuleApiName.map((value, index) => {
                  return <Select.Option value={index}>{value.fieldName}</Select.Option>;
                })}
              </Select>
            </Col>
            <div className="d-flex">
              <span id="fdnameError" style={{ display: invalidPhoneField ? "inherit" : "none" }}>
                Choose a phone field
              </span>
            </div>
          </Row>
        </div>
      </Drawer>
    </>
  );
});
