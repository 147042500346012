import React, { useState, useContext, useEffect } from "react";
import { Button, Input, Modal, Empty, Row, Divider } from "antd";
import DropDownComponent from "./../../components/custom/DropDownComponent.jsx";
import { AppContext } from "./../../context/AppContext";
import useHttp from "./../../hooks/useHttp.jsx";

import { failureNotification } from "./../../pages/utils/CommonNotifications";

function SubAccountsListing(props) {
  var urlParams = new URLSearchParams(window.location.search);
  const { closeOffCanvas, selectComponentWidth, selectComponentHeight, showSubHeader } = props;
  const { fetchData } = useHttp();
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [createSubAccountModalOpen, setCreateSubAccountModalOpen] = useState(false);
  const [addUsersToSubAccount, setAddUsersToSubAccount] = useState(false);
  const [subAccountsListItems, setSubAccountsListItems] = useState();
  const [isParentOsyncAccount, setIsParentOsyncAccount] = useState(false);
  const [allSubAccountsList, setAllSubAccountsList] = useState(appProperties.subAccountsList);
  const [addedSubAccount, setAddedSubAccount] = useState({});
  const [subAccountName, setSubAccountName] = useState("Main Account");
  const [newSubAccountName, setNewSubAccountName] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!allSubAccountsList) {
      if (appProperties.subAccountsList) {
        setAllSubAccountsList(appProperties.subAccountsList);
      }
    }
    setIsParentOsyncAccount(appProperties.isParentOsyncAccount);
    if (allSubAccountsList) {
      const itemsList = allSubAccountsList.map((item) => ({
        label: item.acc_name,
        value: item.osyncId,
      }));

      setSubAccountsListItems([...itemsList]);

      if (!subAccountsListItems?.length) {
        setSubAccountName("Main Account");
      }
    }
  }, [appProperties.isParentOsyncAccount, allSubAccountsList]);

  useEffect(() => {
    let subAccountId = "";

    if (urlParams && urlParams.has("osyncId")) {
      subAccountId = urlParams.get("osyncId");
    }

    if (subAccountId) {
      if (subAccountId === "") {
        setSubAccountName("Main Account");
      } else {
        let subAccountNameForTheSelected = findLabel(subAccountId);
        setSubAccountName(subAccountNameForTheSelected);
      }
    }
  }, [subAccountsListItems]);

  const createSubAccountProcess = () => {
    if (newSubAccountName) {
      setLoading(true);
      let payloadJson = {
        subAccountName: newSubAccountName,
        currentUserEmail: appProperties?.currentUserEmail,
        leftServiceId: appProperties?.leftServiceId,
        companyOrgId: appProperties?.companyId,
        userId: appProperties?.userId,
      };
      fetchData(`accounts/sub`, "POST", payloadJson, appProperties)
        .then(function (response) {
          if (response) {
            response = JSON.parse(response);
            let addedSubAccount = response?.data?.subAccountDetails;
            if (addedSubAccount) {
              setAllSubAccountsList((prevList) => (prevList ? [...prevList, addedSubAccount] : [addedSubAccount]));
              setCreateSubAccountModalOpen(false);
              setLoading(false);

              setAppProperties((prev) => ({
                ...prev,
                subAccountsList: allSubAccountsList,
              }));

              setAddedSubAccount(addedSubAccount);
              setAddUsersToSubAccount(true);
            } else {
              let errorMessage = response?.data?.errorMessage;
              if (errorMessage) {
                failureNotification("Sub account creation failed", errorMessage);
                setLoading(false);
              }
            }
          }
        })
        .catch((error) => {
          console.log(error?.message);
          setLoading(false);
        });
    } else {
    }
  };
  const addUserProcess = () => {
    if (addedSubAccount) {
      setAddUsersToSubAccount(false);
      const url = new URL(window.location.href);

      // Get the search parameters
      const params = new URLSearchParams(url.search);

      // Change the endpoint to '/users'
      const newEndpoint = "/users";

      if (params.has("osyncId")) {
        params.delete("osyncId");
      }
      // Change the `osyncId` parameter
      params.set("osyncId", addedSubAccount.osyncId);
      params.set("showAddUser", "true");

      // Construct the new URL
      const newUrl = `${url.origin}${newEndpoint}?${params.toString()}`;

      // Reload the new URL in the browser
      window.location.href = newUrl;
    }
  };
  const switchToSubAccount = (subAccountId) => {
    if (closeOffCanvas) {
      closeOffCanvas();
    }
    if (appProperties.setLoading) {
      appProperties.setLoading(true);
    }

    try {
      if (window.self !== window.top) {
        localStorage.setItem("recent-accessed-sub-account", subAccountId);
      }
    } catch (error) {
      console.log("error retrieving recent-accessed-sub-account from local storage", error);
    }

    if (urlParams.has("osyncId")) {
      urlParams.delete("osyncId");
    }
    urlParams.append("osyncId", subAccountId);

    // To reflect the updated URL parameters in the browser's address bar
    var newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + urlParams.toString();
    window.location.href = newUrl;
  };

  const findLabel = (value) => {
    if (subAccountsListItems) {
      const result = subAccountsListItems.filter((item) => item.value === value);
      return result.length > 0 ? result[0].label : null;
    }
  };
  const customProps = {
    popupClassName: "subAccountListItem",
  };
  return (
    <>
      {appProperties?.agencyAccount ? (
        <>
          {showSubHeader ? <div class="mb-2  ps-1">Subaccounts</div> : null}

          <DropDownComponent
            customProps={customProps}
            buttonText="Create new"
            buttonNeeded={appProperties.currentUserInstalledTheExtension}
            buttonAction={() => {
              setCreateSubAccountModalOpen(true);
            }}
            headerSpaceNeeded={subAccountsListItems && subAccountsListItems.length > 0}
            headerSpace={
              <>
                <Button
                  type="text"
                  style={{ textAlign: "left", padding: "0 16px" }}
                  onClick={() => {
                    switchToSubAccount("");
                    setSubAccountName("Main Account");
                  }}
                >
                  Main Account
                </Button>
                <Divider
                  style={{
                    margin: "8px 0",
                  }}
                />
                <span className="ms-2 mb-2"> subaccounts </span>
              </>
            }
            selectComponentWidth={selectComponentWidth}
            selectComponentHeight={selectComponentHeight}
            selectTitle={subAccountName}
            handleOnSelect={switchToSubAccount}
            selectMenuItems={subAccountsListItems}
            notFoundContent={
              <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                  height: 60,
                }}
                description={<span>No subaccounts!</span>}
              ></Empty>
            }
          />
        </>
      ) : null}

      <Modal
        title="Create a Subaccount"
        centered
        open={createSubAccountModalOpen}
        width={425}
        closeIcon={<div className="actionIconsSprite modalCloseIcon" />}
        onOk={() => {}}
        onCancel={() => {
          setCreateSubAccountModalOpen(false);
        }}
        footer={null}
      >
        <>
          <div className="mt-4 mb-4">
            <div className="d-flex flex-column gap-2">
              <span>Account Name</span>
              <Input
                autoComplete="off"
                defaultValue={""}
                tabIndex={1}
                className="hs-bg-off-white hs-focus-border h-50 hs-fs-14 hover rounded"
                bordered={null}
                onChange={(e) => setNewSubAccountName(e.currentTarget.value)}
              />
              <div className="d-flex justify-content-end">
                <span className="hs-fs-11 mt-1">
                  <div className="mt-3">
                    Please provide a name for the sub account for this team. This team will have its own SMS account and user access.
                  </div>
                </span>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center justify-content-between">
            <Button
              className="hs-border-10 big-button hs-fs-16 px-3"
              tabIndex={6}
              onClick={() => {
                setCreateSubAccountModalOpen(false);
              }}
            >
              <span className="px-5 hs-color-violet">Cancel</span>
            </Button>
            <Button
              className="hs-bg-violet hs-border-10 big-button hs-fs-16 px-3"
              type="primary"
              name="submitButton"
              tabIndex={6}
              onClick={() => {
                createSubAccountProcess();
              }}
              loading={loading}
            >
              <span className="px-5">Create</span>
            </Button>
          </div>
        </>
      </Modal>
      <Modal
        title={
          <Row>
            <div className="d-flex justify-content-center w-100">
              <span
                className="d-flex justify-content-center actionIconsSprite successTickIcon"
                style={{
                  width: 60,
                  height: 60,
                }}
              ></span>
            </div>
          </Row>
        }
        centered
        open={addUsersToSubAccount}
        width={425}
        closeIcon={<div className="actionIconsSprite modalCloseIcon" />}
        onOk={() => {}}
        onCancel={() => {
          setAddUsersToSubAccount(false);
        }}
        footer={null}
      >
        <>
          <div className="mt-4 mb-4">
            <Row>
              <div className="d-flex justify-content-center w-100">
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: "bolder",
                    color: "#111827",
                  }}
                >
                  Add Users!
                </span>
              </div>
            </Row>
            <Row>
              <div>
                <span className="mt-1 d-flex justify-content-center">
                  <p className="text-center" style={{ fontSize: 14, fontWeight: 400, color: "#374151" }}>
                    Your account has been successfully created. Please add users to continue.
                  </p>
                </span>
              </div>
            </Row>
          </div>

          <div className="d-flex justify-content-center justify-content-between">
            <Button
              className="hs-border-10 big-button hs-fs-16 px-3"
              tabIndex={6}
              onClick={() => {
                setAddUsersToSubAccount(false);
              }}
            >
              <span className="px-5 hs-color-violet">Cancel</span>
            </Button>
            <Button
              className="hs-bg-violet hs-border-10 big-button hs-fs-16 px-4"
              type="primary"
              name="submitButton"
              tabIndex={6}
              onClick={addUserProcess}
              loading={loading}
            >
              <span className="px-4">Add Users</span>
            </Button>
          </div>
        </>
      </Modal>
    </>
  );
}

export default SubAccountsListing;
