import React, { useState } from "react";
import { Badge, Menu } from "antd";
import "../../assets/css/services/sidebar.css";
import { Link, useLocation } from "react-router-dom";

function ServicesSidebar() {
  const location = useLocation();

  const path = window.location.pathname;
  const pathSegments = path.split("/").length;
  const pathSegment = pathSegments > 3 ? path.split("/")[pathSegments - 2] : path.split("/")[pathSegments - 1];

  const [selectedMenu, setSelectedMenu] = useState(pathSegment);

  return (
    <>
      <Menu
        mode="inline"
        key={selectedMenu}
        style={{
          overflow: "auto",
          height: "100%",
          background: "white",
          width: "100%",
          padding: ".5rem",
          borderRadius: 10,
        }}
        className="sidebarMenu"
        defaultSelectedKeys={[selectedMenu]}
      >
        <Menu.Item key="channels" className="serviceSidebar" onClick={() => setSelectedMenu("channels")}>
          <Link className="text-decoration-none" to={"/channels" + location.search}>
            <div className="d-flex pt-3 pb-3 justify-content-between">
              <div>Channels</div>
              <div className="right-arrow"></div>
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item key="textsms" className="serviceSidebar" onClick={() => setSelectedMenu("textsms")}>
          <Link className="text-decoration-none" to={"/channels/textsms" + location.search}>
            <div className="d-flex pt-3 pb-3 justify-content-between">
              <div>Text SMS</div>
              <div className="right-arrow"></div>
            </div>
          </Link>
        </Menu.Item>
        <Badge.Ribbon className="sidebarMenuItemBadge" text="Coming soon">
          <Menu.Item className="serviceSidebar" key="facebook" style={{ pointerEvents: "none" }} onClick={() => setSelectedMenu("facebook")}>
            <Link className="text-decoration-none" to={"/channels/facebook" + location.search} style={{ pointerEvents: "none" }}>
              <div className="d-flex pt-3 pb-3 justify-content-between">
                <div style={{ opacity: "50%" }}>Facebook</div>
                <div className="right-arrow"></div>
              </div>
            </Link>
          </Menu.Item>
        </Badge.Ribbon>
        <Badge.Ribbon className="sidebarMenuItemBadge" text="Coming soon">
          <Menu.Item
            className="serviceSidebar"
            key="twilio_whatsapp"
            style={{ pointerEvents: "none" }}
            onClick={() => setSelectedMenu("twilio_whatsapp")}
          >
            <Link className="text-decoration-none" style={{ pointerEvents: "none" }} to={"/channels/twilio_whatsapp" + location.search}>
              <div className="d-flex pt-3 pb-3 justify-content-between">
                <div style={{ opacity: "50%" }}>Whatsapp</div>
                <div className="right-arrow"></div>
              </div>
            </Link>
          </Menu.Item>
        </Badge.Ribbon>
        <Badge.Ribbon className="sidebarMenuItemBadge" text="Coming soon">
          <Menu.Item style={{ pointerEvents: "none" }} className="serviceSidebar" key="instagram">
            <Link style={{ pointerEvents: "none" }} className="text-decoration-none">
              <div className="d-flex pt-3 pb-3 justify-content-between">
                <div style={{ opacity: "50%" }}>Instagram</div>
                <div className="right-arrow"></div>
              </div>
            </Link>
          </Menu.Item>
        </Badge.Ribbon>
        {/* <Badge.Ribbon className="sidebarMenuItemBadge" text="Coming soon">
          <Menu.Item
            style={{ pointerEvents: "none" }}
            className="serviceSidebar"
            key="callModule"
          >
            <Link
              style={{ pointerEvents: "none" }}
              className="text-decoration-none"
            >
              <div className="d-flex pt-3 pb-3 justify-content-between">
                <div style={{ opacity: "50%" }}>Call Module</div>
                <div className="right-arrow"></div>
              </div>
            </Link>
          </Menu.Item>
        </Badge.Ribbon> */}
        {/* <Badge.Ribbon className="sidebarMenuItemBadge" text="Coming soon">
          <Menu.Item
            style={{ pointerEvents: "none" }}
            className="serviceSidebar"
            key="email"
          >
            <Link
              style={{ pointerEvents: "none" }}
              className="text-decoration-none"
            >
              <div className="d-flex pt-3 pb-3 justify-content-between">
                <div style={{ opacity: "50%" }}>E mail</div>
                <div className="right-arrow"></div>
              </div>
            </Link>
          </Menu.Item>
        </Badge.Ribbon> */}
      </Menu>
    </>
  );
}

export default ServicesSidebar;
