import "../../assets/css/RightPanel.css";

import { useState } from "react";

import useAuth from "../../hooks/useAuth";
import AgentsContainer from "../../components/agents/AgentsContainer";
import CustomersContainer from "../../components/customer/CustomersContainer";
import ToolContainer from "../../components/tools/ToolContainer";
import AccountOrgIdMigration from "../../components/tools/Migration";
import MigrateRingCentralAccounts from "../../components/tools/Migration";
import useAntDHooks from "../../hooks/useAntDHooks";
import RingCentralSubscription from "../../components/tools/EnableInbound";

//Note : Response from zohoCRM Search API will have OsyncId whereas we have key as osyncId
export default function RightPanel() {
  const { currentSideTab } = useAuth();
  const [agentsData, setAgentsData] = useState();
  const { parseJsonIntoAntDTable } = useAntDHooks();
  console.log(currentSideTab);

  return (
    <>
      {currentSideTab === "customers" ? (
        <CustomersContainer parseJsonIntoAntDTable={parseJsonIntoAntDTable} />
      ) : currentSideTab === "agents" ? (
        <AgentsContainer agentsData={agentsData} setAgentsData={setAgentsData} parseJsonIntoAntDTable={parseJsonIntoAntDTable} />
      ) : currentSideTab === "tools" ? (
        <ToolContainer />
      ) : currentSideTab === "migration" ? (
        <AccountOrgIdMigration />
      ) : currentSideTab === "rc_subscription" ? (
        <RingCentralSubscription />
      ) : (
        <></>
      )}
    </>
  );
}
