import '../../assets/css/ToolContainer.css';
import UrlEncodeComponent from "./UrlEncodeComponent";
import ToolHeader from './ToolHeader';
import { Layout } from "antd";


const ToolContainer = () => {
  return (

    <Layout style={{
      minHeight: '97vh',
    }} className='row mt-4'>
      <div className="row mt-2 w-100 justify-content-center">
        <div className="col-11">
          <ToolHeader />
          <UrlEncodeComponent />
        </div>
      </div>
    </Layout>
  )
}
export default ToolContainer;