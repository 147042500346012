import React, { useContext, useEffect, useState } from "react";
import useHttp from "../../../hooks/useHttp";
import { Button, Form, Checkbox, Radio, Col, Row, Typography } from "antd";
import "../../../assets/css/tendlc.css";
import { AppContext } from "../../../context/AppContext";

const { Text } = Typography;

const purchaseAmount = {
  STANDARD: 4,
  LOW_VOLUME_STANDARD: 4,
};

function RegisterBrand({ profileId, setNotEnoughFunds, brandtype, brandStatus, funds }) {
  const [appProperties, setAppProperties] = useContext(AppContext);
  let rightServiceObj = appProperties.installedApps?.find((obj) => obj?.right?.service_name === "hello_send");
  let integId = rightServiceObj?.integProps?.integId;
  const [continueButtonLoading, setContinueButtonLoading] = useState(false);
  const { fetchData } = useHttp();

  const [selectedBrandType, setSelectedBrandType] = useState("");
  const [brandRegistered, setBrandRegistered] = useState(brandStatus || "false");

  const [loading, setLoading] = useState(null);
  const [form1] = Form.useForm();

  useEffect(() => {
    if (brandStatus === "PENDING" || brandStatus === "FAILED") {
      setTimeout(() => setLoading("none"), 5000);
    }
  });

  function createBrand() {
    form1
      .validateFields()
      .then(function (values) {
        setContinueButtonLoading(true);
        console.log(values);
        if (funds >= purchaseAmount[values.brand_type]) {
          fetchData(
            "hellosend/account/" + integId + "/profile/" + profileId + "/brand",
            brandStatus === "FAILED" ? "PUT" : "POST",
            values,
            appProperties
          )
            .then(function (response) {
              console.log(response);
              response = JSON.parse(response);
              // setCurrent(3);
              if (response?.data?.creditsAvailable === true) {
                setContinueButtonLoading(false);
                setBrandRegistered("PENDING");
                setTimeout(() => setLoading("none"), 5000);
              } else if (response?.data?.creditsAvailable === false) {
                setNotEnoughFunds(true);
                setContinueButtonLoading(false);
              }
            })
            .catch((error) => {
              setContinueButtonLoading(false);
            });
        } else {
          setContinueButtonLoading(false);
          setNotEnoughFunds(true);
        }
      })
      .catch((error) => {
        setContinueButtonLoading(false);
        console.log("error error error error error error rerror error error ", error);
      });
  }
  return (
    <>
      <div className="container">
        <div className="m-3 ">
          <h4>Create an A2P Trust Product</h4>
          <div className="description10">
            "Generates an A2P Profile, which consists of a bundle of information to be submitted along with your Customer Profile to the Campaign
            Registry."
          </div>
        </div>

        {brandRegistered === "false" ? (
          <Form form={form1} layout="vertical" className="m-3" initialValues={{ brand_type: brandtype }}>
            {/* <Form.Item
            label="Customer profile"
            name="customerprofile"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select></Select>
          </Form.Item> */}
            <Form.Item
              label="What type of brand do you need"
              name="brand_type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group
                value={selectedBrandType}
                onChange={(e) => {
                  setSelectedBrandType(e.target.value);
                }}
              >
                {/* <Radio value="SOLE_PROPRIETOR" disabled>
                Sole Proprietor {<span>44$</span>}
                <span>
                  {" "}
                  <div className="radioDescription">
                    Recommended for customers sending fewer than 3,000 message
                    segments per day to the US
                  </div>
                </span>
              </Radio> */}
                <Radio value="LOW_VOLUME_STANDARD">
                  Low-volume standard <span> $50</span>
                  <div className="radioDescription">
                    {" "}
                    Recommended for customers sending fewer than 6,000 message segments per day to the US (2,000 message segments per day to
                    T-Mobile)
                  </div>
                </Radio>
                <Radio value="STANDARD">
                  Standard <span>$50</span>
                  <div className="radioDescription">
                    Recommended for customers sending messages at scale. Your daily limit may fall between 6,000 and 600,000 message segments per
                    day to the US (2,000 - 200,000 per day to T-Mobile).
                  </div>
                </Radio>
              </Radio.Group>
            </Form.Item>
            {/* {selectedBrandType === "SOLE_PROPRIETOR" ? (
            <>
              <Form.Item
                label="Brand Name"
                name="brandName"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
                <div>
                  If you are a sole proprietor business, please enter your
                  business name (which is usually your first name/last name). If
                  you're a hobbyist, you can provide your first name/last name
                  or a friendly name for the brand name field.
                </div>
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select placeholder="---Select Vertical---">
                  {verticals.map((obj, index) => (
                    <Select.Option key={index} value={obj.value}>
                      {obj.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <h2>Submit mobile phone number for registration</h2>
              <div>
                The owner of the phone number will receive a message to verify
                ownership of this number.
              </div>
              <Form.Item
                name="phoneNUmber"
                label="Mobile Phone Number"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input addonBefore="+1" />
                <div>
                  Please provide a mobile number that you have access to in
                  order to confirm OTP verification. Only US/Canadian numbers
                  will be accepted. You may not use a CPaaS phone number such as
                  one obtained from Twilio. You cannot use the same mobile
                  number to register more than 3 Sole Proprietor brands. This
                  limit is managed at the TCR level.
                </div>
              </Form.Item>
            </>
          ) : null} */}
            <Form.Item
              value="sss"
              name="Acknowledgement"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <div className="m-3 p-3 rounded ">
                <Checkbox id="brandAcknowledgement">
                  I agree the above information is correct. I agree to a one-time $50 A2P brand registration fee.
                </Checkbox>
              </div>
            </Form.Item>
            <Form.Item className="d-flex justify-content-end">
              <Button
                type="primary"
                loading={continueButtonLoading}
                onClick={createBrand}
                style={{ backgroundColor: "#009EFD" }}
                id="brandRegistration"
              >
                Continue
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <>
            <Row className="container">
              <Col span={1} offset={2} className="d-flex align-items-center justify-content-center">
                {loading !== "none" ? (
                  <span
                    className="spinner-border"
                    style={{
                      width: "2rem",
                      height: "2rem",
                      animation: loading,
                      transform: "rotate(30deg)",
                    }}
                    role="status"
                  ></span>
                ) : null}
              </Col>
              <Col span={16} className="p-3" style={{ fontSize: "1.2rem", color: "#334D6E" }}>
                <span>
                  Your brand registration application has been{" "}
                  {brandRegistered !== "FAILED" ? (
                    <>
                      successfully submitted. The renewal process is anticipated to be completed within a maximum of
                      <mark>7 days</mark>. Kindly monitor the application status here.
                    </>
                  ) : (
                    <> denied .Please rectify them and resubmit the application.</>
                  )}
                </span>
                <div className="d-flex justify-content-end">
                  {brandRegistered === "FAILED" ? (
                    <Button
                      type="text"
                      onClick={() => {
                        setBrandRegistered("false");
                      }}
                    >
                      Retry
                    </Button>
                  ) : null}
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
}
export default RegisterBrand;
