import React, { useState } from "react";
import { Layout } from "antd";
import { useLocation } from "react-router-dom";
import Contentbar from "../components/main/Contentbar";
import Headerbar from "../components/main/Headerbar";
import Sidebar from "../components/main/Sidebar";

const AppLayout = ({ setLoading, breadCrumbItems, setBreadCrumbItems }) => {
  const location = useLocation();
  const [headerTitle, setHeaderTitle] = useState("");
  const [serviceName, setServiceName] = useState("");
  return (
    <Layout style={{ background: "none" }}>
      <Sidebar setHeaderTitle={setHeaderTitle} headerTitle={headerTitle} location={location} />
      <Layout style={{ background: "none" }}>
        <Headerbar headerTitle={headerTitle} setServiceName={setServiceName} />
        <Contentbar
          breadcrumbItems={breadCrumbItems}
          setBreadCrumbItems={setBreadCrumbItems}
          serviceName={serviceName}
          setLoading={setLoading}
          setHeaderTitle={setHeaderTitle}
        />
      </Layout>
    </Layout>
  );
};

export default AppLayout;
