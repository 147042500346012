import axios from "axios";
import { useCallback, useRef, useState } from "react";
import { failureNotification } from "../../src/pages/utils/CommonNotifications";

import { API_VERSION, APP_URL, ASSIST_MAIL, ModalState, osyncId } from "../constants/AppConstants";
// API call for all methods GET, POST, PUT, DELETE

export default function useHttp() {
  const [isLoading, setIsLoading] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [serverError, setServerError] = useState(null);
  const lastFetchedTime = useRef();

  const fetchData = useCallback(async (url, method, body, appProperties, headers, dependencies, signal) => {
    if (appProperties?.blockApiCall) {
      return false;
    }
    if (!ModalState) {
      let headerJson = {};
      var urlParams = new URLSearchParams(window.location.search);
      if (API_VERSION === "v1") {
        if ((appProperties?.hash && appProperties.hash !== "") || appProperties?.first) {
          const hash = appProperties.hash;

          headerJson = {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Osync-Authorization": hash,
            "Content-Type": "application/json; charset=UTF-8",
          };
        } else if (urlParams.get("hash") !== null && urlParams.get("hash") !== undefined && urlParams.get("hash") !== "") {
          const hash = urlParams.get("hash");
          headerJson = {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Osync-Authorization": hash,
            "Content-Type": "application/json; charset=UTF-8",
          };
        }
      } else {
        const companyId = appProperties?.companyId ?? urlParams?.get("companyId") ?? dependencies?.companyId;
        const userId = appProperties?.userId ?? urlParams?.get("userId") ?? dependencies?.companyId;

        if (companyId && userId) {
          headerJson = {
            "hs-orgId": companyId + "_" + userId,
          };
        } else {
          setIsLoading(false);
          return null;
        }
        let token =
          appProperties?.userHash ?? (await appProperties?.controller?.getApplicationToken(appProperties?.userId ?? urlParams?.get("userId")));
        if (token && token !== "") {
          headerJson = { ...headerJson, "hs-token": token };
        }
        let leftserviceId = urlParams?.get("leftServiceId");
        if (leftserviceId && leftserviceId !== "") {
          headerJson = { ...headerJson, "hs-serviceId": leftserviceId };
        }
      }

      let hsOsyncId = "";

      if (urlParams.has("osyncId")) {
        hsOsyncId = urlParams.get("osyncId");
      }
      if (hsOsyncId && hsOsyncId !== "") {
        let subAccountHeaderJson = {
          "hs-osyncId": hsOsyncId,
        };
        headerJson = { ...headerJson, ...subAccountHeaderJson };
      }
      if (headers) {
        headerJson = { ...headerJson, ...headers };
      }
      //Just for ngrok purpose -- don't delete
      // headerJson["ngrok-skip-browser-warning" ] =  "ngrok";
      setIsLoading(true);
      try {
        const resp = await axios({
          method: method,
          headers: headerJson,
          url: APP_URL + "/api/" + API_VERSION + "/" + url,
          withCredentials: true,
          data: body,
          signal: signal,
        });

        const data = JSON.stringify(resp);
        setApiData(data);
        return data;
      } catch (error) {
        console.log(error);
        console.log("ERROR :::::: " + typeof error, error.response);
        console.log("TYPE OF RESPONSE:::::: ", error.response?.status, typeof error.response?.status);

        if (API_VERSION === "v2" && error.response?.status === 401) {
          appProperties?.setOtpModalState(true);
          if (appProperties?.controller?.landingPage) {
            if (window.location.pathname !== "/zoho-crm/landing") {
              appProperties?.controller?.getUrlParamsForRedirection(true, appProperties).then(function (redirectionUrlFromApp) {
                window.location.href = "/zoho-crm/landing?" + redirectionUrlFromApp;
              });
            }
          }
        } else if (API_VERSION === "v2" && error.response?.status === 403 && error?.response?.data?.error === "NO_ACCESS_TO_SUB_ACCOUNT") {
          appProperties.setPermissionDeniedToSubAccount(true);
        } else if (error.response?.status) {
          failureNotification(
            "ERROR",
            <div>
              Some unknown error occurred. Please contact
              <a target="_blank" rel="noreferrer" href={`mailto:${ASSIST_MAIL}`} style={{ paddingLeft: 5, color: "#605bff" }}>
                {ASSIST_MAIL}
              </a>
            </div>
          );
        }
        setServerError(error);
        setIsLoading(false);
        return null;
      } finally {
        setIsLoading(false);
      }
    }
  }, []);
  const fetchAccount = useCallback(
    async (url, method, body, appProperties, header) => {
      let targetUrl = url;
      const response = await fetchData(targetUrl, method, body, appProperties, header);
      const responseJson = JSON.parse(response);
      return responseJson?.data;
    },
    [fetchData]
  );
  const pollData = useCallback(
    async (from, to, serviceId, appProperties) => {
      const d = new Date();
      let timeMS = d.getTime();
      let fetchUrl = `channels/inbox/polling`;
      if (lastFetchedTime.current) {
        d.setTime(d.getTime() + -10 * 1000);
        timeMS = d.getTime();
        lastFetchedTime.current = d.toISOString();
      } else {
        lastFetchedTime.current = d.toISOString();
      }
      fetchUrl += `?lastFetchedTime=${lastFetchedTime.current}&timeInMS=${timeMS}`;
      fetchUrl += from ? `&orgMember=${encodeURIComponent(from)}` : "";
      fetchUrl += to ? `&orgCustomer=${encodeURIComponent(to)}` : "";
      fetchUrl += serviceId ? `&serviceId=${encodeURIComponent(serviceId)}` : "";

      let response = await fetchData(fetchUrl, "GET", null, appProperties);
      response = JSON.parse(response);
      return response?.data;
    },
    [fetchData]
  );

  const fetchDataByPagination = useCallback(
    async (url, method, body, appProperties, header, limit, paginationParams) => {
      let targetUrl = url;
      if (limit) {
        targetUrl += `&limit=${limit}`;
      }
      if (paginationParams?.lastKey && paginationParams?.lastRange) {
        targetUrl += `&lastKey=${paginationParams?.lastKey}&lastRange=${paginationParams?.lastRange}`;
      }
      const response = await fetchData(targetUrl, method, body, appProperties, header);
      const responseJson = JSON.parse(response);
      return responseJson?.data;
    },
    [fetchData]
  );

  return {
    isLoading,
    apiData,
    serverError,
    fetchData,
    fetchDataByPagination,
    pollData,
  };
}
