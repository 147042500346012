import { Badge, Image, Skeleton, Space } from "antd";
import React from "react";
import MediaError from "../../assets/images/mediaError.jpg";

const ReusableFilePreview = React.memo((props) => {
  const { setFileList, fileList, shape, direction, customClasses, userStyles, initialValues } = props;
  let stylesProps = {};
  if (shape === "circle") {
    stylesProps = { minHeight: "53px", alignItems: "end" };
  }
  if (userStyles) {
    stylesProps = { ...stylesProps, ...userStyles };
  }

  const handleDelete = (uid) => {
    setFileList((prevList) => prevList.filter((file) => file.uid !== uid));
  };

  const CompletedState = React.memo(({ file }) => {
    if (shape === "circle") {
      return (
        <Badge
          count={!file?.hideCloseIcon ? <div className="actionIconsSprite previewCloseIcon" onClick={() => handleDelete(file.uid)} /> : null}
        >
          {file.type?.startsWith("image/") && <ImagePreview shape={shape} file={file} />}
          {file.type?.startsWith("video/") && <VideoPreview file={file} shape={shape} />}
          {(file.type?.startsWith("application/") || file.type?.startsWith("text/")) && <DocumentPreview file={file} shape={shape} />}
          {file.type?.startsWith("audio/") && <AudioPreview file={file} shape={shape} />}
        </Badge>
      );
    } else if (shape === "card") {
      return (
        <>
          <div className="hs-mw-85 ">
            {file.type?.startsWith("image/") && <ImagePreview shape={shape} file={file} />}
            {file.type?.startsWith("video/") && <VideoPreview file={file} shape={shape} />}
            {(file.type?.startsWith("application/") || file.type?.startsWith("text/")) && <DocumentPreview file={file} shape={shape} />}
            {file.type?.startsWith("audio/") && <AudioPreview file={file} shape={shape} />}
          </div>
          {initialValues?.disabled ? <></> : <div className="actionIconsSprite binOutlined" onClick={() => handleDelete(file.uid)} />}
        </>
      );
    }
  });

  const UploadingState = () => {
    if (shape === "circle") {
      return <CircleUploadingState />;
    } else if (shape === "card") {
      return <ActiveImageSkeleton />;
    }
  };

  return (
    <Space direction={direction} className={`w-100 overflow-auto pt-2 hs-bg-off-white pb-2 ${customClasses}`} style={stylesProps}>
      {fileList &&
        fileList.map((file) => {
          return (
            <div key={crypto.randomUUID()} className="d-flex gap-2">
              {file.status === "done" ? <CompletedState file={file} /> : <UploadingState />}
            </div>
          );
        })}
    </Space>
  );
});

const CircleUploadingState = () => {
  return (
    <div className="d-flex align-items-center circleWrapper">
      <ActiveImageSkeleton className="circleSkeletonLoading" />
    </div>
  );
};

function ActiveImageSkeleton(props) {
  return <Skeleton.Image className={`w-100 rounded ${props.className}`} active />;
}

const DocumentPreview = React.memo(({ file }) => {
  return (
    <a
      href={file.url}
      target="_blank"
      rel="noopener noreferrer"
      className="me-2"
      style={{
        borderRadius: 12,
        display: "block",
        minWidth: "7rem",
        textAlign: "center",
        textDecoration: "none",
        color: "black",
      }}
    >
      <div className="d-flex align-items-center justify-content-start">
        <span className={`${file.type === "application/pdf" ? "pdf-icon" : "doc-icon"} staticIconsSprite pdf-icon`}></span>
        {file.name}
      </div>
    </a>
  );
});

const AudioPreview = React.memo(({ file }) => {
  return (
    <audio
      src={file.url}
      controls
      className="mt-1"
      style={{
        borderRadius: 12,
      }}
    />
  );
});

const VideoPreview = React.memo(({ file }) => {
  return (
    <video
      src={file.url}
      controls
      width={300}
      className="mt-1"
      style={{
        borderRadius: 12,
        margin: -1,
      }}
    />
  );
});

const ImagePreview = React.memo(({ file, shape }) => {
  if (shape === "circle") {
    return (
      <Image
        wrapperClassName="d-flex align-items-center circleWrapper"
        placeholder={<CircleUploadingState />}
        src={file.url}
        preview={{
          mask: <div />,
          toolbarRender: () => null,
          maskClassName: "previewMask",
        }}
      />
    );
  } else if (shape === "card") {
    return (
      <Image
        preview={false}
        className="rounded preview"
        src={file.url}
        alt="Preview"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = MediaError;
        }}
      />
    );
  }
});

export default ReusableFilePreview;
