import React from "react";
import { Layout } from "antd";

import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";

export default function Dashboard() {
  return (
    <Layout style={{ minHeight: "100dvh" }}>
      <LeftPanel />
      <RightPanel />
    </Layout>
  );
}
