import { osyncId, hash, leftServiceId } from "./../constants/AppConstants";

var domainMap = new Map();
domainMap.set("US", "com");
domainMap.set("EU", "eu");
domainMap.set("CN", "com.cn");
domainMap.set("AU", "com.au");
domainMap.set("IN", "in");

var isZohoDeskInstanceAlreadyLoaded = false;
var appInstance = {};
var oappsDomainMap = new Map();
oappsDomainMap.set("US", "https://api.oapps.xyz/rest");
oappsDomainMap.set("EU", "https://gj8h9yul22.execute-api.eu-west-2.amazonaws.com/prod");
oappsDomainMap.set("AU", "https://96ee558awf.execute-api.ap-southeast-2.amazonaws.com/prod");

export let ZohoSupportDesk = {
  service: "ZohoDesk",
  planDetails: {
    topPlan: "Gold",
    topPlanKey: "PD_GOLD",
    topPlanId: "Gold-USD-Monthly",
    totalUsers: 3,
  },
  sideBarExtend: {
    homeSideBar: true,
    serviceSideBar: true,
  },
  loadScripts: function () {
    return new Promise((resolve, reject) => {
      resolve("https://js.zohostatic.com/support/developer_sdk/v1/js/ZohoDeskClientSDK.min.js");
    });
  },

  checkIsAlreadyInstalled: function (fetchData) {
    return new Promise((resolve, reject) => {
      let extensionData = {};
      console.log("INSIDE checkIsAlreadyInstalled >>>>>>>>>>>>>>>>>>>>>", window.ZOHODESK);
      window.ZOHODESK.get("extension.config")
        .then((response) => {
          console.log("extension config response >>>>>>>>>>", response);
          let extensionConfigResponse = response["extension.config"];
          Object.keys(extensionConfigResponse).forEach((index) => {
            let { name, value } = extensionConfigResponse[index];
            console.log("name >>>>>>>>>>>>>>>>>>>>>", name);
            console.log("value >>>>>>>>>>>>>>>>>>>>>", value);
            if (name === "osyncId") {
              extensionData["osyncId"] = value;
            } else if (name === "hash") {
              extensionData["hash"] = value;
            } else if (name === "integId") {
              extensionData["integId"] = value;
            }
          });
          console.log("extensionData >>>>>>>>>>>>>>>>>>>>>", extensionData);
          resolve(extensionData);
        })
        .catch((err) => {
          console.log("extension config error >>>>>>>>>>", err);
        });
    });
  },
  getUserData: function () {
    return new Promise((resolve, reject) => {
      window.ZOHODESK.get("portal")
        .then(function (response) {
          console.log("response >>>>>>", response);
          var portalObj = response["portal"];
          window.ZOHODESK.get("user.id")
            .then(function (userResponse) {
              var userId = userResponse["user.id"];
              var userData = {
                companyId: portalObj.id,
                companyOrgId: portalObj.name,
                userId: userId,
                product: "Omni",
                domainName: portalObj.customDomainName,
              };
              resolve(userData);
            })
            .catch(function (err) {
              console.log("error while getting userid >>>>>>>>>>", err);
            });
        })
        .catch(function (err) {
          console.log("error while getting portal obj >>>>>>>>>>", err);
        });
    });
  },
  getWebhookContent: function (thisObj) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  getAssociatedObjectId: function (thisObj) {
    return new Promise((resolve, reject) => {
      if (appInstance.location.indexOf("ticket") !== -1) {
        window.ZOHODESK.get("ticket.id")
          .then(function (response) {
            var ticketId = response["ticket.id"];
            resolve(ticketId);
          })
          .catch(function (err) {
            //Error Handling
          });
      } else if (appInstance.location.indexOf("contact") !== -1) {
        window.ZOHODESK.get("contact.id")
          .then(function (response) {
            var contactId = response["contact.id"];
            resolve(contactId);
          })
          .catch(function (err) {
            //Error Handling
          });
      } else {
        resolve("");
      }
    });
  },
  getAssociatedObjectType: function (thisObj) {
    return new Promise((resolve, reject) => {
      if (appInstance.location.indexOf("ticket") !== -1) {
        resolve("tickets");
      } else if (appInstance.location.indexOf("contact") !== -1) {
        resolve("contacts");
      } else {
        resolve("");
      }
    });
  },
  getAssociatedIntegId: function (thisObj, rightServiceName) {
    return new Promise((resolve, reject) => {
      const urlParams = new URLSearchParams(window.location.search);
      resolve(urlParams.get("integId"));
    });
  },
  getAssociatedHash: function (thisObj, rightServiceName) {
    return new Promise((resolve, reject) => {
      window.ZOHODESK.get("extension.config")
        .then((response) => {
          const extnConfig = response["extension.config"];
          extnConfig.forEach((configJson) => {
            const extensionName = configJson.name;
            if (extensionName === "hash") {
              resolve(configJson.value);
            }
          });
        })
        .catch((err) => {
          // error handling
        });
    });
  },
  fetchContact: function (thisObj) {
    return new Promise((resolve, reject) => {
      window.ZOHODESK.get("ticket").then(function (res) {
        var ticketObj = res["ticket"];
        var toNumber = ticketObj.phone;
        if (toNumber !== null && toNumber !== "") {
          resolve(toNumber);
        } else {
          var contactId = ticketObj.contactId;
          let sampleRequestObj = {
            url: "https://desk.zoho.com/api/v1/contacts/" + contactId,
            type: "GET",
            data: {
              include: "owner",
            },
            headers: {
              "Content-Type": "application/json",
            },
            connectionLinkName: "oappszohodeskomnichannel",
            responseType: "json",
          };

          window.ZOHODESK.request(sampleRequestObj).then(
            (res) => {
              console.log("sampleRequestObj >>>>>>>>>>>>>", res);
              var dataObj = res.data;
              var contactObj = dataObj.statusMessage;
              var mobile = contactObj.mobile;
              var phone = contactObj.phone;
              if (phone !== null && phone !== "") {
                resolve(phone);
              } else if (mobile !== null && mobile !== "") {
                resolve(mobile);
              }
            },
            (error) => {}
          );
        }
      });
    });
  },
  getSubscriptionId: function (fetchSubscriptionData, appProperties) {
    return new Promise((resolve, reject) => {
      fetchSubscriptionData(appProperties).then((response) => {
        resolve(response);
      });
    });
  },
  init: function () {
    return new Promise((resolve, reject) => {
      if (!isZohoDeskInstanceAlreadyLoaded) {
        console.log(" Promise debug-with-saranya >>>>>>>>>>>>>>>>>>>window.ZOHODESK INIT 00000 >>>>>>>>>>>>>>>>>>", window.ZOHODESK);
        if (window.ZOHODESK !== undefined) {
          window.ZOHODESK.extension
            .onload()
            .then((App) => {
              isZohoDeskInstanceAlreadyLoaded = true;
              appInstance = App;
              let currentDomain = App.meta.dcType;
              console.log("currentDomain >>>>>>>>>>>>>>>>>>>>>>>>>", currentDomain);
              resolve();
            })
            .catch((err) => {
              console.log("Promise >>>>>>>>>>>>>>>>>>>window.ZOHODESK INIT error >>>>>>>>>>>>>>>>>>>>>>>>>", err);
            });
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
  },
  pricingLink: function () {
    return new Promise((resolve, reject) => {
      var redirectionLink = "/pricing?productId=102&groupName=OMNI_CHANNEL";
      redirectionLink += "&leftServiceId=" + leftServiceId;
      redirectionLink += "&serviceName=zohodesk";
      redirectionLink += "&osyncId=" + osyncId;
      redirectionLink += "&hash=" + hash;
      redirectionLink += "&isOmniAccount=true";
      resolve(redirectionLink);
    });
  },
  insertContent: function (resultAreaInput) {
    return new Promise((resolve, reject) => {
      window.ZOHODESK.invoke("INSERT", "ticket.replyEditor", {
        value: resultAreaInput,
      });
      resolve();
    });
  },
};
