import { useState } from "react";
import useHttp from "./useHttp";

function useNumbers(appProperties) {
  const [loading, setLoading] = useState(false);
  const { fetchData } = useHttp();
  const getAllSavedNumbers = async () => {
    try {
      setLoading(true);
      const response = await fetchData(`helloSend/savedNumbers`, "GET", null, appProperties);
      if (response) {
        const responseData = JSON.parse(response);
        return responseData?.data;
      } else {
        throw new Error("Can't able to fetch saved numbers.");
      }
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    getAllSavedNumbers,
  };
}

export default useNumbers;
