import React, { useContext, useEffect, useState } from "react";

import { Menu, Button, Empty, Table, Switch, Input, Row, Col, Select, Modal, Space, Tooltip, Typography } from "antd";
import { CloseCircleOutlined, InfoCircleFilled } from "@ant-design/icons";

import "./../../assets/css/phonePage.css";

import { useNavigate, useLocation } from "react-router-dom";

import useHttp from "../../hooks/useHttp";
import { CommonLoading, EditMessageServiceId, ErrorModal, NoticeModal } from "../utils/CommonVessels";

import { ModalState, urlParams } from "../../constants/AppConstants";
import { failureNotification } from "../utils/CommonNotifications";
import { EditFriendlyName } from "./ActiveNumbers";
import { AppContext } from "../../context/AppContext";
import { StarOutlined, StarFilled } from "@ant-design/icons";
const { Link } = Typography;

function AddPhone() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();

  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(true);
  const [phoneNumberList, setPhoneNumberList] = useState([]);
  const [msgSidList, setMsgSidList] = useState([]);
  const [appProperties] = useContext(AppContext);
  const [showLoading, setShowLoading] = useState(true);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [phoneTableData, setPhoneTableData] = useState([]);
  const [msgTableData, setMsgTableData] = useState([]);
  const [alphaTableData, setAlphaTableData] = useState([]);

  const [enableInboundSmschecked, setEnableInboundSmsChecked] = useState(false);
  const [enableInboundSmsloading, setEnableInboundSmsLoading] = useState(false);
  const [enableInboundSmsdiabled, setEnableInboundSmsDisabled] = useState(false);
  const [callIntegration, setCallIntegration] = useState(false);
  const [callSavedNumber, setCallSavedNumber] = useState(false);

  const items = appProperties.providerCommonObj.menu;
  const integId = appProperties.integId;
  const rightServiceName = appProperties.rightServiceName;
  const enableInboundSms = appProperties.providerCommonObj.isEnableInboundSms;

  const { fetchData } = useHttp();
  const sideBar = urlParams.get("sideBar");
  useEffect(() => {
    console.log("numbers api call", appProperties);
    fetchData(`omessage/${integId}/numbers`, "GET", null, appProperties).then((response) => {
      if (response) {
        const responseData = JSON.parse(response);
        console.log(responseData, "response");

        if (responseData?.data?.data) {
          let getNumberList = responseData.data.data;
          if (typeof getNumberList === "string") {
            getNumberList = JSON.parse(getNumberList);
          }
          setPhoneNumberList(getNumberList);
        }
        if (responseData?.data?.messagingServices) {
          let getMsgServicesLists = responseData.data.messagingServices;
          const msgSidsList = getMsgServicesLists.map((service) => ({
            friendlyName: service.friendlyName,
            sid: service.sid,
          }));
          setMsgSidList(msgSidsList);
        }
        setCallSavedNumber(true);
      }
    });
  }, []);

  useEffect(() => {
    console.log(callSavedNumber, "callSavedNumber");
    if (callSavedNumber === true) {
      fetchData(`omessage/${integId}/savedNumbers`, "GET", null, appProperties).then((response) => {
        const responseData = JSON.parse(response);

        if (responseData?.data?.data) {
          responseData.data.data.map((data) => {
            if (data?.phoneUniqueId) {
              setPhoneTableData((prevState) => [...prevState, data]);
            } else {
              setAlphaTableData((prevState) => [...prevState, data]);
            }
            return null;
          });
        }
        if (responseData?.data?.messagingServices?.length > 0) {
          responseData?.data?.messagingServices.map((obj) => {
            setMsgTableData((prevState) => [...prevState, obj]);
          });
        }
        setMsgTableData(responseData?.data?.messagingServices ?? []);

        setCallIntegration(true);
        setCallSavedNumber(false);
      });
    }
    if (enableInboundSms === true && callIntegration === true) {
      console.log(callIntegration, "callIntegration");
      if (state !== undefined && state?.subscriptionId) {
        console.log("state?.subscriptionId", state.subscriptionId);
        setEnableInboundSmsChecked(true);
        setEnableInboundSmsDisabled(true);
      } else {
        fetchData(`integration/${integId}`, "GET", null, appProperties).then((response) => {
          const responseData = JSON.parse(response);
          console.log(responseData, "integ response");

          if (responseData?.data?.ringCentralSubscriptionId) {
            setEnableInboundSmsChecked(true);
            setEnableInboundSmsDisabled(true);
          } else {
            setEnableInboundSmsChecked(false);
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [callSavedNumber, callIntegration]);
  console.log(phoneTableData, "< < < < <");

  const checkEnableInbound = () => {
    setEnableInboundSmsLoading(true);
    var subscriptionId = "";
    const Body = {
      inboundSMS: "true",
      subscriptionId: subscriptionId,
    };
    fetchData(`omessage/completeinstallation?integId=${integId}`, "POST", Body, appProperties).then((response) => {
      const responseData = JSON.parse(response);
      console.log(responseData, "data");
      setEnableInboundSmsLoading(false);
      const subscriptionIdResponse = JSON.parse(responseData.data.data);

      if (subscriptionIdResponse?.SMSProvider?.subscriptionId) {
        setEnableInboundSmsChecked(true);
        setEnableInboundSmsDisabled(true);
      } else {
        setEnableInboundSmsChecked(false);
      }
    });
  };

  const [selectedMenuItem, setSelectedMenuItem] = useState("AddPhone");
  const handleMenuClick = (event) => {
    setSelectedMenuItem(event.key);
  };
  const handleClickBtnNext = () => {
    navigate("/account/" + rightServiceName + "/guide" + location.search);
  };
  function handleButtonDisabled() {
    setIsNextBtnDisabled(false);
  }

  var spanValueAddPhone = enableInboundSms ? 12 : 24;
  var spanValueEnableSwitch = enableInboundSms ? 12 : 0;
  return (
    <>
      {sideBar === "false" ? (
        <div id="buttonStyle">
          <Button
            style={{ backgroundColor: isNextBtnDisabled ? "" : "#009EFD" }}
            disabled={isNextBtnDisabled}
            onClick={handleClickBtnNext}
            id="btn-next"
            type="primary"
          >
            Next
          </Button>
        </div>
      ) : (
        ""
      )}
      <div className="addPhoneMenu" style={{ marginTop: 10 }}>
        <Row>
          <Col xl={spanValueAddPhone}>
            <Menu className="addPhoneMenu" onClick={handleMenuClick} selectedKeys={[selectedMenuItem]} mode="horizontal" items={items} />
          </Col>
          <Col xl={spanValueEnableSwitch} className="border-bottom border-light border-2" style={{ marginTop: 8, textAlignLast: "end" }}>
            {enableInboundSms === true && (
              <span id="enableInboundSwitch">
                {!enableInboundSmschecked && (
                  <span id="errorenableInbound">
                    <CloseCircleOutlined className="inboundErrorIcon" style={{ fontSize: 20, marginRight: 5 }} />
                    Enable the inbound option to receive incoming message.
                  </span>
                )}
                <span
                  style={{
                    color: "#009EFD",
                    fontWeight: 600,
                    fontFamily: "Inter , sans-serif",
                    marginLeft: 5,
                    marginRight: 5,
                  }}
                >
                  Inbound SMS
                </span>
                <Switch
                  checked={enableInboundSmschecked}
                  defaultChecked={enableInboundSmschecked}
                  loading={enableInboundSmsloading}
                  disabled={enableInboundSmsdiabled}
                  onClick={checkEnableInbound}
                />
              </span>
            )}
          </Col>
        </Row>

        {selectedMenuItem === "AddPhone" && (
          <PhoneNumber
            phoneTableData={phoneTableData}
            setPhoneTableData={setPhoneTableData}
            toShowLoading={setShowLoading}
            numbers={phoneNumberList}
            enableNextBtn={handleButtonDisabled}
            setTableHeaders={setTableHeaders}
            appProperties={appProperties}
            selectedMenuItem={selectedMenuItem}
            msgSidList={msgSidList}
          />
        )}

        {selectedMenuItem === "MessagingService" && (
          <MessagingService
            msgTableData={msgTableData}
            setMsgTableData={setMsgTableData}
            toShowLoading={setShowLoading}
            messageServiceSids={msgSidList}
            enableNextBtn={handleButtonDisabled}
            setTableHeaders={setTableHeaders}
            appProperties={appProperties}
            selectedMenuItem={selectedMenuItem}
          />
        )}
        {selectedMenuItem === "AlphaSender" && (
          <AlphaSender
            alphaTableData={alphaTableData}
            setAlphaTableData={setAlphaTableData}
            toShowLoading={setShowLoading}
            enableNextBtn={handleButtonDisabled}
            setTableHeaders={setTableHeaders}
            appProperties={appProperties}
            selectedMenuItem={selectedMenuItem}
          />
        )}
      </div>
      <div>
        <SavedPhoneTable
          tableData={selectedMenuItem === "AddPhone" ? phoneTableData : selectedMenuItem === "MessagingService" ? msgTableData : alphaTableData}
          setTableData={
            selectedMenuItem === "AddPhone" ? setPhoneTableData : selectedMenuItem === "MessagingService" ? setMsgTableData : setAlphaTableData
          }
          appProperties={appProperties}
          fetchData={fetchData}
          selectedMenuItem={selectedMenuItem}
          tableHeaders={tableHeaders}
          msgSidList={msgSidList}
          showLoading={showLoading}
        />
      </div>
    </>
  );
}

const PhoneNumber = React.memo((props) => {
  const [phoneNumbersList, setPhoneNumbersList] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [savedNumbersList, setSavedNumbersList] = useState("");
  const {
    toShowLoading,
    numbers,
    setTableHeaders,
    enableNextBtn,
    phoneTableData,
    appProperties,
    msgSidList,
    setPhoneTableData,
    selectedMenuItem,
  } = props;
  const [showErrorModal, setShowErrorModal] = useState(false);
  const errorMessage = "Error Message";
  const errorDescription = (
    <span>
      No SMS-enabled phone number found in your {props.appProperties.rightServiceName} account. Please go to your account and purchase a number.
    </span>
  );

  const columns = [
    {
      title: "",
      dataIndex: "defaultNumberSelectIcon",
      key: "defaultNumberSelectIcon",
      ellipsis: true,
    },
    {
      title: "Friendly Name",
      dataIndex: "friendlyName",
      key: "friendlyName",
      ellipsis: true,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      ellipsis: true,
    },
    {
      title: "Message Profile Id",
      dataIndex: "messageServiceId",
      key: "messageServiceId",
      ellipsis: true,
    },
    {
      title: "Phone Unique Id",
      dataIndex: "phoneUniqueId",
      key: "phoneUniqueId",
      ellipsis: true,
    },
  ];
  useEffect(() => {
    let registeredNumbers = numbers.phone;

    if (registeredNumbers?.length > 0) {
      setPhoneNumber(registeredNumbers[0]);
      setPhoneNumbersList(registeredNumbers);
      setShowLoading(false);
      toShowLoading(false);
    } else if (registeredNumbers !== undefined) {
      setShowLoading(false);
      toShowLoading(false);
      if (ModalState === false) {
        setShowErrorModal(false);
      }
    }
  }, [numbers]);

  useEffect(() => {
    setTableHeaders(columns);
    const savedTableData = phoneTableData;
    if (savedTableData?.length > 0) {
      enableNextBtn();
      const phoneNumberList = savedTableData.map((service) => service.phoneNumber);
      setSavedNumbersList(phoneNumberList);
    }
  }, [phoneTableData]);

  return (
    <>
      {showLoading ? (
        <CommonLoading />
      ) : phoneNumbersList !== undefined ? (
        <>
          <ErrorModal showError={showErrorModal} errorMessage={errorMessage} errorDescription={errorDescription} />
          <GetUserInputData
            savedNumbersList={savedNumbersList}
            setPhoneNumber={setPhoneNumber}
            phoneNumber={phoneNumber}
            phoneNumbersList={phoneNumbersList}
            selectedMenuItem={selectedMenuItem}
            msgSidList={msgSidList}
            PlaceholderValue={"Enter Number"}
            isMessagingService="false"
            alphaSender="false"
            phoneTableData={phoneTableData}
            setPhoneTableData={setPhoneTableData}
            appProperties={appProperties}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
});
const MessagingService = React.memo((props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [messageServiceSidList, setMessageServiceSidList] = useState([]);

  const [savedNumbersList, setSavedNumbersList] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [isMsgAddButtonDisabled, setIsMsgAddButtonDisabled] = useState(true);
  const { toShowLoading, setTableHeaders, enableNextBtn, msgTableData, appProperties, setMsgTableData, selectedMenuItem, messageServiceSids } =
    props;

  const columns = [
    {
      title: "",
      dataIndex: "defaultNumberSelectIcon",
      key: "defaultNumberSelectIcon",
      ellipsis: true,
    },
    {
      title: "Friendly Name",
      dataIndex: "friendlyName",
      key: "friendlyName",
      ellipsis: true,
    },
    {
      title: "MessageServices ID",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      ellipsis: true,
    },
    {
      title: "Message Profile Id",
      dataIndex: "messageServiceId",
      key: "messageServiceId",
      ellipsis: true,
    },
  ];

  useEffect(() => {
    if (messageServiceSids?.length > 0) {
      setMessageServiceSidList(messageServiceSids);
      setPhoneNumber(messageServiceSids[0]?.sid);
      setTableHeaders(columns);
      setMsgTableData(msgTableData);
      setShowLoading(false);
      toShowLoading(false);
    } else if (messageServiceSids !== undefined) {
      setShowLoading(false);
      toShowLoading(false);
    }
  }, [messageServiceSids]);

  useEffect(() => {
    const savedTableData = msgTableData;
    if (savedTableData?.length > 0) {
      enableNextBtn();
      console.log(savedTableData, "savedTableData");
      const phoneNumberList = savedTableData.map((service) => service.phoneNumber);
      setSavedNumbersList(phoneNumberList);
    }
  }, [msgTableData]);
  return (
    <>
      {showLoading ? (
        <CommonLoading />
      ) : messageServiceSidList !== undefined ? (
        <GetUserInputData
          selectedMenuItem={selectedMenuItem}
          isMessagingService="true"
          alphaSender="false"
          phoneNumber={phoneNumber}
          phoneNumbersList={messageServiceSidList}
          PlaceholderValue={"Messaging Service ID"}
          setMsgTableData={setMsgTableData}
          msgTableData={msgTableData}
          savedNumbersList={savedNumbersList}
          setPhoneNumber={setPhoneNumber}
          appProperties={appProperties}
          isMsgAddButtonDisabled={isMsgAddButtonDisabled}
        />
      ) : (
        ""
      )}
    </>
  );
});

const AlphaSender = React.memo((props) => {
  const [savedNumbersList, setSavedNumbersList] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const { setTableHeaders, enableNextBtn, alphaTableData, appProperties, setAlphaTableData, selectedMenuItem } = props;

  const columns = [
    {
      title: "",
      dataIndex: "defaultNumberSelectIcon",
      key: "defaultNumberSelectIcon",
      ellipsis: true,
    },
    {
      title: "Friendly Name",
      dataIndex: "friendlyName",
      key: "friendlyName",
      ellipsis: true,
    },
    {
      title: "Alpha Sender ID",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      ellipsis: true,
    },
    {
      title: "Message Profile Id",
      dataIndex: "messageServiceId",
      key: "messageServiceId",
      ellipsis: true,
    },
  ];

  useEffect(() => {
    const savedTableData = alphaTableData;
    setTableHeaders(columns);

    if (savedTableData?.length > 0) {
      enableNextBtn();
      setSavedNumbersList(savedTableData.map((service) => service?.phoneNumber));
    }
  }, [alphaTableData]);

  return (
    <>
      <GetUserInputData
        selectedMenuItem={selectedMenuItem}
        PlaceholderValue="Enter Alpha sender id "
        isMessagingService="false"
        alphaSender="true"
        savedNumbersList={savedNumbersList}
        setAlphaTableData={setAlphaTableData}
        alphaTableData={alphaTableData}
        setPhoneNumber={setPhoneNumber}
        phoneNumber={phoneNumber}
        appProperties={appProperties}
      />
    </>
  );
});

const SavedPhoneTable = React.memo((props) => {
  const [showTable, setShowTable] = useState(false);
  const [getData, setGetData] = useState([]);
  const { setTableData } = props;

  const { showLoading, tableData, tableHeaders, appProperties } = props;

  useEffect(() => {
    let tableList = tableData;
    if (tableList?.length > 0) {
      setShowTable(true);
    } else {
      setShowTable(false);
    }
    setGetData(tableList);
  }, [tableData]);

  function mapDataToTable(data) {
    let updatedData = data.map((obj) => {
      return {
        ...obj,
        defaultNumberSelectIcon: (
          <DefaultNumberChange
            style={{ width: "25px" }}
            appProperties={appProperties}
            defaultNumber={obj?.defaultNumber}
            phoneNumber={obj?.phoneNumber}
          />
        ),
        ...(props.selectedMenuItem === "AddPhone" &&
          appProperties.providerCommonObj.isMessagingServiceSenderSupported === true && {
            messageServiceId: (
              <EditMessageServiceId
                msgSidList={props?.msgSidList}
                friendlyName={obj?.friendlyName}
                phoneNumber={obj?.phoneNumber}
                messageServiceId={obj?.messageServiceId}
              />
            ),
          },
        {
          friendlyName: <EditFriendlyName phoneNumber={obj?.phoneNumber} friendlyName={obj?.friendlyName} />,
        }),
      };
    });
    return updatedData;
  }

  const DefaultNumberChange = (props) => {
    const { fetchData } = useHttp();

    const { appProperties, phoneNumber, defaultNumber } = props;

    const handleDefaultNumberOn = async () => {
      try {
        let response = await fetchData(
          `${appProperties.integId}/number/default?phoneNumber=${encodeURIComponent(phoneNumber)}`,
          "PUT",
          null,
          appProperties
        );
        if (response) {
          response = JSON.parse(response);
          if (response?.data) {
            const updatedTableData = getData.map((item) => {
              const { defaultNumber, ...updatedItem } = item;
              return { ...updatedItem, defaultNumber: 0 };
            });
            const indexToUpdate = updatedTableData.findIndex((item) => item.phoneNumber === phoneNumber);

            if (indexToUpdate !== -1) {
              updatedTableData[indexToUpdate] = response?.data?.phone;
            }
            setTableData(updatedTableData);
            setGetData(updatedTableData);
          }
        }
      } catch (error) {
        console.error("Error", error);
      }
    };

    const handleDefaultNumberOff = async () => {
      try {
        let response = await fetchData(
          `${appProperties.integId}/number/default?phoneNumber=${encodeURIComponent(phoneNumber)}&action=remove`,
          "PUT",
          null,
          appProperties
        );
        if (response) {
          response = JSON.parse(response);
          if (response?.data) {
            const updatedTableData = getData.map((item) => {
              if (item.defaultNumber) {
                const { defaultNumber, ...updatedItem } = item;
                return updatedItem;
              } else {
                return item;
              }
            });
            let updatedData = updatedTableData.filter((obj) => obj.phoneNumber !== phoneNumber);
            setTableData(updatedTableData);
            setGetData(updatedTableData);
          }
        }
      } catch (error) {
        console.error("Error", error);
      }
    };

    return (
      <div>
        {defaultNumber === 1 ? (
          <Tooltip placement="right" title="Set as default">
            <StarFilled style={{ color: "#019efd" }} onClick={handleDefaultNumberOff} />
          </Tooltip>
        ) : (
          <Tooltip placement="right" title="Set as default">
            <StarOutlined style={{ color: "black" }} onClick={handleDefaultNumberOn} />
          </Tooltip>
        )}
      </div>
    );
  };

  return (
    <>
      <Col className="tableIndex">
        {showLoading ? (
          ""
        ) : showTable ? (
          <Table pagination={false} size="middle" className="savePhoneTable" columns={tableHeaders} dataSource={mapDataToTable(getData)} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Phone numbers configured" style={{ marginTop: 80 }} />
        )}
      </Col>
    </>
  );
});

const GetUserInputData = React.memo((props) => {
  const [friendlyName, setFriendlyName] = useState("");
  const [isLoadingAddBtn, setIsLoadingAddBtn] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [invalidMessagingService, setInvalidMessagingService] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [pendingSelection, setPendingSelection] = useState(null);
  const [showNotice, setShowNotice] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const [numberAlreadyAdded, setNumberAlreadyAdded] = useState(false);
  const { fetchData } = useHttp();
  const [onChangeMsgAddButtonDisabled, setOnChangeMsgAddButtonDisabled] = useState(true);
  const {
    appProperties,
    phoneNumbersList,
    PlaceholderValue,
    savedNumbersList,
    isMessagingService,
    alphaSender,
    selectedMenuItem,
    setPhoneNumber,
    phoneTableData,
    setPhoneTableData,
    msgTableData,
    setMsgTableData,
    msgSidList,
    alphaTableData,
    setAlphaTableData,
    isMsgAddButtonDisabled,
  } = props;

  const showMessagingService = appProperties.providerCommonObj.isMessagingServiceSenderSupported;
  let phoneNumber = props.phoneNumber;
  const integId = appProperties.integId;
  let phoneNumList;
  if (phoneNumbersList !== undefined) {
    phoneNumList = phoneNumbersList;
  }

  const handleClickAddBtn = async () => {
    try {
      setNumberAlreadyAdded(false);
      let appData = {};
      if (selectedMenuItem === "AddPhone") {
        appData = {
          phoneNumber: phoneNumber,
          friendlyName: friendlyName,
          isMessagingService: isMessagingService,
          alphaSender: alphaSender,
          messageServiceId: selectedValue,
        };
      } else {
        appData = {
          phoneNumber: phoneNumber,
          friendlyName: friendlyName,
          isMessagingService: isMessagingService,
          alphaSender: alphaSender,
        };
      }
      if (phoneNumber === "") {
        setInvalidPhone(true);
      } else if (showMessagingService === true && selectedValue === null && selectedMenuItem === "AddPhone") {
        setInvalidMessagingService(true);
      } else if (friendlyName === "") {
        setInvalidName(true);
      } else if (savedNumbersList.includes(phoneNumber)) {
        setNumberAlreadyAdded(true);
      } else if (phoneNumber !== "" && friendlyName !== "") {
        setIsLoadingAddBtn(true);
        fetchData(`omessage/${integId}/savePhone`, "POST", appData, appProperties).then((response) => {
          if (response) {
            let savePhoneResp = JSON.parse(response);
            console.log(savePhoneResp, "savePhoneResp");

            let resp = savePhoneResp?.data?.data;
            if (savePhoneResp?.data?.license !== undefined) {
              failureNotification(savePhoneResp?.data?.license?.code, savePhoneResp?.data?.license?.description);
              setIsLoadingAddBtn(false);
            }
            console.log(resp, "resp");
            if (selectedMenuItem === "AddPhone") {
              if (savePhoneResp.data.errorMessage !== undefined) {
                failureNotification("Error", savePhoneResp.data.errorMessage);
                setIsLoadingAddBtn(false);
              } else if (resp !== undefined) {
                setPhoneTableData(!savedNumbersList.includes(phoneNumber) && phoneTableData?.length > 0 ? [...phoneTableData, resp] : [resp]);
                setIsLoadingAddBtn(false);
              }
            } else if (selectedMenuItem === "MessagingService") {
              setMsgTableData(!savedNumbersList.includes(phoneNumber) && msgTableData?.length > 0 ? [...msgTableData, resp] : [resp]);
              setIsLoadingAddBtn(false);
            } else if (selectedMenuItem === "AlphaSender") {
              setAlphaTableData(!savedNumbersList.includes(phoneNumber) && alphaTableData?.length > 0 ? [...alphaTableData, resp] : [resp]);
              setIsLoadingAddBtn(false);
            } else {
              setIsLoadingAddBtn(false);
            }
          }
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleSelectChange = (value) => {
    setPendingSelection(value);
    if (value !== "") {
      setShowNotice(true);
    } else if (value === "") {
      setSelectedValue(value);
      setTimeout(() => {
        setOpenSelect(false);
      }, 10);
    }
  };
  function handleCancel() {
    setShowNotice(false);
    setOpenSelect(false);
  }

  const handleModalConfirm = () => {
    setSelectedValue(pendingSelection);
    setPendingSelection("");
    setShowNotice(false);
    setOpenSelect(false);
  };

  const handleModalCancel = () => {
    setPendingSelection("");
    setShowNotice(false);
    setOpenSelect(false);
  };

  function handleSelectBlur() {
    if (showNotice === false) {
      setOpenSelect(false);
    }
  }

  const setMessagingNumber = (value) => {
    if (selectedMenuItem === "MessagingService") {
      fetchData(`${integId}/verifyNumber?number=` + value, "GET", null, appProperties).then((response) => {
        if (response) {
          setPhoneNumber(value);
          let savePhoneResp = JSON.parse(response);
          if (savePhoneResp?.data?.status == false) {
            const errorMessage = "No numbers connected !";
            const errorDescription = (
              <span>
                To use this messaging service, please add one or more numbers to it, in the Twilio console and return here.{" "}
                <Link
                  href="https://help.twilio.com/articles/4402705042075-Managing-a-Messaging-Service-Sender-Pool#h_01H266YMP2NG092378YJ3PR315"
                  target="_blank"
                >
                  Read more
                </Link>
              </span>
            );
            failureNotification(errorMessage, errorDescription);
          }
          var associatedMsgService = savePhoneResp?.data?.status;
          if (associatedMsgService) {
            setOnChangeMsgAddButtonDisabled(false);
            setNumberAlreadyAdded(false);
          } else {
            setOnChangeMsgAddButtonDisabled(true);
            setNumberAlreadyAdded(false);
          }
        }
      });
    } else {
      setPhoneNumber(value);
    }
  };

  return (
    <div>
      <div>
        <Row style={{ paddingTop: 24, paddingBottom: 24 }}>
          <Col className="m-3">
            {selectedMenuItem === "AlphaSender" ? (
              <Input
                onFocus={() => {
                  setInvalidPhone(false);
                }}
                onChange={(e) => {
                  setPhoneNumber(e.target.value.trim());
                }}
                id="phoneNumberInput"
                placeholder="Alpha Sender ID"
                style={{ width: 200, height: 40 }}
              />
            ) : (
              <Select
                defaultValue={selectedMenuItem === "MessagingService" && phoneNumList?.length > 0 ? `Select Service` : phoneNumList[0]}
                onFocus={() => {
                  setInvalidPhone(false);
                }}
                className="addPhone-select"
                size="large"
                onChange={(value) => {
                  setMessagingNumber(value);
                }}
                id="phoneNumberInput"
                placeholder={PlaceholderValue}
                style={{ width: 200, height: 40 }}
              >
                {phoneNumList?.map((number, index) => {
                  return (
                    <>
                      {selectedMenuItem === "MessagingService" ? (
                        <Select.Option key={index} value={number.sid}>
                          {number.friendlyName + "(" + number.sid + ")"}
                        </Select.Option>
                      ) : (
                        <Select.Option key={index} value={number}>
                          {number}
                        </Select.Option>
                      )}
                    </>
                  );
                })}
              </Select>
            )}
            <p
              id="numberError"
              style={{
                visibility: invalidPhone ? "visible" : "hidden",
              }}
            >
              {selectedMenuItem === "AlphaSender"
                ? "Enter alpha sender id"
                : selectedMenuItem === "MessagingService"
                ? "select MessagingService"
                : selectedMenuItem === "AddPhone"
                ? "select PhoneNumber"
                : ""}
            </p>
          </Col>
          {showMessagingService === true && selectedMenuItem === "AddPhone" ? (
            <>
              <Col className="showMessagingServiceSelect m-3">
                <Select
                  className="msgServiceSelect"
                  size="large"
                  open={openSelect}
                  value={selectedValue}
                  onFocus={() => {
                    setInvalidMessagingService(false);
                  }}
                  onClick={() => {
                    setOpenSelect(true);
                  }}
                  placeholder="Associate Service"
                  style={{ width: 200, height: 40 }}
                  onSelect={handleSelectChange}
                  onBlur={handleSelectBlur}
                  dropdownStyle={{ zIndex: 10 }}
                >
                  <Select.Option value="">Create Messaging Service</Select.Option>
                  {msgSidList?.map((number, index) => {
                    return (
                      <>
                        <Select.Option key={index} value={number.sid}>
                          {number.friendlyName + "(" + number.sid + ")"}
                        </Select.Option>
                      </>
                    );
                  })}
                </Select>
                <br />
                <Row className="d-flex justify-content-between align-items-center">
                  <span
                    id="nameErrorHide"
                    style={{
                      display: invalidMessagingService ? "inherit" : "none",
                      color: "red",
                    }}
                  >
                    Please choose Assosiated SID
                  </span>
                  <InfoCircleFilled
                    onClick={() => {
                      window.open("https://help.oapps.xyz/portal/en/kb/articles/10dlc-registration-for-sms-providers");
                    }}
                    className="ms-auto mt-1"
                    style={{
                      color: "#009EFD",
                      cursor: "pointer",
                      fontSize: 18,
                    }}
                  />
                </Row>
                <NoticeModal
                  show={showNotice}
                  cancel={handleCancel}
                  handleModalConfirm={handleModalConfirm}
                  handleModalCancel={handleModalCancel}
                />
              </Col>
            </>
          ) : null}
          <Col className="m-3">
            <Input
              onFocus={() => {
                setInvalidName(false);
              }}
              onChange={(val) => {
                setFriendlyName(val.target.value.trim());
              }}
              id="friendlyNameInput"
              placeholder="Friendly Name"
              style={{ width: 200, height: 40 }}
              type="text"
              name="name"
            />
            <br />
            <Row>
              <span id="nameErrorHide" style={{ display: invalidName ? "inherit" : "none" }}>
                Enter the friendly name
              </span>
              <p
                id="nameError"
                style={{
                  opacity: invalidName ? "100%" : "50%",
                  color: invalidName ? "red" : "black",
                }}
              >
                Eg: US Support
              </p>
            </Row>
          </Col>

          <Col className="m-3">
            <Button
              onClick={handleClickAddBtn}
              type="primary"
              ghost
              className={`add-btn ${isMsgAddButtonDisabled && onChangeMsgAddButtonDisabled ? "addMsgServiceBtnDisabled" : ""}`}
              disabled={isMsgAddButtonDisabled && onChangeMsgAddButtonDisabled}
              loading={isLoadingAddBtn}
              style={{
                width: 100,
                height: 35,
              }}
            >
              ADD
            </Button>
            <br />
            <p
              className="existError"
              style={{
                display: numberAlreadyAdded ? "inherit" : "none",
              }}
            >
              {selectedMenuItem === "AddPhone"
                ? "PhoneNumber already added"
                : selectedMenuItem === "MessagingService"
                ? " MessageServices ID already added"
                : selectedMenuItem === "AlphaSender"
                ? "Alpha Sender Id already added"
                : ""}
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
});

export default AddPhone;
