import React from "react";
import Icon from "@ant-design/icons";

import "../../assets/css/content.css";

const ChatGptLoadingIcon = React.memo(function ChatGptLoadingIcon(props) {
  const showLoading = props.loading;
  console.log("ChatGptLoadingIcon >>>>>>>>>>>> ", showLoading);
  if (showLoading) {
    return (
      <>
        <Icon spin component={LoadingImage} />
      </>
    );
  } else {
    return <> </>;
  }
});

function LoadingImage() {
  console.log("it works");
  return (
    <>
      <div className="container-fluid overlay w-100 h-100 d-flex justify-content-center align-items-center p-3">
        <div className="row">
          <div className="col">
            <img
              alt="example"
              height={50}
              width={50}
              className="img-thumbnail border-0"
              src={require("./../../assets/gif/chatgpt-loading.gif")}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatGptLoadingIcon;
