import { Button } from "antd";
import "../../assets/css/buyNumber.css";
import { useContext, useEffect, useState } from "react";
import { urlParams } from "../../constants/AppConstants";
import { AppContext } from "../../context/AppContext";
import UserIcon from "../../assets/icons/UserIcon.png";
import UserIconBlue from "../../assets/icons/userIconBlue.png";
import NumberIcon from "../../assets/icons/NumberIcon.png";
import NumberIconBlue from "../../assets/icons/numberIconBlue.png";
import { useNavigate } from "react-router-dom";
import useHttp from "../../hooks/useHttp";

import { ProviderCommonObj, ProviderExtendedCommonObj } from "../../constants/ProviderConstants.js";

function AccountPhone() {
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [selectedBtn, setSelectedBtn] = useState(true);
  const [concludeValue, setConcludeValue] = useState("authorize");
  const [navLink, setNavLink] = useState("");
  const [nextBtnLoading, setNextBtnLoading] = useState(false);
  const leftServiceName = appProperties.displayName;
  const integId = appProperties.integId;
  const leftServiceId = appProperties.leftServiceId;
  const rightServiceName = appProperties.rightServiceName;
  const { fetchData } = useHttp();
  const navigate = useNavigate();

  let checkCreated = appProperties?.helloSendAccountId;
  let authObj = appProperties.authorizeObj;
  console.log("authobj in account>>>>", authObj);
  let rightAuth = authObj?.right?.auth?.authorized;
  if (checkCreated !== undefined && checkCreated !== null) {
    navigate("/account/helloSend/buyNumber?" + urlParams);
  }
  if (rightAuth) {
    navigate("/guide?" + urlParams);
  }
  useEffect(() => {
    if (concludeValue === "buyNumber") {
      setNavLink("/account/helloSend/" + concludeValue + "?" + urlParams);
    } else {
      setNavLink("/account/" + rightServiceName + "/" + concludeValue + "?" + urlParams);
    }
  }, [concludeValue]);

  const handleNextBtnCLick = () => {
    setNextBtnLoading(true);
    if (concludeValue === "buyNumber") {
      fetchData("hellosend/account/" + integId + "/create?leftServiceId=" + leftServiceId, "POST", null, appProperties)
        .then(function (response) {
          response = JSON.parse(response);
          let newIntegId = response.data.integration.integId;
          let licenseDetails = response.data.license;
          let helloSendAccountId = response.data.helloSendAccountId;

          changeAppProperties(newIntegId, licenseDetails, helloSendAccountId);
          navigate(navLink);
          setNextBtnLoading(false);
        })
        .catch((error) => {
          setNextBtnLoading(false);
        });
    } else {
      navigate(navLink);
    }
  };

  function changeAppProperties(integId, license, helloSendAccountId) {
    if (concludeValue === "buyNumber") {
      let assignerObj1 = Object.assign(ProviderCommonObj, ProviderExtendedCommonObj["hello_send"]); // variant#1
      appProperties.providerCommonObj = assignerObj1;
      appProperties.integId = integId;
      appProperties.licenseObj.licenseDetails = license;
      appProperties.rightServiceName = "hello_send";
      appProperties.helloSendAccountId = helloSendAccountId;

      setAppProperties(appProperties);
    }
  }

  function handleDontHaveAnNumber() {
    setSelectedBtn(false);
    setConcludeValue("buyNumber");
  }

  return (
    <>
      <div className="landingPageText">
        <p>
          Supercharge your <span style={{ color: "#017737", fontSize: 23 }}>{leftServiceName} </span>
          sales with SMS channel
        </p>
        <p style={{ opacity: "55%" }}>Boost Engagement , Drive Conversions!</p>

        <div className="d-flex m-auto justify-content-center">
          <div
            onClick={() => {
              setSelectedBtn(true);
              setConcludeValue("authorize");
            }}
            className={selectedBtn ? "checkPhoneBtnSelected me-5" : "checkPhoneBtn me-5"}
          >
            <img
              src={selectedBtn ? UserIconBlue : UserIcon}
              alt="userIcon"
              style={{
                height: 30,
                width: 30,
                margin: "30px auto",
                color: selectedBtn ? "#009EFD" : "#5E6A72",
              }}
            />
            <div style={{ height: 130 }} className="p-2">
              I have a {appProperties.rightServiceName} number, and I want to integrate it with Pipedrive to communicate with my contacts.
            </div>
            <div className="prerequiste">
              <span style={{ color: "#8B8B8B" }}>Prerequisite: </span>
              <span style={{ fontSize: 12 }}>{appProperties.rightServiceName} account & SMS enabled number</span>
            </div>
          </div>
          <div onClick={handleDontHaveAnNumber} className={selectedBtn ? "checkPhoneBtn" : "checkPhoneBtnSelected"}>
            <img
              alt="numberIcon"
              src={selectedBtn ? NumberIcon : NumberIconBlue}
              style={{
                height: 30,
                width: 30,
                margin: "30px auto",
                color: selectedBtn ? "#5E6A72" : "#009EFD",
              }}
            />
            <div style={{ height: 130 }} className="p-2">
              I don't have a {appProperties.rightServiceName} account but need to enable the SMS channel for Pipedrive to engage my contacts.
            </div>
            <div className="prerequiste">
              <div>No prerequisites . You can buy a number starting from $1.25 in the US and Canada region.</div>
            </div>
          </div>
        </div>
        <br />
        <Button loading={nextBtnLoading} onClick={handleNextBtnCLick} type="primary" className="checkPhoneNextBtn" value={concludeValue}>
          Next
        </Button>
      </div>
    </>
  );
}
export default AccountPhone;
