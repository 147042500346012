import { Button, Card, Col, Flex, Input, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import useAdminHttp from "../../hooks/useAdminHttp";
import { failureNotification } from "../../../pages/utils/CommonNotifications";
const { Paragraph } = Typography;

export default function RingCentralSubscription() {
  const { fetchData } = useAdminHttp();
  const [osyncId, setOsyncId] = useState("");
  const [integId, setIntegId] = useState("");
  const [osyncUserId, setOsyncUserId] = useState("");
  const [rightServiceId, setRightServiceId] = useState("");
  const [subscriptionResponse, setSubscriptionResponse] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  const createSubscription = () => {
    fetchData(
      "create/ringcentral/subscription?osyncId=" +
        osyncId +
        "&rightServiceId=" +
        rightServiceId +
        "&integId=" +
        integId +
        "&osyncUserId=" +
        osyncUserId,
      "POST"
    ).then((res) => {
      try {
        if (res) {
          console.log("ringcentral/subscription>>>>>>>>>>", res);
          console.log("ringcentral/subscription>>>>>> res data >>>>", res?.data);
          setSubscriptionResponse(res?.data);
        } else {
          failureNotification("Error", "Something went wrong !");
          return;
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  useEffect(() => {
    const areInputsValid = osyncId && integId && osyncUserId && rightServiceId;
    setDisableButton(!areInputsValid);
  }, [osyncId, integId, osyncUserId]);

  return (
    <>
      <Card
        className="toolsCard"
        title="Migration"
        bordered={false}
        style={{
          width: "100%",
        }}
      >
        <Row className="mt-5">
          <Col span={6}>
            <div id="getUrlEncodedString">
              <div className="align-item-bottom d-flex justify-content-end mt-2">
                <label htmlFor="Right service id">Enter Right Service Id</label>
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div id="getUrlEncodedString">
              <div className="d-flex justify-content-left ms-4">
                <label htmlFor="Right service id" className="mb-3">
                  <Input
                    style={{ width: "25pc" }}
                    id="rightServiceId"
                    placeholder="Right service id"
                    onChange={(e) => setRightServiceId(e.target.value)}
                    value={rightServiceId}
                  />
                </label>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col span={6}>
            <div id="getUrlEncodedString">
              <div className="align-item-bottom d-flex justify-content-end mt-2">
                <label htmlFor="Osync Id">Enter OsyncId</label>
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div id="getUrlEncodedString">
              <div className="d-flex justify-content-left ms-4">
                <label htmlFor="Osync Id" className="mb-3">
                  <Input
                    style={{ width: "25pc" }}
                    id="osyncId"
                    placeholder="Osync Id"
                    onChange={(e) => setOsyncId(e.target.value)}
                    value={osyncId}
                  />
                </label>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col span={6}>
            <div>
              <div className="align-item-bottom d-flex justify-content-end mt-2">
                <label htmlFor="Integ Id">Enter Integ Id</label>
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div className="d-flex justify-content-left ms-4">
                <label htmlFor="Integ Id" className="mb-3">
                  <Input
                    style={{ width: "25pc" }}
                    id="integId"
                    placeholder="Integ Id"
                    onChange={(e) => setIntegId(e.target.value)}
                    value={integId}
                  />
                </label>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col span={6}>
            <div>
              <div className="align-item-bottom d-flex justify-content-end mt-2">
                <label htmlFor="Osync User Id">Enter Osync User Id</label>
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div className="d-flex justify-content-left ms-4">
                <label htmlFor="Osync User Id" className="mb-3">
                  <Input
                    style={{ width: "25pc" }}
                    id="osyncUserId"
                    placeholder="Osync User Id"
                    onChange={(e) => setOsyncUserId(e.target.value)}
                    value={osyncUserId}
                  />
                </label>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col span={6}></Col>
          <Col span={6}>
            <div className="d-flex flex-row-reverse">
              <div className="d-flex justify-content-left"></div>
              <div className="submit">
                <Flex gap="small" wrap="wrap">
                  <Button
                    className="ant-btn css-dev-only-do-not-override-amq5gd ant-btn-default"
                    disabled={disableButton}
                    onClick={createSubscription}
                  >
                    Submit
                  </Button>
                </Flex>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <SubscriptionResponse rcSubscriptionResponse={subscriptionResponse} />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export const SubscriptionResponse = (props) => {
  const { rcSubscriptionResponse } = props;
  console.log("rcSubscriptionResponse >>>>>>>>>>>>", rcSubscriptionResponse);
  console.log("typeof rcSubscriptionResponse >>>>>>>>>>>>", typeof rcSubscriptionResponse);

  const subscriptionDiv = Object.entries(rcSubscriptionResponse).map(([key, value], index) => {
    return (
      <div key={index}>
        {console.log("rcSubscriptionResponse >>>>>> key >>>>>>", key)}
        {console.log("rcSubscriptionResponse >>>>>> value >>>>>>", JSON.stringify(value))}
        <i className={`ico ico-${key}`}></i> <span>{JSON.stringify(key)}</span> : <span>{JSON.stringify(value)}</span>
      </div>
    );
  });

  return (
    <div className="col-5">
      <div>{subscriptionDiv}</div>
    </div>
  );
};
