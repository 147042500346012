import { Button, Drawer, Tag } from "antd";
import { useState, useEffect } from "react";
import "../assets/css/RightPanel.css";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Collapse } from "antd";

const FetchLicense = ({
  accountData,
  stripeSubscriptionData,
  chargebeeSubscriptionData,
  status,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [hide, setHide] = useState(false);
  const [additionalDetails, setAdditionalDetails] = useState(false);
  const [tagDisabled, setTagDisabled] = useState(false);
  const [tagProps, setTagProps] = useState();

  const leftServiceName = accountData["Left Service Name"]
    ?.toLowerCase()
    .replace(/[-_\s]/g, "");
  const rightServiceName = accountData["Right Service Name"]
    ?.toLowerCase()
    .replace(/[-_\s]/g, "");

  const serviceMap = new Map([["zohocrm_ringcentral", "stripe"]]);

  useEffect(() => {
    if (
      !leftServiceName ||
      !rightServiceName ||
      rightServiceName === "zohosign"
    ) {
      setHide(true);
    } else {
      setHide(false);
    }
  }, [leftServiceName, rightServiceName]);

  useEffect(() => {
    let statusColor = tagColor(status);
    setTagProps(statusColor);
  }, [status]);
 
  useEffect(() => {
    if (!stripeSubscriptionData && !chargebeeSubscriptionData) {
      setTagDisabled(true);
    } else {
      setTagDisabled(false);
    }
  }, [stripeSubscriptionData, chargebeeSubscriptionData]);

  const concatenatedServiceName = leftServiceName + "_" + rightServiceName;
  const closeDrawer = () => {
    setDrawerVisible(false);
    setAdditionalDetails(false);
  };

  const openDrawer = () => {
    if (stripeSubscriptionData || chargebeeSubscriptionData) {
      setDrawerVisible(true);
    }
  };
  const handleSeeMore = () => {
    setAdditionalDetails(!additionalDetails);
  };

  const displayLicenseDetails = (data) => {
    const accordionItems = [];

    const licenseDataArr = [];
    if (data?.transaction) {
      licenseDataArr.push({ obj: data?.transaction, name: "transaction" });
    } else {
      licenseDataArr.push({
        obj: data,
        name: "transaction",
      });
    }
    while (licenseDataArr.length > 0) {
      const { obj, name } = licenseDataArr.shift();
      const accordionContent = [];
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];

          const keyName = key?.charAt(0)?.toUpperCase() + key.slice(1);
          if (Array.isArray(value) && value.length > 0) {
            value.forEach((item, index) => {
              if (typeof item === "object" && item !== null) {
                licenseDataArr.push({ obj: item, name: keyName });
              } else {
                accordionContent.push(
                  <div key={index} className="licenseDataDiv">
                    <span>
                      <b>{keyName}: </b>{" "}
                    </span>
                    <span> {item}</span>
                    <br />
                  </div>
                );
              }
            });
          } else if (typeof value === "number" && value.toString().length > 9) {
            const formattedNum = new Date(value * 1000).toLocaleString();
            accordionContent.push(
              <div key={keyName} className="licenseDataDiv">
                <span>
                  <b>{keyName} :</b>
                </span>
                <span> {formattedNum}</span>
                <br />
              </div>
            );
          } else if (typeof value === "boolean") {
            const convertedString = value.toString();
            accordionContent.push(
              <div key={keyName} className="licenseDataDiv">
                <span>
                  <b>{keyName} :</b>{" "}
                </span>
                <span> {convertedString}</span>
                <br />
              </div>
            );
          } else if (!value || (Array.isArray(value) && value.length === 0)) {
            accordionContent.push(
              <div key={keyName} className="licenseDataDiv">
                <span>
                  <b>{keyName} :</b>
                </span>
                <span> null</span>
                <br />
              </div>
            );
          } else if (typeof value === "object") {
            if (keyName !== "Payment_method_details") {
              licenseDataArr.push({ obj: value, name: key });
            }
          } else {
            accordionContent.push(
              keyName === "Payment_method_details" ? (
                <div key={keyName} className="licenseDataDiv">
                  <span>
                    <b>{keyName} :</b>{" "}
                  </span>
                  <span>***</span>
                  <br />
                </div>
              ) : keyName === "Receipt_url" ? (
                <div key={keyName} className="licenseDataDiv">
                  <span>
                    <b>{keyName} :</b>{" "}
                  </span>

                  <span className="longString">{value}</span>
                  <br />
                </div>
              ) : (
                <div key={keyName} className="licenseDataDiv">
                  <span>
                    <b>{keyName} :</b>{" "}
                  </span>
                  <span> {value}</span>
                  <br />
                </div>
              )
            );
          }
        }
      }
      accordionItems.push({
        key: name,
        label: name.toUpperCase(),
        children: accordionContent.map((item, index) => (
          <div key={index}>{item}</div>
        )),
      });
    }

    return <Collapse items={accordionItems} />;
  };

  const displayTransactionDetails = (data) => {
    let accordionItems = [];
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        if (Array.isArray(value)) {
          const accordionContent = value.map((transactionItem, index) =>
            key === "list" ? (
              <div key={index} className="licenseDataDiv">
                <span>
                  Transaction Status:{" "}
                  <b>
                    {transactionItem?.transaction?.status?.toUpperCase()} at{" "}
                    {new Date(
                      transactionItem.transaction.date * 1000
                    ).toLocaleDateString()}
                  </b>
                </span>
              </div>
            ) : key === "data" ? (
              <div key={index} className="licenseDataDiv">
                <span>
                  Transaction Status:{" "}
                  <b>
                    {transactionItem?.status?.toUpperCase()} at{" "}
                    {new Date(
                      transactionItem.created * 1000
                    ).toLocaleDateString()}
                  </b>
                </span>
              </div>
            ) : null
          );
          accordionItems.push({
            key: key,
            label: "TRANSACTION DATA LIST",
            children: accordionContent,
          });
        }
      }
    }

    return <Collapse items={accordionItems} />;
  };

  const tagColor = (status) => {
    const statusMap = new Map([
      [
        "active",
        {
          color: "success",
          icon: <CheckCircleOutlined />,
        },
      ],
      [
        "future",
        {
          color: "warning",
          icon: <ExclamationCircleOutlined />,
        },
      ],
      [
        "cancelled",
        {
          color: "error",
          icon: <CloseCircleOutlined />,
        },
      ],
      [
        "canceled",
        {
          color: "error",
          icon: <CloseCircleOutlined />,
        },
      ],
      [
        "paused",
        {
          color: "warning",
          icon: <ExclamationCircleOutlined />,
        },
      ],
      [
        "in_trial",
        {
          color: "warning",
          icon: <ExclamationCircleOutlined />,
        },
      ],
      [
        "trailing",
        {
          color: "warning",
          icon: <ExclamationCircleOutlined />,
        },
      ],
      [
        "transferred",
        {
          color: "warning",
          icon: <ExclamationCircleOutlined />,
        },
      ],
      [
        "incomplete",
        {
          color: "error",
          icon: <CloseCircleOutlined />,
        },
      ],
      [
        "incomplete_expired",
        {
          color: "error",
          icon: <CloseCircleOutlined />,
        },
      ],
      [
        "unpaid",
        {
          color: "error",
          icon: <CloseCircleOutlined />,
        },
      ],
      [
        "past_due",
        {
          color: "error",
          icon: <CloseCircleOutlined />,
        },
      ],
      [
        "non_renewing",
        {
          color: "error",
          icon: <CloseCircleOutlined />,
        },
      ],
    ]);

    return (
      statusMap.get(status) || {
        color: "default",
        icon: <CloseCircleOutlined />,
      }
    );
  };

  const stripeResponseData = stripeSubscriptionData?.transaction?.data[0];
  const chargebeeResponseData =
    chargebeeSubscriptionData?.transaction?.list[0]?.transaction;
  return (
    <>
      <Tag
        color={tagProps?.color}
        icon={tagProps?.icon}
        className="antd-tag"
        hidden={hide}
        onClick={openDrawer}
        style={{ cursor: tagDisabled ? "not-allowed" : "pointer" }}
      >
        {serviceMap.get(concatenatedServiceName) === "stripe" ? (
          <span>Stripe License</span>
        ) : (
          <span>Chargebee License</span>
        )}
      </Tag>
      <Drawer
        rootClassName="licenseDrawer"
        title="Subscription Details"
        placement="right"
        closable={true}
        destroyOnClose={true}
        onClose={closeDrawer}
        open={drawerVisible}
        width={500}
      >
        <>
          {serviceMap.get(concatenatedServiceName) === "stripe"
            ? stripeSubscriptionData && (
                <>
                  <p>
                    Subscription Status :{" "}
                    <b>
                      {stripeSubscriptionData?.subscription?.status?.toUpperCase()}
                    </b>
                  </p>

                  <p>
                    Transaction Status:{" "}
                    <b>
                      {stripeResponseData?.status?.toUpperCase()} at{" "}
                      {new Date(
                        stripeResponseData?.created * 1000
                      ).toLocaleDateString()}
                    </b>
                  </p>

                  <p>Customer ID: {stripeResponseData?.customer}</p>
                  <p>Paid: {stripeResponseData?.paid.toString()}</p>
                  <p>
                    Error Code:{" "}
                    {stripeResponseData?.failure_code != null
                      ? stripeResponseData?.failure_code?.toString()
                      : "null"}
                  </p>
                  <p>
                    Error Message:{" "}
                    {stripeResponseData?.failure_message != null
                      ? stripeResponseData?.failure_message?.toString()
                      : "null"}
                  </p>
                  <div className="seeMoreButtonDiv">
                    <Button
                      className="seeMoreButton"
                      onClick={() => handleSeeMore()}
                    >
                      {additionalDetails ? "Hide Details" : "See More"}
                    </Button>
                  </div>

                  {additionalDetails && (
                    <div>
                      <h6>Overall Subscription Details</h6>
                      {displayLicenseDetails(stripeResponseData)}
                      {displayTransactionDetails(
                        stripeSubscriptionData?.transaction
                      )}
                    </div>
                  )}
                </>
              )
            : chargebeeSubscriptionData && (
                <>
                  <p>
                    Subscription Status:{" "}
                    <b>
                      {chargebeeSubscriptionData?.subscription?.subscription?.status.toUpperCase()}
                    </b>
                  </p>
                  <p>
                    Transaction Status:{" "}
                    <b>
                      {" "}
                      {chargebeeResponseData?.status?.toUpperCase()} at{" "}
                      {new Date(
                        chargebeeResponseData?.date * 1000
                      ).toLocaleDateString()}
                    </b>
                  </p>

                  <p>Customer ID: {chargebeeResponseData?.customer_id}</p>
                  <p>Subscription ID: {chargebeeResponseData?.id}</p>
                  <p>Error Code: {chargebeeResponseData?.error_code}</p>
                  <p>Error Message: {chargebeeResponseData?.error_text}</p>

                  <div className="seeMoreButtonDiv">
                    <Button
                      className="seeMoreButton"
                      onClick={() => handleSeeMore()}
                    >
                      {additionalDetails ? "Hide Details" : "See More"}
                    </Button>
                  </div>

                  {additionalDetails && (
                    <div>
                      <h6>Overall Subscription Details</h6>
                      {displayLicenseDetails(chargebeeResponseData)}
                      {displayTransactionDetails(
                        chargebeeSubscriptionData?.transaction
                      )}
                    </div>
                  )}
                </>
              )}
        </>
      </Drawer>
    </>
  );
};

export default FetchLicense;
