import { Button, Popconfirm, Table, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useEffect } from "react";

import useAdminHttp from "../../hooks/useAdminHttp";

export default function AgentsContent({ setIsLoading, isLoading, getAndSetCurrentAgentsData, agentsData }) {
  const { fetchData } = useAdminHttp();
  const agentDeleteHandler = (record) => {
    setIsLoading(true);
    console.log("deleting ::: ", record);
    fetchData("adminUser", "delete", record)
      .then((res) => {
        if (res.status === 200 && res?.data) {
          message.success("Agent removed successfully");
        } else {
          throw new Error("Failed to remove agent");
        }
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        getAndSetCurrentAgentsData();
        setIsLoading(false);
      });
  };

  const agentsDataColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 280,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 320,
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      width: 100,
      render: (_, record) => (
        <div className="row ms-3">
          <Popconfirm title="Are you sure want to delete this agent ?" onConfirm={() => agentDeleteHandler(record)}>
            <Button className="pb-2 w-auto h-25">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getAndSetCurrentAgentsData();
  }, []);

  return <Table pagination={false} loading={isLoading} dataSource={agentsData} columns={agentsDataColumns} />;
}
