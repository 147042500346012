import React, { useState, useMemo, useEffect } from "react";
import { Select, Input, Typography, Empty } from "antd";
const { Option } = Select;

const CustomSearchDropdownSelect = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { options, customProps, listEndComponent, selectSource } = props;
  const [searchBoxPlaceHolder, setSearchBoxPlaceHolder] = useState("");
  const { Text } = Typography;

  const filterFromString = (stringToBeFiltered, searchTerm) => {
    return stringToBeFiltered?.toLowerCase()?.includes(searchTerm?.toLowerCase());
  };

  useEffect(() => {
    if (customProps?.searchBoxPlaceHolder) {
      setSearchBoxPlaceHolder(customProps?.searchBoxPlaceHolder ?? "Search");
    }
  }, [customProps?.searchBoxPlaceHolder]);
  const filteredOptions = useMemo(() => {
    return searchTerm
      ? options?.filter((option) => {
          let stringToFilter = "";
          if (typeof option?.label === "string") {
            stringToFilter = option?.labelText ?? option?.label;
          } else {
            stringToFilter = option?.labelText ?? option?.label?.props?.children;
          }

          return filterFromString(stringToFilter, searchTerm);
        })
      : options;
  }, [options, searchTerm]);

  const dropdownRender = (menu) => (
    <div className="px-1">
      <div className="d-flex mt-2 mb-3">
        <Input
          size="small"
          className="rounded border-0 searchBoxInSelectComponent"
          placeholder={searchBoxPlaceHolder}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="dropDownList">{menu}</div>
      <div>{listEndComponent}</div>
    </div>
  );

  return (
    <>
      <Select
        {...customProps}
        filterOption={false}
        popupClassName="customSelectPopup"
        dropdownRender={options && options.length > 0 ? dropdownRender : null}
        onDropdownVisibleChange={(visible) => {
          if (!visible) {
            setSearchTerm("");
          }
        }}
        notFoundContent={
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={<span>No {selectSource}!</span>}
          ></Empty>
        }
      >
        {filteredOptions?.map((option) => (
          <Option key={option?.value} value={option?.value}>
            <div className="d-flex align-items-center hs-w-90 justify-content-between pt-1 pb-1">
              <Text disabled={customProps?.disabled} ellipsis={true}>
                {option?.label}
              </Text>
              {option?.count && <div className="hs-bg-light-silver px-2 rounded-pill">{option?.count}</div>}
            </div>
          </Option>
        ))}
      </Select>
    </>
  );
};

export default CustomSearchDropdownSelect;
