import { Col, Row, Grid, Tooltip, Typography, Badge } from "antd";

export function CampaignOverallMetric(props) {
  const { data, icon, message, comingSoon } = props;
  return (
    <>
      <MetricContent data={data} icon={icon} message={message} comingSoon={comingSoon} style={{}} />
    </>
  );
}

const ResponsiveTitle = ({ value }) => {
  const { xl, xxl } = Grid.useBreakpoint();
  const { Title } = Typography;
  const level = xxl ? 3 : xl ? 4 : 5;
  return (
    <Tooltip placement="left" title={value}>
      <Title ellipsis className="pb-0 mb-0 hs-fw-800 hs-fs-22" level={level}>
        {value < 999999 ? `${value}` : `999999+`}
      </Title>
    </Tooltip>
  );
};

const MetricContent = ({ data, icon, message, comingSoon }) => {
  return (
    <div
      className={`w-100 bg-white hs-border-10 metricContainer d-flex align-items-center justify-content-center me-2 ${
        comingSoon ? "disabled-look" : ""
      }`}
    >
      <span style={{ float: "left" }} className="pb-4">
        {icon}
      </span>
      <span style={{ float: "right" }} className="d-grid justify-content-center align-items-center">
        <div className="hs-fs-15 truncate-text">
          <span>{message}</span>
        </div>
        <div className="d-grid justify-content-start align-items-start">
          <span>
            <ResponsiveTitle value={data} />
          </span>
        </div>
      </span>
    </div>
  );
};
