const origin = window.location.origin;
let url;
if (origin) {
  if (origin.startsWith("http://localhost")) {
    url = "http://localhost:7000/adminapi/v2/";
  } else if (origin.indexOf("rekzyl.com") !== -1) {
    url = origin.replace("client", "server") + "/adminapi/v2/";
  } else if (origin.indexOf("pre-omessage.oapps.xyz") !== -1) {
    url = "https://pre-api.oapps.xyz/rest/adminapi/v2/";
  } else {
    url = "https://api.oapps.xyz/rest/adminapi/v2/";
  }
}
export let ADMIN_SERVER_API = url;
