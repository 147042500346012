import React, { useState, useContext, useEffect } from "react";

import { AppContext } from "./../context/AppContext";
import { SyncOutlined, LoadingOutlined } from "@ant-design/icons";
import ChatGptLoadingIcon from "./chatgpt/ChatGptLoading";
import useHttp from "../hooks/useHttp";

import { Space, Input, Row, Col, Tooltip, Button, Divider, Card, Cascader, Spin, notification } from "antd";

import "./../assets/css/content.css";
import { languages } from "./../constants/AppConstants";
import { Credits } from "./../components/main/Credits";
import { failureNotification } from "./utils/CommonNotifications";

const { TextArea } = Input;

const testOption = [];

function ContentGpt() {
  const [contextHolder] = notification.useNotification();
  const [loadingButton, setLoadingButton] = useState("Generate");
  const [commonLoading, setCommonLoading] = useState(true);
  const [loadingButtonRegenerate, setLoadingButtonRegenerate] = useState(<SyncOutlined />);
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [textAreaInput, setTextAreaInput] = useState("");
  const [options, setOptions] = useState(testOption);
  const [resultAreaInput, setResultAreaInput] = useState("");
  const [resultAreaClass, setResultAreaClass] = useState("generatedInputBorder d-flex align-items-start w-100 h-100");
  const [textDropdownVal, setTextDropdownVal] = useState("");
  const [disabledLink, setDisabledLink] = useState(false);

  const integId = appProperties.integId;

  useEffect(() => {
    if (appProperties?.licenseObj) {
      let { features } = appProperties.licenseObj;

      if (features !== undefined && features !== null) {
        var tempActionList = [];
        for (var i = 0; i < features.length; i++) {
          var actionObj = {};
          actionObj.value = features[i].featureName;
          actionObj.label = features[i].featureName;
          if (features[i].featureName === "Translate to") {
            actionObj.children = languages;
          } else {
            actionObj.children = [];
          }
          tempActionList.push(actionObj);
        }
        setOptions(tempActionList);

        if (tempActionList.length > 0) {
          let selectedAction = [];
          selectedAction.push(tempActionList[0].value);
          setTextDropdownVal(selectedAction);
        }
        setCommonLoading(false);
      }
    }
  }, [appProperties.licenseObj]);
  function handleCreditBalance(creditObj) {
    let usagePercentage = creditObj.usagePercentage;

    if (usagePercentage === 100) {
      setDisabledLink(true);
    } else {
      setDisabledLink(false);
    }
  }

  const generateContent = (tryAgain) => {
    let contentTobeGenerated = tryAgain ? resultAreaInput : textAreaInput;
    contentTobeGenerated = contentTobeGenerated.replace(/\n/g, "");
    contentTobeGenerated = contentTobeGenerated.trim();
    if (contentTobeGenerated === "" || contentTobeGenerated === null || contentTobeGenerated === undefined) {
      failureNotification("Error", "Please enter the content to generate");
      return;
    }

    let actionItem = textDropdownVal;
    if (actionItem === null || actionItem === undefined || actionItem === "") {
      failureNotification("Error", "Choose the action to generate");
      return;
    }
    if (tryAgain) {
      const antIconRegenerate = <LoadingOutlined spin />;
      setLoadingButtonRegenerate(() => <Spin indicator={antIconRegenerate} className="generateBtn" align="left"></Spin>);
    } else {
      const antIcon = <LoadingOutlined spin />;
      setLoadingButton(() => <Spin indicator={antIcon} className="generateBtn" align="left"></Spin>);
      setResultAreaClass("generatedInputBorder d-flex align-items-start w-100 disabled-class-color h-100");
    }

    if (actionItem && actionItem.length > 1) {
      actionItem = textDropdownVal[0] + " " + textDropdownVal[1];
    } else {
      actionItem = textDropdownVal[0];
    }

    if (tryAgain) {
      setResultAreaClass("generatedInputBorder d-flex align-items-start w-100 disabled-class-color  h-100");
      actionItem += " again";
      console.log(actionItem, "actionItem");
    }

    let targetUrl = `integration/${integId}/chatgpt/content`;

    let payloadJson = {
      prompt: contentTobeGenerated,
      action: actionItem,
    };

    appProperties
      .fetchData(targetUrl, "POST", payloadJson, appProperties)
      .then((response) => {
        const responseData = JSON.parse(response);
        console.log(responseData, "response for chat Gpt");

        if (responseData.data.status === "false") {
          let message = responseData.data.license.description;
          console.log(message, "message");
          failureNotification("Error", message);
          setLoadingButton("Generate");
        } else {
          if (responseData?.data?.choices.length > 0) {
            let suggestedText = responseData.data.choices[0].text;
            setLoadingButton("Generate");
            setLoadingButtonRegenerate(<SyncOutlined />);
            setResultAreaInput(suggestedText.trim());
            setResultAreaClass("generatedInputBorder d-flex align-items-start w-100  h-100");
            appProperties.licenseObj = responseData.data;
            console.log(appProperties.licenseObj, " appProperties.licenseObj");
            setAppProperties(appProperties);
          }
        }
      })
      .catch((err) => {
        setLoadingButton("Generate");
        setLoadingButtonRegenerate(<SyncOutlined />);
        console.log("Axios Error", err);
        return {
          message: "LICENSE_INACTIVE",
        };
      });
  };

  const cascaderFilter = (inputValue, path) => {
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  };

  return (
    <>
      {contextHolder}
      <div className="p-2 h-100 w-100 position-absolute">
        <Row className="p-2 h-100 w-100" align="center">
          <Col className="overflow-auto">
            <ChatGptLoadingIcon loading={commonLoading} />
            <Row>
              <Col span={24}>
                <Credits handleCreditBalance={handleCreditBalance} />
              </Col>
            </Row>
            <Row className="p-2 pt-3" align="end">
              <Col>
                <Space.Compact>
                  <Cascader
                    options={options}
                    allowClear={false}
                    disabled={disabledLink}
                    onChange={(value) => {
                      console.log("value >>>>>>>>>>> ", value);
                      setTextDropdownVal(value);
                    }}
                    showSearch={{
                      cascaderFilter,
                    }}
                    defaultValue={textDropdownVal}
                    value={textDropdownVal}
                    onSearch={(value) => console.log(value)}
                  />
                </Space.Compact>
              </Col>
            </Row>
            <Row className="p-2 h-25" style={{ minHeight: "190px" }} align="end">
              <TextArea
                style={{
                  fontSize: "12px",
                }}
                className="inputAreaSize"
                rows={4}
                disabled={disabledLink}
                onChange={(e) => {
                  let inputValue = e.target.value;
                  setTextAreaInput(inputValue);
                }}
                placeholder="Enter or Paste it here and press Generate "
              ></TextArea>
            </Row>
            <Row align="end">
              <Col>
                <Tooltip title="Click to generate">
                  <Button
                    className="site-form-item-icon text-primary generateBtn"
                    disabled={disabledLink}
                    style={{
                      paddingRight: "8px",
                      fontSize: "12px",
                    }}
                    type="Link"
                    onClick={() => {
                      generateContent(false, 0);
                    }}
                  >
                    {loadingButton}
                  </Button>
                </Tooltip>
              </Col>
            </Row>
            <Divider className="m-2 w-25" align="end" />
            <Row className="p-2 cardPara h-25" style={{ "min-height": "225px" }} align="end">
              <Card className="p-0 m-0 w-100 border h-100">
                <Row
                  align="end"
                  style={{
                    padding: "0px !important",
                    margin: "0px !important",
                  }}
                >
                  <Tooltip title="Regenerate">
                    <div
                      onClick={() => {
                        generateContent(true);
                      }}
                    >
                      {loadingButtonRegenerate}
                    </div>
                  </Tooltip>
                </Row>
                <TextArea
                  className={resultAreaClass}
                  disabled={disabledLink}
                  value={resultAreaInput}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    setResultAreaInput(inputValue);
                  }}
                ></TextArea>
              </Card>
            </Row>
            <Row align="end" className="p-2">
              <Col>
                <Tooltip title="Click to insert">
                  <Button
                    className="site-form-item-icon text-primary generateBtn"
                    disabled={disabledLink}
                    style={{
                      paddingRight: "8px",
                      fontSize: "12px",
                    }}
                    type="Link"
                    onClick={() => {
                      if (resultAreaInput?.length > 0) {
                        appProperties.controller.insertContent(resultAreaInput);
                      }
                    }}
                  >
                    Insert to Editor
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ContentGpt;
