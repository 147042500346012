import "../assets/css/Login.css";

import { Button, message, Input, Space, Modal, Typography } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "../hooks/useAuth";
import useAdminHttp from "../hooks/useAdminHttp";

const Login = () => {
  const { Title } = Typography;
  const { fetchData } = useAdminHttp();
  const navigate = useNavigate();
  const { setIsAdmin, isAdmin } = useAuth();
  const [emailButtonLoading, setEmailButtonLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");

  if (isAdmin) navigate("/admin");

  const otpHandler = () => {
    fetchData('validate', 'post', { "email": email, "otp": otp })
      .then((response) => {
        console.log(response?.data);
        if (response?.status === 200) {
          setIsModalVisible(false);
          setConfirmLoading(false);
          setIsAdmin(true);
          navigate("/admin");
        }
        else {
          throw new Error("Wrong OTP");
        }
      })
      .catch(err => {
        setOtp('');
        setConfirmLoading(false);
        message.error(err.message)
      });
  }

  const loginHandler = () => {
    setEmailButtonLoading(true);
    fetchData('login', 'post', { "email": email })
      .then(response => {
        console.log(response);
        if (response && response?.status === 200 && response.data !== false) {
          showModal();
        }
        else {
          throw new Error("Invalid email");
        }
      })
      .catch(err => {
        message.error(err.message);
        setEmail('');
      })
      .finally(() => {
        setEmailButtonLoading(false);
      });
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    otpHandler();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.currentTarget.value);
  };

  return (
    <>
      <Modal
        data-cy="otpModal"
        style={{ height: "300px" }}
        title={"Check your inbox '" + email + "'"}
        open={isModalVisible}
        closable={false}
        maskClosable={false}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
      >
        <Input
          onPressEnter={handleOk}
          placeholder="Enter your OTP"
          onChange={(e) => setOtp(e.currentTarget.value)}
          value={otp}
        />
      </Modal>
      <div className="row vh-100 vw-100 align-items-center justify-content-center">
        <div
          className="col-10 rounded h-75 loginContainer"
          style={{ background: "#ffffff" }}
        >
          <div
            className="row h-100 align-items-center justify-content-center"
            style={{ overflow: "hidden" }}
          >
            <div className="col-md-6 d-none d-md-block">
              <img
                src={require("../assets/images/loginIllustration.jpg")}
                style={{ objectFit: "cover", height: "100%", width: "100%" }}
                alt="login illustration"
              />
            </div>

            <div className="col-10 col-md-4 my-auto">
              <Title className="welcomeTitle mt-5">Welcome Back :)</Title>
              <p className="mb-5 loginInfo">
                Please login with your work mail to access
              </p>
              <Space direction="vertical">
                <Input
                  size="large"
                  onPressEnter={loginHandler}
                  onChange={handleEmailChange}
                  value={email}
                  type="email"
                  placeholder="Enter your mail"
                ></Input>
                <Button
                  size="large"
                  onClick={loginHandler}
                  className="loginButton px-5"
                  loading={emailButtonLoading}
                >
                  Get OTP
                </Button>
              </Space>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
