export function RegisterChargebee(accountType, appProperties, setAppProperties) {
  console.log("::::::::::::: inside RegisterChargebee ::::::::::::::");
  console.log("::::::::::::: inside RegisterChargebee 1111  ::::::::::::::", appProperties);

  console.log("::::::::::::: inside RegisterChargebee 1111 second ::::::::::::::");
  let isSuccessCheckoutVariable = false;
  console.log("::::::::::::: inside RegisterChargebee 2222 ::::::::::::::");
  if (window.Chargebee !== undefined && window.Chargebee !== null) {
    window.Chargebee.registerAgain();
  }

  console.log("::::::::::::: inside RegisterChargebee 3333 ::::::::::::::");
  var cbInstance = window.Chargebee.getInstance();

  console.log("::::::::::::: inside RegisterChargebee cbInstance ::::::::::::::", cbInstance);
  var cart = cbInstance.getCart();

  console.log("::::::::::::: inside RegisterChargebee cart ::::::::::::::", cart);
  cart.setCustomer({
    cf_oapps_id: appProperties.osyncId,
    cf_leftserviceid: appProperties.leftServiceId,
    cf_accounttype: accountType,
    cf_servicename: appProperties.service,
    cf_license: accountType,
  });
  cbInstance.setCheckoutCallbacks(function (cart) {
    // you can define a custom callbacks based on cart object

    return {
      loaded: function () {
        console.log(" dinesh ::::::::: RegisterChargebee loaded 2222222222 ");
      },
      close: function () {
        console.log("isSuccessCheckoutVariable >>>>>>>>>>>>>" + isSuccessCheckoutVariable);

        if (isSuccessCheckoutVariable) {
          appProperties
            .fetchData(
              "omni/license?leftServiceId=" + appProperties.leftServiceId + "&osyncId=" + appProperties.osyncId,
              "GET",
              null,
              appProperties
            )
            .then((response) => {
              console.log("isSuccessCheckoutVariable response>>>>>>>>>>>>>" + response);
              const responseData = JSON.parse(response);
              let updatedLicenseObj = {
                ...appProperties.licenseObj,
                ...responseData.data,
              };
              console.log("licenseData >>>>>>>>", responseData);

              console.log("appProperties.licenseObj >>>>>>>>", updatedLicenseObj);
              setAppProperties({
                ...appProperties,
                licenseObj: updatedLicenseObj,
              });
            });
        }
      },
      success: function (hostedPageId) {
        console.log("isSuccessCheckoutVariable appProperties", appProperties, appProperties.hash);
        console.log("isSuccessCheckoutVariable hostedPageId>>>>>>>>>>>>>" + hostedPageId);
        appProperties.fetchData("chargebee/license/add?hostedPageId=" + hostedPageId, "POST", null, appProperties).then(function (response) {
          response = JSON.parse(response);
          console.log("sucess Chargeebee :>>>>>>", response);
          let updatedLicenseObj = {
            ...appProperties.licenseObj,
            licenseDetails: response.data,
          };
          setAppProperties({
            ...appProperties,
            licenseObj: updatedLicenseObj,
          });
        });
        isSuccessCheckoutVariable = true;
      },
      step: function (value) {
        //
        console.log("value -> which step in checkout 2222222");
        console.log(value);
        if (value === "thankyou_screen") {
          // setIsSuccessCheckout(true);
        }
      },
    };
  });
}
