import { Button, Input, Modal, Popconfirm, Space, message } from "antd";
import { useState } from "react";

import useAdminHttp from "../hooks/useAdminHttp";

function LicenseActions({ licenseData, fetchCustomerData }) {
  const [isExtendTrialModalOpen, setIsExtendTrialModalOpen] = useState(false);
  const [isEndTrialPopOverOpen, setIsEndTrialPopOverOpen] = useState(false);
  const [subscriptionButtonLoading, setSubscriptionButtonLoading] = useState(false);
  const [isSubscriptionPopupOpen, setIsSubscriptionPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const maxNoOfDaysCanExtend = 10;
  const [trialDaysToExtend, setTrialDaysToExtend] = useState();
  const { fetchData } = useAdminHttp();

  const handleExtendTrialOk = () => {
    if (trialDaysToExtend >= maxNoOfDaysCanExtend) {
      message.error("Max trial days can be extended is restricted to " + maxNoOfDaysCanExtend);
      setTrialDaysToExtend("");
      return;
    }
    setLoading(true);
    console.log("licenseData before updating ::: ", licenseData);
    fetchData("license/trial?osyncId=" + licenseData.osyncId + "&trialDays=" + trialDaysToExtend, "put", null)
      .then((res) => {
        if (res?.status === 200 && res?.data) {
          setIsExtendTrialModalOpen(false);
          fetchCustomerData(licenseData);
        } else {
          throw new Error("Something wrong happened!");
        }
      })
      .catch((err) => {
        message.error(err.message);
        console.log(err);
      })
      .finally(() => {
        setTrialDaysToExtend("");
        setLoading(false);
      });
  };

  const showExtendTrialModal = () => {
    setIsExtendTrialModalOpen(true);
  };

  const handleExtendTrialCancel = () => {
    setIsExtendTrialModalOpen(false);
  };

  const showEndTrialPopOver = () => {
    setIsEndTrialPopOverOpen(true);
  };

  const handleEndTrialPopOverCancel = () => {
    setIsEndTrialPopOverOpen(false);
  };

  const handleEndTrialOk = () => {
    setLoading(true);
    console.log("sooo", licenseData.rightService);
    fetchData("license/trial?osyncId=" + licenseData.osyncId + "&trialDays=0", "put", null)
      .then((res) => {
        console.log("res from license/trial :::: ", res);
        if (res?.status === 200 && res?.data) {
          setIsEndTrialPopOverOpen(false);
          fetchCustomerData(licenseData);
          message.success("Trial for user with OsyncId : " + licenseData.osyncId + " has ended", 3);
        } else {
          throw new Error("Unknown error occured");
        }
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        setTrialDaysToExtend("");
        setLoading(false);
      });
  };

  const updateSubscription = (activate) => {
    setSubscriptionButtonLoading(true);
    fetchData(
      "license/subscription?osyncId=" + licenseData.osyncId + "&licenseIdentifier=" + licenseData.licenseIdentifier + "&activate=" + activate,
      "put",
      null
    )
      .then((res) => {
        if (res?.status === 200 && res?.data) {
          if (activate) {
            message.success("Subscription activated!");
          } else {
            message.success("Subscription ended!");
          }
          fetchCustomerData(licenseData);
        } else {
          if (activate) {
            throw new Error("Failed to activate subscription");
          } else {
            throw new Error("Failed to end subscription");
          }
        }
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        setIsSubscriptionPopupOpen(false);
        setSubscriptionButtonLoading(false);
      });
  };

  const openSubscriptionPopup = () => {
    setIsSubscriptionPopupOpen(true);
  };

  return (
    <>
      {licenseData ? (
        <>
          <Modal
            confirmLoading={loading}
            title="Extend the trial period"
            open={isExtendTrialModalOpen}
            onOk={handleExtendTrialOk}
            onCancel={handleExtendTrialCancel}
          >
            <p>
              <b>Note : </b>The trial extend days will be calculated from today
            </p>
            <Input
              min={1}
              max={10}
              type="number"
              placeholder="No of days"
              value={trialDaysToExtend}
              onChange={(e) => setTrialDaysToExtend(e.currentTarget.value)}
            ></Input>
          </Modal>
          <Space>
            {licenseData.licenseStatus === 11 ||
            licenseData.licenseStatus === 24 ||
            licenseData.licenseStatus === 5 ||
            licenseData.licenseStatus === 4 ||
            licenseData.licenseStatus === 2 ? (
              <>
                <Button className="actionsButton" onClick={showExtendTrialModal}>
                  Extend Trial
                </Button>
                {licenseData.licenseStatus !== 11 && licenseData.licenseStatus !== 24 ? (
                  <Popconfirm
                    title="Are you sure you want to end this trial"
                    open={isEndTrialPopOverOpen}
                    onConfirm={handleEndTrialOk}
                    okButtonProps={{ loading: loading }}
                    onCancel={handleEndTrialPopOverCancel}
                  >
                    <Button className="actionsButton" onClick={showEndTrialPopOver}>
                      End Trial
                    </Button>
                  </Popconfirm>
                ) : (
                  <></>
                )}
              </>
            ) : licenseData.licenseStatus === 1 ? (
              <Popconfirm
                title="Are you sure you want to end subscription"
                open={isSubscriptionPopupOpen}
                onConfirm={() => updateSubscription(false)}
                onCancel={() => setIsSubscriptionPopupOpen(false)}
              >
                <Button loading={subscriptionButtonLoading} onClick={openSubscriptionPopup} danger>
                  End subscription
                </Button>
              </Popconfirm>
            ) : licenseData.licenseStatus === 21 ||
              licenseData.licenseStatus === 22 ||
              licenseData.licenseStatus === 23 ||
              licenseData.licenseStatus === 30 ? (
              <Popconfirm
                title="Are you sure you want to activate subscription"
                onConfirm={() => updateSubscription(true)}
                open={isSubscriptionPopupOpen}
                onCancel={() => setIsSubscriptionPopupOpen(false)}
              >
                <Button loading={subscriptionButtonLoading} onClick={openSubscriptionPopup} danger>
                  Activate subscription
                </Button>
              </Popconfirm>
            ) : (
              <></>
            )}
          </Space>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default LicenseActions;
