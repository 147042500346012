import { Row, Modal } from "antd";
function PermissionDeniedModal(props) {
  const { permissionDeniedToSubAccount } = props;
  return (
    <Modal
      title={
        <Row>
          <div className="d-flex justify-content-start w-100">
            <span
              className="d-flex justify-content-start actionIconsSprite permissionDeniedIcon"
              style={{
                width: 68,
                height: 60,
                marginLeft: -15,
              }}
            ></span>
          </div>
        </Row>
      }
      centered
      open={permissionDeniedToSubAccount}
      width={425}
      footer={null}
      closeIcon={null}
    >
      <>
        <div className="mt-2">
          <Row>
            <div className="d-flex justify-content-start w-100">
              <span
                style={{
                  fontSize: 16,
                  fontWeight: "bolder",
                  color: "#111827",
                }}
              >
                Access Denied: Contact Admin
              </span>
            </div>
          </Row>
          <Row>
            <div className="mt-2">
              <span>
                <p
                  style={{
                    fontSize: 13,
                    fontWeight: 400,
                    color: "#374151",
                  }}
                >
                  You are not added as a user to access this account. Please contact your administrator to gain access.
                </p>
              </span>
            </div>
          </Row>
        </div>
      </>
    </Modal>
  );
}

export default PermissionDeniedModal;
