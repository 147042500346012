import React, { useState, useRef, useContext, useEffect } from "react";
import "./../../assets/css/automationMessages.css";
import { AppContext } from "./../../context/AppContext";
import { CheckOutlined } from "@ant-design/icons";
import useHttp from "../../hooks/useHttp";
import { Typography } from "antd";
import AddOrEditAutomationComponent from "./AddOrEditAutomationComponent";
import { listAllAutomationRecords } from "./../utils/CommonVessels";
import ZohoCRM from "../../controller/ZohoCRMController";
const { Text } = Typography;
function ShowAutomationComponentForm({source}) {
  const [valueObj, setValueObj] = useState({});
  const [fileList, setFileList] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [messageAreaLoading, setMessageAreaLoading] = useState(false);
  const { fetchData } = useHttp();
  const method = useRef(null);
  const friendlyNameRef = useRef(null);
  const [savedNumbersList, setSavedNumbersList] = useState(null);
  const [addBtnLoading, setAddBtnLoading] = useState(false);
  const [doNotShowEditConfigurationMessage, setDoNotShowEditConfigurationMessage] = useState(false);
  const [appProperties] = useContext(AppContext);
  const [automationListView, setAutomationListView] = useState([]);
  const [webhookUrl, setWebhookUrl] = useState("");
  const [webhookId, setWebhookId] = useState("");
  const [configData, setConfigData] = useState({});
  useEffect(() => {
    appProperties?.controller?.getWebhook().then(function (data) {
      console.log("dtiou app controller >>>>>>>data>>>>>>", data);
      if (data) {
        if (typeof data === "string") {
          data = JSON.parse(data);
        }
        if (Object.keys(data).length > 0) {
          setConfigData(data);
        }
      }
    });
    setDoNotShowEditConfigurationMessage(appProperties?.controller?.service !== "Zoho CRM");
  }, [appProperties?.controller?.service, ZohoCRM?.configData]);

  useEffect(() => {
    console.log("Object.keys(configData).length > 0 >>>>>>>>>>>>>", Object.keys(configData).length > 0);
    if (configData && Object.keys(configData).length > 0) {
      method.current = "edit";
      let integId = configData?.integId;
      let eventId = configData?.eventId;

      if (eventId && integId) {
        fetchData(`omessage/${integId}/webhook?eventId=${eventId}`, "GET", null, appProperties)
          .then(function (response) {
            if (response) {
              let data = JSON.parse(response);
              let webhookEventObject = data?.data?.webhookNotification;
              if (webhookEventObject) {
                if (webhookEventObject?.phoneField) {
                  if (typeof webhookEventObject?.phoneField === "string") {
                    webhookEventObject.phoneField = JSON.parse(webhookEventObject?.phoneField);
                    webhookEventObject.fieldApiName = webhookEventObject?.phoneField;
                    setValueObj(webhookEventObject);
                  }
                }
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      method.current = "add";
    }
  }, [configData]);

  useEffect(() => {
    if (webhookId && webhookUrl && valueObj.integId) {
      appProperties?.controller?.setWebhook(webhookUrl, webhookId, appProperties, valueObj?.integId).then(function () {});
    }
  }, [webhookUrl, webhookId, valueObj.integId]);
  return (
    <>
      <div className="m-3 p-3" style={{ background: "white" }}>
        <AddOrEditAutomationComponent
          valueObj={valueObj}
          friendlyNameRef={friendlyNameRef}
          setValueObj={setValueObj}
          method={method}
          savedNumbersList={savedNumbersList}
          setSavedNumbersList={setSavedNumbersList}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          setFileList={setFileList}
          setMessageAreaLoading={setMessageAreaLoading}
          messageAreaLoading={messageAreaLoading}
          fileList={fileList}
          showAddOrEditButton={true}
          appProperties={appProperties}
          addBtnLoading={addBtnLoading}
          messageAreaHeight={3}
          setAddBtnLoading={setAddBtnLoading}
          setTableData={setAutomationListView}
          mapDataToTable={listAllAutomationRecords}
          setWebhookUrl={setWebhookUrl}
          setWebhookId={setWebhookId}
          doNotShowEditConfigurationMessage={doNotShowEditConfigurationMessage}
          source={source}
        />
      </div>
    </>
  );
}

export default ShowAutomationComponentForm;
