import { useState } from "react";
import axios from "axios";

import { ADMIN_SERVER_API } from "../AdminConstants";
import useAuth from "./useAuth";
import { message } from "antd";

export default function useAdminHttp() {
  const { setIsAdmin } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [serverError, setServerError] = useState(null);
  const fetchData = async (url, method, body) => {
    const headerJson = {
      "Content-Type": "text/plain",
    };
    setIsLoading(true);
    try {
      const resp = await axios({
        method: method,
        withCredentials: true,
        headers: headerJson,
        url: ADMIN_SERVER_API + url,
        data: body,
      });

      // console.log("HTTP RESP >>>>>>> " + data);
      setApiData(resp);
      return resp;
    } catch (error) {
      console.log("Error ::: ", error);
      if (error.response.status === 401) {
        message.error("Unauthorized access. Please login again");
        setIsAdmin(false);
      }
      setServerError(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, apiData, serverError, fetchData };
}
