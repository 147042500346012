import { Space, Button, Modal, Form, Input, message, Typography } from "antd";
import { useState } from "react";

import useAdminHttp from "../hooks/useAdminHttp";

export default function AgentsActions({ getAndSetCurrentAgentsData }) {
  const { fetchData } = useAdminHttp();
  const { Title } = Typography;
  const [addAgentModalVisible, setAddAgentModalVisible] = useState(false);
  const [isFormSubmitLoading, setIsFormSubmitLoading] = useState(false);
  const addAgentHandler = () => {
    setAddAgentModalVisible(true);
  };
  const [form] = Form.useForm();
  const formSubmitHandler = (data) => {
    setIsFormSubmitLoading(true);
    fetchData("adminUser", "post", data)
      .then((res) => {
        if (res?.status === 200 && res.data) {
          message.success(
            <span>
              Please verify your email at{" "}
              <a target="__blank" href="https://mail.zoho.com/">
                mail.zoho.com
              </a>
            </span>,
            5
          );
          getAndSetCurrentAgentsData();
          setAddAgentModalVisible(false);
        } else {
          throw new Error("Something wrong happened while adding the admin");
        }
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        form.resetFields();
        setIsFormSubmitLoading(false);
      });
  };

  const emailRegex = /^[a-zA-Z\d]+@oapps\.xyz$/g;
  const emailRules = [
    {
      pattern: emailRegex,
      message: "Please enter a valid email address!",
    },
  ];
  return (
    <Space direction="horizontal" className="mb-4">
      <Modal
        open={addAgentModalVisible}
        cancelButtonProps={{ className: "d-none" }}
        okButtonProps={{ className: "d-none" }}
        onCancel={() => setAddAgentModalVisible(false)}
        className="pb-2"
        title={<Title level={2}>Add new admin</Title>}
      >
        <Form form={form} onFinish={formSubmitHandler} className="px-4 pt-2">
          <Form.Item name="name" label="Name" required>
            <Input required placeholder="Enter admin name" type="text"></Input>
          </Form.Item>
          <Form.Item rules={emailRules} name="email" label="Email" required>
            <Input required placeholder="Enter admin email" type="email"></Input>
          </Form.Item>
          <Form.Item className="">
            <Button loading={isFormSubmitLoading} htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Button onClick={addAgentHandler}>Add Admin</Button>
    </Space>
  );
}
