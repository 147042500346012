import React, { useContext, useEffect, useState } from "react";
import useHttp from "../../../hooks/useHttp";
import { Button, Form, Checkbox, Select } from "antd";
import "../../../assets/css/tendlc.css";
import { AppContext } from "../../../context/AppContext";

function CreateA2pCampaign({ profileId, msgService, setCurrent }) {
  const [appProperties, setAppProperties] = useContext(AppContext);
  const { fetchData } = useHttp();

  const [form] = Form.useForm();
  const [getAllMessagingServices, setGetAllMessagingServices] = useState([]);
  const [continueButtonLoading, setContinueButtonLoading] = useState(false);
  let rightServiceObj = appProperties.installedApps?.find((obj) => obj?.right?.service_name === "hello_send");
  let integId = rightServiceObj?.integProps?.integId;

  useEffect(() => {
    fetchData("hellosend/account/" + integId + "/profile/" + profileId + "/services", "GET", null, appProperties).then(function (response) {
      if (response) {
        response = JSON.parse(response);
        console.log(response);
        setGetAllMessagingServices(response.data.services);
      }
    });
  }, []);

  const handleSearchMsgService = (value) => {
    if (value.length === 34 || value.length === 0) {
      fetchData("hellosend/account/" + integId + "/profile/" + profileId + "/services?messagingServiceId=" + value, "GET", null, appProperties)
        .then(function (response) {
          response = JSON.parse(response);
          console.log(response);
          setGetAllMessagingServices(response.data.services);
        })
        .catch((error) => console.log(error));
    }
  };

  const createMsgService = () => {
    form
      .validateFields()
      .then(function (data) {
        setContinueButtonLoading(true);
        console.log(data);
        fetchData(
          "hellosend/account/" + integId + "/profile/" + profileId + "/service?messagingServiceId=" + data.messagingService,
          "POST",
          null,
          appProperties
        )
          .then(function (response) {
            response = JSON.parse(response);
            setContinueButtonLoading(false);
            setCurrent(4);
          })
          .catch((error) => {
            setContinueButtonLoading(false);
          });
      })
      .catch((error) => {
        console.log("error error error error error error rerror error error ", error);
      });
  };

  return (
    <>
      <div className="container">
        <div className="m-3">
          <h4>Create Messaging Service</h4>
          <div className="description10">
            The campaign will be associated with the chosen Messaging Service. You can add 10DLC phone numbers to the Messaging Service after the
            campaign has been established.
          </div>
        </div>
        <Form form={form} layout="vertical" className="m-3" initialValues={{ messagingService: msgService?.messageServiceId }}>
          <Form.Item
            label="Existing Messaging Service"
            name="messagingService"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              showSearch
              className="msgServiceSelect"
              placeholder="Choose Messaging Service"
              defaultActiveFirstOption={false}
              filterOption={false}
              onSearch={handleSearchMsgService}
              options={getAllMessagingServices.map((d) => ({
                value: d.messageServiceId,
                label: (
                  <>
                    {d.messageServiceName}
                    <br />
                    <span className="description10">{d.messageServiceId}</span>
                  </>
                ),
              }))}
            />
          </Form.Item>
          <Form.Item
            value="sss"
            name="Acknowledgement"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <div className="m-3 p-3 rounded ">
              <Checkbox id="msgAcknowledgement">
                {" "}
                I concur with the assertion that the information provided above is accurate and valid.
              </Checkbox>
            </div>
          </Form.Item>

          <Form.Item className="d-flex justify-content-end">
            <Button
              type="primary"
              loading={continueButtonLoading}
              onClick={createMsgService}
              style={{ backgroundColor: "#009EFD" }}
              id="msgRegister"
            >
              Continue
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}
export default CreateA2pCampaign;
